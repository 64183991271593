import React, { useState, useEffect, useRef } from "react";
import {
  $get,
  $put,
  $post,
  $upload,
  $convertMarkdownToHtml,
} from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function FileDataModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [link, setLink] = useState("");
  const [requestText, setRequestText] = useState("");
  const fileRef = useRef(null);

  const getFileContent = async () => {
    if (requestText === "") {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("파일에서 분석할 내용을 작성하세요."),
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    // file size check - max 5MB
    if (fileRef.current.files[0].size > 5 * 1024 * 1024) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("파일 크기는 5MB 이하로 업로드해주세요."),
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    props.onFileAnalysis(fileRef.current.files[0], requestText);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>{t("파일 분석")}</h3>
        </div>
        <div className="modal-body mt-20">
          <div className="flex">
            <input
              type="file"
              className="form-control mr-5"
              placeholder={t("파일을 업로드하세요.")}
              accept=".pdf"
              ref={fileRef}
            />
          </div>
          <input
            type="text"
            className="form-control mt-10"
            value={requestText}
            onChange={(e) => setRequestText(e.target.value)}
            placeholder={t(
              "파일에서 분석할 내용을 작성하세요. 예) 파일 내용을 요약해줘."
            )}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                getFileContent();
              }
            }}
          />
          {/* <p>{t("업로드한 파일의 내용을 요약합니다.")}</p> */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getFileContent()}
            disabled={
              (fileRef.current &&
                fileRef.current.files &&
                fileRef.current.files.length === 0) ||
              requestText === ""
                ? true
                : false
            }
          >
            <i className="fa-solid fa-magnifying-glass-chart"></i>{" "}
            {t("파일 분석 시작")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileDataModal;

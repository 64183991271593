import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function TableContentAssistantModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [assistantMessage, setAssistantMessage] = useState("");
  const [tableContentType, setTableContentType] = useState(null);

  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getTableContent = async (worktype) => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    setAssistantMessage("");
    sessionStorage.setItem("stopAssistant", false);

    let url = `${serverUrl}/api/prompt/table-content`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        tableContentType: worktype === undefined ? tableContentType : worktype,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);
      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");

      setAssistantMessage((prev) => prev + value);

      assistantScrollRef.current.scrollTop =
        assistantScrollRef.current.scrollHeight;
    }

    sessionStorage.setItem("stopAssistant", false);

    // const result = tempAssistantMessage.join("").split("<br />");
    // const resHtml = [];
    // result.forEach((item) => {
    //   resHtml.push(`<p>${item}</p>`);
    // });

    // setAssistantMessage(resHtml.join(""));
    setAssistantMessage($convertMarkdownToHtml(tempAssistantMessage.join("")));

    bProgress = false;
    setLoading(false);
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  const saveTableContent = async () => {
    const res = await $put(`/api/work/${props.workId}`, {
      table_content: assistantScrollRef.current.innerHTML,
    });

    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("저장 완료"),
        showConfirmButton: false,
        timer: 1000,
      });
      // props.onModal(false);
      props.onSave();
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("저장 실패"),
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getSuggestTableContent = async () => {
    const res = await $get(`/api/work/suggest-table-content/${props.workId}`);

    if (res.status === 200) {
      setAssistantMessage(res.data);
      if (res.data.length === 0) {
        getTableContent();
      }
    }
  };

  const insertTableContent = async () => {
    const res = await $put(`/api/work/${props.workId}`, {
      table_content: assistantScrollRef.current.innerHTML,
    });
    props.onChange(assistantScrollRef.current.innerHTML);
  };

  const getWorkType = async () => {
    const res = await $get(`/api/work/worktype`);
    if (res.status === 200) {
      const workType = res.data.find((item) => item.id === props.type);
      if (workType) setTableContentType(workType.table_content_type);
    }
  };

  useEffect(() => {
    if (tableContentType) {
      getSuggestTableContent();
    }
  }, [tableContentType]);

  useEffect(() => {
    getWorkType();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>{t("목차")}</h3>
        </div>

        <div
          className="modal-body"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
          contentEditable="true"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: assistantMessage }}
        ></div>

        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}
          {loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => stopGenerating()}
            >
              <i className="fa-regular fa-circle-stop"></i> {t("중지")}
            </button>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => getTableContent("short")}
            >
              <i className="fa-solid fa-list-ul"></i> {t("짧은 목차")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => getTableContent("long")}
            >
              <i className="fa-solid fa-list-ol"></i> {t("긴 목차")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => insertTableContent()}
            >
              <i className="fa-solid fa-check"></i> {t("본문에 삽입하기")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => saveTableContent()}
            >
              <i className="fa-solid fa-save"></i> {t("저장")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TableContentAssistantModal;

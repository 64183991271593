import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $get,
  $put,
  $post,
  $convertMarkdownToHtml,
  $convertMarkdownToHtml2,
} from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function ParagraphAssistantModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, userData } = useAuth();
  const [assistantParagraph, setAssistantParagraph] = useState("");
  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getSuggestParagraph = async () => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    setAssistantParagraph("");
    sessionStorage.setItem("stopAssistant", false);

    const url = `${serverUrl}/api/prompt/${props.type}paragraph`;

    // props.selectedText 문장을 포함하는 문단을 찾아서 props.selectedText를 포함하고, props.selectedText 이전 최대 600자와 props.selectedText 이후 최대 1000자를 가져온다.

    let prevParagraph = "";
    let nextParagraph = "";
    if (props.type.startsWith("detail")) {
      let paragraphs = props.workContent.split(props.selectedText);
      if (paragraphs.length === 1) {
        prevParagraph = paragraphs[0];
        nextParagraph = "";
      } else if (paragraphs.length === 2) {
        prevParagraph = paragraphs[0];
        if (prevParagraph.length > 1000) {
          prevParagraph = prevParagraph.slice(-1000);
          prevParagraph = prevParagraph.slice(prevParagraph.indexOf(".") + 1);
        }

        nextParagraph = paragraphs[1];

        if (nextParagraph.length > 1000) {
          nextParagraph = nextParagraph.slice(0, 1000);
          nextParagraph = nextParagraph.slice(
            0,
            nextParagraph.lastIndexOf(".") + 1
          );
        }
      }
    }

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        workTitle: props.workTitle,
        // workContent: props.workContent,
        workContent: prevParagraph + props.selectedText + nextParagraph,
        paragraph: props.selectedText,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantParagraph((prev) => prev + value);

      assistantScrollRef.current.scrollTop =
        assistantScrollRef.current.scrollHeight;
    }

    sessionStorage.setItem("stopAssistant", false);

    if (props.type === "table-") {
      if (userData.lang === "KO") {
        setAssistantParagraph(
          $convertMarkdownToHtml(tempAssistantMessage.join(""))
        );
      } else {
        setAssistantParagraph(
          $convertMarkdownToHtml2(tempAssistantMessage.join(""))
        );
      }
    } else {
      setAssistantParagraph(
        $convertMarkdownToHtml(tempAssistantMessage.join(""))
      );
    }

    bProgress = false;
    setLoading(false);
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  const changeParagraph = async () => {
    props.onModal(false);
    props.onChange(assistantParagraph);
  };

  const copyParagraph = async () => {
    navigator.clipboard.writeText(assistantParagraph);
    // Swal.fire({
    //   icon: "success",
    //   title: "복사 완료",
    //   text: "클립보드에 복사되었습니다.",
    //   timer: 1000,
    // });
    props.onModal(false);
  };

  useEffect(() => {
    getSuggestParagraph();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content modal-md">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>{t("선택된 텍스트")}</h3>
          <p
            className="bg-dark text-white p-10"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            {props.selectedText}
          </p>
        </div>

        <div
          className="modal-body"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: assistantParagraph }}
        ></div>
        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}
          {loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => stopGenerating()}
            >
              <i className="fa-regular fa-circle-stop"></i> {t("중지")}
            </button>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => getSuggestParagraph()}
            >
              <i className="fa-solid fa-repeat"></i> {t("다시 제안 받기")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => changeParagraph()}
            >
              <i className="fa-solid fa-check"></i> {t("바꾸기")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParagraphAssistantModal;

import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $delete } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function GlossaryModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [glossaryList, setGlossaryList] = useState([]);

  const [loading, setLoading] = useState(false);
  const glossaryBodyRef = useRef(null);

  let bProgress = false;

  const deleteGlossary = async (id) => {
    Swal.fire({
      title: t("정말 삭제하시겠습니까?"),
      text: t("삭제된 데이터는 복구할 수 없습니다."),
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      showCancelButton: true,
      confirmButtonText: t("삭제"),
      cancelButtonText: t("취소"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/work/glossary/${id}`);
        if (res.status === 200) {
          getGlossaryList();
          props.onList();
        }
      }
    });
  };

  const getGlossaryList = async () => {
    const res = await $get(`/api/work/glossary/${props.workId}`);
    if (res.status === 200) {
      setGlossaryList(res.data);
    }
  };

  useEffect(() => {
    getGlossaryList();
  }, []);

  return (
    <div className="modal left">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>
            <i className="fa-solid fa-book"></i> {t("용어집")}
          </h3>
        </div>

        <div className="modal-body" ref={glossaryBodyRef}>
          <div className="glossary">
            {glossaryList.map((item, index) => (
              <div className="glossary-item" key={index}>
                <div className="glossary-item-title">
                  {item.glossary}{" "}
                  <button
                    className="btn btn-xsm btn-transparent"
                    onClick={() => deleteGlossary(item.id)}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </div>
                <div
                  className="glossary-item-description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
                <div className="glossary-item-button"></div>
              </div>
            ))}
          </div>
        </div>

        {glossaryList && glossaryList.length > 0 && (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => props.onInsert()}
            >
              <i className="fa-solid fa-check"></i> {t("본문에 삽입하기")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default GlossaryModal;

import React, { useState, useEffect, useRef } from "react";
import {
  $get,
  $put,
  $post,
  $upload,
  $convertMarkdownToHtml,
} from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function FileDataModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [link, setLink] = useState("");
  const fileRef = useRef(null);

  const getFileContent = async () => {
    props.onImageAnalysis(fileRef.current.files[0]);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>{t("통계 이미지 분석")}</h3>
        </div>
        <div className="modal-body mt-20">
          <div className="flex">
            <input
              type="file"
              className="form-control mr-5"
              placeholder={t("통계 이미지를 업로드하세요.")}
              accept=".jpg, .jpeg, .png"
              ref={fileRef}
            />
          </div>
          <p>
            {t("통계 차트 또는 지표 이미지를 업로드하면, 내용을 분석합니다.")}
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getFileContent()}
            disabled={
              fileRef.current &&
              fileRef.current.files &&
              fileRef.current.files.length === 0
                ? true
                : false
            }
          >
            <i className="fa-solid fa-magnifying-glass-chart"></i>{" "}
            {t("분석 시작")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileDataModal;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function CompanyDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, tokenData, checkLogin, userData } = useAuth();
  const { company_id } = location.state || { company_id: null };

  const [company, setCompany] = useState(null);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);

  const scrollRef = useRef(null);

  const getLocalDateTime = (utcDate) => {
    const date = new Date(utcDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}T${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
  };

  const getCompanyDetail = async () => {
    setLoading(true);
    const res = await $get(`/api/company/${company_id}`);
    if (res.status === 200) {
      const companyDetail = res.data;

      if (companyDetail.active_start_date) {
        companyDetail.active_start_date = getLocalDateTime(
          companyDetail.active_start_date
        );
      }

      if (companyDetail.active_end_date) {
        companyDetail.active_end_date = getLocalDateTime(
          companyDetail.active_end_date
        );
      }

      setCompany(companyDetail);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "회사 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const getUserList = async () => {
    setLoading(true);
    const res = await $get(`/api/company/user/${company_id}`);
    if (res.status === 200) {
      setUserList(res.data);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const updateCompany = async () => {
    Swal.fire({
      title: "수정하시겠습니까?",
      text: "",
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const tempCompany = { ...company };
        delete tempCompany.create_datetime;

        if (company.active_yn === "N") {
          tempCompany.active_start_date = null;
          tempCompany.active_end_date = null;
        } else {
          if (company.active_start_date) {
            tempCompany.active_start_date = new Date(company.active_start_date)
              .toISOString()
              .substring(0, 19)
              .replace("T", " ");
          } else {
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: "활성 기간 시작일을 입력해주세요",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
            return;
          }

          if (company.active_end_date) {
            tempCompany.active_end_date = new Date(company.active_end_date)
              .toISOString()
              .substring(0, 19)
              .replace("T", " ");
          } else {
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: "활성 기간 종료일을 입력해주세요",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
            return;
          }
        }

        const res = await $put(`/api/company/${company_id}`, tempCompany);
        setLoading(false);
        if (res.status === 200) {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: "회사 정보 수정 성공",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: "회사 정보 수정 실패",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const goToDetail = (email) => {
    navigate("/userdetail", { state: { email } });
  };

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    getCompanyDetail();
    getUserList();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "url(./assets/images/mockup-02.png)",
        // backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <main className="container" style={{ maxWidth: "1200px" }}>
        {company && (
          <div className="content">
            <div className="content-body align-items-start">
              <div
                className="flex justify-content-between align-items-center mb-10"
                style={{ width: "100%" }}
              >
                <div className="sub-title">{t("기업 정보")}</div>
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => navigate("/companylist")}
                >
                  {t("목록")}
                </button>
              </div>
              <div className="form-group">
                <label className="form-label">{t("기업명")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.company_name || ""}
                  onChange={(e) => {
                    setCompany({ ...company, company_name: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("비즈니스번호")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.business_no || ""}
                  onChange={(e) => {
                    setCompany({ ...company, business_no: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("국가")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.country || ""}
                  onChange={(e) => {
                    setCompany({ ...company, country: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("담당자")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.person_in_charge || ""}
                  onChange={(e) => {
                    setCompany({
                      ...company,
                      person_in_charge: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("연락처")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.tel || ""}
                  onChange={(e) => {
                    setCompany({ ...company, tel: e.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("이메일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={company.email || ""}
                  onChange={(e) => {
                    setCompany({ ...company, email: e.target.value });
                  }}
                />
              </div>

              <div className="form-group">
                <label className="form-label">{t("가입일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    company.create_datetime
                      ? $dateTimezone(company.create_datetime)
                      : ""
                  }
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("상태")}</label>
                <select
                  className="form-control mr-5"
                  style={{ width: "100px" }}
                  value={company.active_yn}
                  onChange={(e) =>
                    setCompany({ ...company, active_yn: e.target.value })
                  }
                >
                  <option value="Y">{t("활성")}</option>
                  <option value="N">{t("비활성")}</option>
                </select>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => updateCompany()}
                >
                  {t("저장")}
                </button>
              </div>
              {company.active_yn === "Y" && (
                <div className="form-group">
                  <label className="form-label">{t("활성 기간")}</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={company.active_start_date}
                    onChange={(e) => {
                      setCompany({
                        ...company,
                        active_start_date: e.target.value,
                      });
                    }}
                  />
                  ~
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={company.active_end_date}
                    onChange={(e) => {
                      console.log("e.target.value", e.target.value);
                      setCompany({
                        ...company,
                        active_end_date: e.target.value,
                      });
                    }}
                  />
                </div>
              )}

              <div
                className="flex justify-content-between align-items-center mb-10 mt-20"
                style={{ width: "100%" }}
              >
                <div className="sub-title">{t("사용자 목록")}</div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead>
                    <tr>
                      <th>닉네임</th>
                      <th>이름</th>
                      <th>이메일</th>
                      <th>연락처</th>
                      <th width="90px">상태</th>
                      <th>등록 일시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList &&
                      userList.map((user, idx) => (
                        <tr key={`user-${idx}`}>
                          <td>
                            <a onClick={() => goToDetail(user.email)}>
                              {user.nickname}
                            </a>
                          </td>
                          <td>{user.name}</td>
                          <td>
                            <a onClick={() => goToDetail(user.email)}>
                              {user.email}
                            </a>
                          </td>
                          <td>{user.phone}</td>
                          <td>{user.active_yn === "Y" ? "활성" : "비활성"}</td>
                          <td>{$dateTimezone(user.create_datetime)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </main>
    </div>
  );
}

export default CompanyDetail;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { $get, $put, $dateTimezone } from "../utils/common";
import Loading from "../component/Loading";

function PublishedDocument() {
  const { company_domain, work_id } = useParams();
  const clientUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  // const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [beatLoading, setBeatLoading] = useState(false);
  const [beatLoading2, setBeatLoading2] = useState(false);
  const [useAI, setUseAI] = useState(false);
  const [workId, setWorkId] = useState(work_id);
  const [workTitle, setWorkTitle] = useState("");
  const [workContent, setWorkContent] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [workType, setWorkType] = useState("");

  const getWork = async () => {
    if (work_id === null) {
      setWorkContent("");
      setWorkTitle("");
      return;
    }

    setLoading(true);
    const res = await $get(`/api/work/${work_id}`);
    if (res.status === 200) {
      setWorkContent(res.data.content);
      setWorkTitle(res.data.title);
      setWorkId(res.data.id);
      setPublishedDate(res.data.modify_datetime);
      setWorkType(res.data.work_title);
    }

    setLoading(false);
  };

  useEffect(() => {
    getWork();
  }, [work_id]);

  return (
    <div>
      <h1
        className="company-title"
        onClick={() => navigate(`/c/${company_domain}`)}
      >
        원더스랩
      </h1>
      <main className="main">
        <div
          className="container"
          style={{ maxWidth: "1200px", marginTop: "0px" }}
        >
          <div className="row">
            <div className="col-xl-9 col-md-9 col-12">
              <article className="card mb-4">
                <header className="card-header text-center">
                  <div className="card-meta">
                    <a href="#">
                      <time
                        className="timeago"
                        datetime="2021-09-26 20:00"
                        timeago-id="1"
                      >
                        {}
                      </time>
                    </a>
                    {publishedDate ? $dateTimezone(publishedDate) : ""} in{" "}
                    <a href="page-category.html">{workType}</a>
                  </div>
                  <a href="post-image.html">
                    <h1 className="card-title">{workTitle}</h1>
                  </a>
                </header>
                {/* <a href="post-image.html">
                  <img className="card-img" src="img/articles/1.jpg" alt="" />
                </a> */}
                <div
                  className="card-body"
                  dangerouslySetInnerHTML={{
                    __html:
                      workContent === "" ||
                      workContent === null ||
                      workContent === "<p><br></p>"
                        ? ``
                        : workContent,
                  }}
                ></div>
              </article>
            </div>
            <div className="col-xl-3 col-md-3 col-12">
              <aside className="sidebar sidebar-sticky">
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="card-title">About</h4>
                    <p className="card-text">
                      Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                      rhoncus, sem quam <a href="#">semper libero</a>, sit amet
                      adipiscing sem neque sed ipsum.{" "}
                    </p>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="card-title">Categories</h4>
                    <div className="tag-list">
                      <a className="tag" href="page-category.html">
                        시장 분석 보고서
                      </a>
                      <a className="tag" href="page-category.html">
                        사업계획서
                      </a>
                      <a className="tag" href="page-category.html">
                        제안서
                      </a>
                      <a className="tag" href="page-category.html">
                        프로젝트 계획서
                      </a>
                      <a className="tag" href="page-category.html">
                        위험 평가 보고서
                      </a>
                    </div>
                  </div>
                </div>
              </aside>
              {/* <aside className="sidebar">
                  <div className="widget widget-stats">
                    <div className="stats-icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                    <div className="stats-info">
                      <h4>TOTAL DOCUMENTS</h4>
                      <p>1,922</p>
                    </div>
                    <div className="stats-link">
                      <a>
                        View Detail{" "}
                        <i className="fa fa-arrow-alt-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="widget widget-stats">
                    <div className="stats-icon">
                      <i className="fa-solid fa-users"></i>
                    </div>
                    <div className="stats-info">
                      <h4>TOTAL USERS</h4>
                      <p>16</p>
                    </div>
                    <div className="stats-link">
                      <a href="javascript:;">
                        View Detail{" "}
                        <i className="fa fa-arrow-alt-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </aside> */}
            </div>
          </div>
        </div>
      </main>
      <div className="site-newsletter">
        <div className="container">
          <div className="text-center">
            <h3 className="h4 mb-2">Subscribe to our newsletter</h3>
            <p className="text-muted">
              Join our monthly newsletter and never miss out on new stories and
              promotions.
            </p>

            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-7 col-lg-5 ms-auto me-auto">
                <div className="input-group mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                    aria-label="Email address"
                  />
                  {/* <span className="input-group-btn"> */}
                  <button className="btn btn-secondary" type="button">
                    Subscribe
                  </button>
                  {/* </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishedDocument;

import React, { useState, useEffect } from "react";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function SystemPopupModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [popup, setPopup] = useState(props.popup);
  const popupRef = React.createRef();

  const closePopup = () => {
    if (!props.preview && popupRef.current.checked) {
      localStorage.setItem(`wyou-popup-${popup.id}`, "false");
    }
    props.onModal(false);
  };

  const getPopupDetail = async () => {
    if (!props.popupId) return;
    const res = await $get(`/api/popup/${props.popupId}`);
    if (res.status === 200) {
      setPopup({
        ...res.data,
        from_datetime: $dateTimezone(res.data.from_datetime, 9),
        to_datetime: $dateTimezone(res.data.to_datetime, 9),
      });
    }
  };

  useEffect(() => {
    getPopupDetail();
  }, [props.popupId]);
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => closePopup()}>
            &times;
          </span>
          <h3></h3>
          {popup && popup.type === "T" && <h3>{popup.title}</h3>}
        </div>
        <div className="modal-body">
          {popup && popup.type === "I" && (
            <img
              src={serverUrl + popup.image_path}
              alt={popup.title}
              style={{ width: "100%", height: "100%" }}
            />
          )}

          {popup && popup.type === "T" && (
            <div className="popup">
              {/* <div className="popup-title">{popup.title}</div> */}
              <div className="popup-content">{popup.content}</div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <input type="checkbox" id="popupCheckbox" ref={popupRef} />
          <label htmlFor="popupCheckbox">{t("다시 보지 않기")}</label>
        </div>
      </div>
    </div>
  );
}

export default SystemPopupModal;

import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $delete } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function WorkFeedbackModal(props) {
  const appUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [assistantMessage, setAssistantMessage] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getWorkFeedbackList = async () => {
    setLoading(true);

    const res = await $get(`/api/work/feedback/${props.workId}`);
    let data = res.data;
    if (res.status === 200) {
      if (props.role === "owner") {
        setFeedbackList(data);
        props.onList(data);
        if (data.length === 0) {
          props.onModal(false);
        }
      } else if (props.role === "feedback_writer") {
        data = data.filter((item) => item.feedback_email === props.email);
        setFeedbackList(data);
        props.onList(data);
        if (data.length === 0) {
          props.onModal(false);
        }
      }
    }

    setLoading(false);
  };

  const handleMouseOver = (index) => {
    if (loading) return;
    setTimeout(() => {
      props.onMouseOver(feedbackList[index].selected_text);
    }, 500);
  };

  const handleMouseOut = (index) => {
    if (loading) return;
    setTimeout(() => {
      props.onMouseOut(feedbackList[index].selected_text);
    }, 500);
  };

  const deleteFeedback = async (id) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("피드백 삭제"),
      text: t("피드백을 삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/work/feedback/${id}`);
        if (res.status === 200) {
          getWorkFeedbackList();
        }
      }
    });
  };

  const doneFeedback = async (id) => {
    const res = await $put(`/api/work/feedback/${id}`, {
      status: "Y",
    });
    if (res.status === 200) {
      getWorkFeedbackList();
    }
  };

  useEffect(() => {
    getWorkFeedbackList();
  }, []);

  return (
    <div className="modal left">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>
            {/* 피드백({feedbackList ? feedbackList.length : 0}){" "} */}
            {loading && <BeatLoader color="#f2295b" />}
          </h3>
        </div>

        <div className="modal-body" ref={assistantScrollRef}>
          {feedbackList &&
            feedbackList.map((item, index) => (
              <div className="spell-error" key={index}>
                <p
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={() => handleMouseOut(index)}
                >
                  {item.selected_text}
                </p>
                <p>
                  <i
                    className="fa-solid fa-arrow-right"
                    style={{ color: "red" }}
                  ></i>{" "}
                  {item.feedback}
                </p>
                {props.role === "owner" && (
                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-transparent"
                      onClick={() => doneFeedback(item.id)}
                    >
                      <i
                        className="fa-regular fa-circle-check"
                        style={{ color: "green" }}
                      ></i>{" "}
                      {t("완료")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-transparent"
                      onClick={() => deleteFeedback(item.id)}
                    >
                      <i
                        className="fa-regular fa-trash-can"
                        style={{ color: "red" }}
                      ></i>{" "}
                      {t("삭제")}
                    </button>
                  </div>
                )}
                {props.role === "feedback_writer" && (
                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-transparent"
                      onClick={() => deleteFeedback(item.id)}
                    >
                      <i
                        className="fa-regular fa-trash-can"
                        style={{ color: "red" }}
                      ></i>{" "}
                      {t("삭제")}
                    </button>
                  </div>
                )}
              </div>
            ))}
          {feedbackList.length === 0 && !loading && (
            <div className="spell-error">
              <p>{t("피드백 내용이 없습니다.")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkFeedbackModal;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import TGEditor from "../component/TGEditor";
import Loading from "../component/Loading";
import WorkMaster from "../component/WorkMaster";
import LoginModal from "../component/LoginModal";
import WorkList from "../component/WorkList";
import SocialLogin from "../component/SocialLogin";
import { useTranslation } from "react-i18next";
import LanguageModal from "../component/LanguageModal";
import SystemPopupModal from "../component/SystemPopupModal";

function Workspace() {
  const appUrl = process.env.REACT_APP_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const userLocalLang = navigator.language || navigator.userLanguage;
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [workId, setWorkId] = useState(null);
  const [workList, setWorkList] = useState([]);
  const [showWorkMaster, setShowWorkMaster] = useState(false);
  const [workMasterList, setWorkMasterList] = useState([]);
  const [workMasterId, setWorkMasterId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [points, setPoints] = useState(0);
  const [showWorkListAll, setShowWorkListAll] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [popupModalOpen, setPopupModalOpen] = useState(false);
  const [activePopup, setActivePopup] = useState({});

  const openLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };

  const getWorkList = async () => {
    setLoading(true);
    const res = await $get("/api/work");
    if (res.status === 200) {
      setWorkList(res.data);
    }
    setLoading(false);
  };

  const createWork = async (workMaster) => {
    setLoading(true);
    setShowWorkMaster(false);
    const workData = {
      email: userData.email,
      company_id: userData.company_id,
      title: "",
      content: "",
      subject: "",
      keyword: "",
      forbid: workMaster !== undefined ? workMaster.brand_forbid : "",
      target: workMaster !== undefined ? workMaster.brand_target : "",
      reference: "",
      master_id: workMaster !== undefined ? workMaster.id : null,
    };

    if (userData.company_id) {
      workData.company_id = userData.company_id;
    }

    const res = await $post("/api/work", workData);

    if (res.status === 200) {
      setWorkId(res.data.insertId);
      getWorkList();
    }
    setLoading(false);
  };

  const deleteWork = async (id) => {
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("글 삭제"),
      text: t("글을 삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: t("예"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setShowWorkMaster(false);
        const res = await $delete(`/api/work/${id}`);
        if (res.status === 200) {
          if (id === workId) {
            setWorkId(null);
          }
          getWorkList();
        }
        setLoading(false);
      }
    });
  };

  const getWorkMasterList = async () => {
    setLoading(true);
    const res = await $get("/api/work/workmaster");
    if (res.status === 200) {
      setWorkMasterList(res.data);
    }
    setLoading(false);
  };

  const deleteMasterWork = async (id) => {
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: "어시스턴트 삭제",
      text: "어시스턴트를 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $put(`/api/work/workmaster/${id}`, {
          active_yn: "N",
        });
        if (res.status === 200) {
          getWorkMasterList();
        }

        setWorkId(null);
        setShowWorkMaster(false);
        setShowWorkListAll(false);
        setLoading(false);
      }
    });
  };

  const editMasterWork = async (id) => {
    setWorkId(null);
    setShowWorkListAll(false);
    setShowWorkMaster(true);
    setWorkMasterId(id);
  };

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    }

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
      lang: userLanguage,
    });

    setLoading(false);

    if (res2 === undefined) {
      return;
    }

    if (res2.status === 200) {
      const userInfo = res2.data[0];

      login({
        ...userInfo,
      });

      setLoginModalOpen(false);
      checkLogin();

      // navigate("/");
      // navigate("/");

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const initializeNaverLogin = async () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: naverClientID,
      callbackUrl: appUrl,
      isPopup: false /* 팝업을 통한 연동처리 여부, true 면 팝업 */,
      loginButton: {
        color: "green",
        type: 3,
        // height: 45,
        width: 300,
      } /* 로그인 버튼의 타입을 지정 */,
    });
    naverLogin.init();
    naverLogin.logout();

    await naverLogin.getLoginStatus(async (status) => {
      if (status) {
        const res = await $post("/api/naver-login", {
          // tokenId: resData.azp,
          email: naverLogin.user.email,
          nickname: naverLogin.user.nickname,
          profile_image_url: naverLogin.user.profile_image,
          access_token: naverLogin.accessToken.accessToken,
          access_token_expires_in: naverLogin.accessToken.expires,
          lang: userLocalLang === "ko-KR" ? "KO" : "EN",
        });
        // 서버로 토큰 전송

        const userInfo = res.data[0];
        // setLoading(false);

        if (res.status === 200) {
          login({
            ...userInfo,
          });
        }
      }
    });
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const getSystemModalList = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const res = await $get(`/api/popup/now/${currentDateTime}`);
    if (res.status === 200) {
      if (res.data.length > 0) {
        const localStorageKey = `wyou-popup-${res.data[0].id}`;
        const localStorageValue = localStorage.getItem(localStorageKey);
        if (localStorageValue === "false") return;
        setActivePopup(res.data[0]);
        setPopupModalOpen(true);
      }
    }
  };

  const checkRemainActiveDate = () => {
    if (userData && userData.active_end_date) {
      const currentDate = new Date();
      const activeEndDate = new Date(userData.active_end_date);
      if (currentDate < activeEndDate) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (isLoggedIn) {
      getWorkList();
      getWorkMasterList();
      getSystemModalList();
    } else {
      setWorkId(null);
      setWorkList([]);
      setWorkMasterId(null);
      setWorkMasterList([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    initKaKao();
    // initializeNaverLogin();
    requestKaKaoInfo();
    if (window.innerWidth < 768) {
      setLeftSidebarOpen(false);
    }
  }, []);

  return (
    <main className="container full" style={{ height: `calc(100vh)` }}>
      {isLoggedIn && (
        <div className={`sidebar${leftSidebarOpen ? "" : " toggle"}`}>
          <div
            className="sidebar-button"
            style={{ left: leftSidebarOpen ? "300px" : "0px" }}
            onClick={() => {
              setLeftSidebarOpen(!leftSidebarOpen);
            }}
          >
            {/* <button
              type="button"
              className="btn btn-xsm btn-transparent"
              onClick={() => {
                setLeftSidebarOpen(!leftSidebarOpen);
              }}
            > */}
            <i
              className={
                leftSidebarOpen
                  ? "fa-solid fa-angles-left"
                  : "fa-solid fa-angles-right"
              }
            ></i>
            {/* </button> */}
          </div>
          <div className="work-container">
            <ol className="workmaster-list">
              <li className="work-item">
                <a
                  className="work-item-link"
                  onClick={() => {
                    if (userData.active_yn === "Y") {
                      createWork();
                    } else {
                      Swal.fire({
                        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                        title: t("구독이 필요한 서비스입니다."),
                        showCancelButton: true,
                        confirmButtonText: t("구독하러 가기"),
                        cancelButtonText: t("확인"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.open(
                            "https://www.wonderslab.ai/pricing",
                            "_blank"
                          );
                        }
                      });
                    }
                  }}
                >
                  {t("새문서")}{" "}
                  <i className="fa-regular fa-pen-to-square ml-10"></i>
                </a>
              </li>

              {workMasterList.length > 0 &&
                workMasterList.map((workMaster, index) => (
                  <li className="work-item" key={`maseter-${workMaster.id}`}>
                    <a
                      className="work-item-link"
                      key={workMaster.id}
                      onClick={() => {
                        if (userData.active_yn === "Y") {
                          createWork(workMaster);
                        } else {
                          Swal.fire({
                            iconHtml:
                              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                            title: "승인 후 사용 가능",
                            text: "관리자 승인 후 사용 가능합니다.",
                            showCancelButton: false,
                          });
                        }
                      }}
                    >
                      {workMaster.master_title}{" "}
                    </a>
                    <div>
                      <button
                        className="btn btn-sm btn-transparent"
                        onClick={() => editMasterWork(workMaster.id)}
                      >
                        <i className="fa-regular fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-transparent"
                        onClick={() => deleteMasterWork(workMaster.id)}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </button>
                    </div>
                  </li>
                ))}
            </ol>

            <ol className="work-list mt-10">
              {workList.map((work, index) => (
                <li
                  className={
                    workId === work.id ? "work-item active" : "work-item"
                  }
                  key={`work-${work.id}`}
                >
                  <a
                    className="work-item-link"
                    onClick={() => {
                      setWorkId(work.id);
                      setShowWorkMaster(false);
                      setShowWorkListAll(false);
                    }}
                  >
                    {work.title ? work.title : t("제목 없음")}
                  </a>
                  <button
                    className="btn btn-sm btn-transparent"
                    onClick={() => deleteWork(work.id)}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </li>
              ))}
              {workList.length === 50 && (
                <li className="work-item more">
                  <a
                    className="work-item-link"
                    onClick={() => {
                      setWorkId(null);
                      setShowWorkMaster(false);
                      setShowWorkListAll(true);
                    }}
                  >
                    더보기
                  </a>
                </li>
              )}
            </ol>
          </div>
          <div
            className="profile-menubar dropdown"
            style={{ backgroundColor: "var(--tg-gray-dark)" }}
          >
            {!isLoggedIn && (
              <a
                className="dropdown-toggle"
                onClick={(e) => {
                  setLoginModalOpen(true);
                }}
                style={{ paddingBottom: "10px" }}
              >
                로그인
              </a>
            )}

            {isLoggedIn && (
              <a
                className="dropdown-toggle"
                onClick={async (e) => {
                  const res = await $get("/api/user/points");
                  if (res.status === 200) {
                    setPoints(res.data.points);
                  }
                  setUserMenu(!userMenu);
                }}
                style={{ paddingBottom: "20px", paddingTop: "10px" }}
              >
                <i className="fa-solid fa-gear"></i> {userData.email}
              </a>
            )}
            <ul className={userMenu ? "dropdown-menu show" : "dropdown-menu"}>
              {/* <li
                className="dropdown-item"
                onClick={() => {
                  if (userData.active_yn === "Y") {
                    navigate("/payment");
                  } else {
                    Swal.fire({
                      iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                      title: t("승인 후 사용 가능"),
                      text: t("관리자 승인 후 사용 가능합니다."),
                      showCancelButton: false,
                    });
                  }
                }}
              >
                <a className="dropdown-link">
                  <i className="fa-solid fa-coins"></i>{" "}
                  {$convertNumberFormat(points)}
                </a>
              </li> */}

              {userData && userData.active_yn === "Y" && (
                <li
                  className="dropdown-item"
                  onClick={() => {
                    setWorkId(null);
                    setShowWorkMaster(true);
                    setUserMenu(false);
                  }}
                >
                  <a className="dropdown-link">
                    <i className="fa-solid fa-robot"></i>{" "}
                    {t("어시스턴트 만들기")}
                  </a>
                </li>
              )}
              {userData && userData.active_yn === "Y" && (
                <li
                  className="dropdown-item"
                  onClick={() => {
                    setShowLanguageModal(true);
                    setUserMenu(false);
                  }}
                >
                  <a className="dropdown-link">
                    <i className="fa-solid fa-language"></i> {t("언어 변경")}
                  </a>
                </li>
              )}
              <li
                className="dropdown-item"
                onClick={() => {
                  if (userData.active_yn === "Y") {
                    navigate("/mypage");
                  } else {
                    if (userData.active_yn === "Y") {
                      createWork();
                    } else {
                      Swal.fire({
                        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                        title: t("구독이 필요한 서비스입니다."),
                        showCancelButton: true,
                        confirmButtonText: t("구독하러 가기"),
                        cancelButtonText: t("확인"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.open(
                            "https://www.wonderslab.ai/pricing",
                            "_blank"
                          );
                        }
                      });
                    }
                  }
                }}
              >
                <a className="dropdown-link">
                  <i className="fa-regular fa-user"></i> {t("마이페이지")}
                </a>
              </li>
              {userData && userData.role_level === 0 && (
                <li className="dropdown-item">
                  <Link to="/userlist" className="dropdown-link">
                    <i className="fa-solid fa-users-gear"></i>{" "}
                    {t("사용자 관리")}
                  </Link>
                </li>
              )}

              {userData && userData.role_level === 0 && (
                <li className="dropdown-item">
                  <Link to="/companylist" className="dropdown-link">
                    <i className="fa-solid fa-building"></i> {t("기업 관리")}
                  </Link>
                </li>
              )}

              {userData && userData.role_level === 0 && (
                <li className="dropdown-item">
                  <Link to="/system-popup" className="dropdown-link">
                    <i className="fa-regular fa-bell"></i> {t("팝업 관리")}
                  </Link>
                </li>
              )}

              <li
                className="dropdown-item"
                onClick={() => {
                  setUserMenu(false);
                  socialLogout();
                }}
              >
                <a className="dropdown-link">
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>{" "}
                  {t("로그아웃")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {workId && (
        <TGEditor
          workId={workId}
          leftSidebarOpen={leftSidebarOpen}
          onToggle={() => openLeftSidebar()}
          onList={() => getWorkList()}
        />
      )}

      {!workId && !showWorkMaster && !showWorkListAll && isLoggedIn && (
        <div className="empty-workspace p-20">
          <div className="flex justify-content-center logo mb-20">
            <img src="./assets/images/wdot-logo.png" alt="logo" />
          </div>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "700",
              textAlign: "center",
              margin: "0px",
            }}
          >
            {t("프로들만을 위한 AI 어시스턴트로")}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "700",
              textAlign: "center",
              margin: "0px",
            }}
          >
            {t("일에 대한 마침표를 빠르게")}
            <span className="dot" style={{ fontSize: "1.2rem" }}>
              .
            </span>
          </p>
          {userData && userData.active_yn === "Y" ? (
            <div>
              <button
                className="btn btn-primary mt-30 mr-10"
                onClick={() => createWork()}
              >
                {t("글 작성 시작하기")}
              </button>
              {userLocalLang.startsWith("ko") ? (
                <a
                  href="https://wondersaiguide.oopy.io/d5ce9925-7962-429a-83b6-f5af7011626c"
                  target="_blank"
                  className="btn btn-default mt-30"
                >
                  {t("사용자 가이드")}
                </a>
              ) : (
                <a
                  href="https://wondersaiguideen.oopy.io/57f2ca60-d272-4d2c-81fd-0868fa931981"
                  target="_blank"
                  className="btn btn-default mt-30"
                >
                  {t("사용자 가이드")}
                </a>
              )}
            </div>
          ) : (
            <a
              href="https://www.wonderslab.ai/pricing"
              target="_blank"
              className="btn btn-primary mt-30"
            >
              {t("구독하기")}
            </a>
          )}
          {checkRemainActiveDate() && (
            <div className="mt-20">
              {userData.subscription_yn === "N" && (
                <p className="text-center">
                  {t("사용 만료일")} :{" "}
                  {new Date(userData.active_end_date).toLocaleString()}
                </p>
              )}
            </div>
          )}
        </div>
      )}

      {!workId && !showWorkMaster && !isLoggedIn && (
        // <div className="empty-workspace">
        //   <h3>로그인 후 사용하세요!</h3>
        //   <button
        //     className="btn btn-primary"
        //     onClick={() => setLoginModalOpen(true)}
        //   >
        //     로그인
        //   </button>
        // </div>
        <SocialLogin onLoginWithKakao={() => loginWithKakao()} />
      )}

      {!workId && showWorkMaster && (
        <div className="empty-workspace">
          <WorkMaster
            workMasterId={workMasterId}
            onList={() => {
              getWorkList();
              getWorkMasterList();
              setShowWorkMaster(false);
            }}
          />
        </div>
      )}
      {!workId && showWorkListAll && (
        <WorkList
          onList={() => {
            getWorkList();
          }}
          onSelect={(id) => {
            setWorkId(id);
            setShowWorkMaster(false);
            setShowWorkListAll(false);
          }}
        />
      )}
      {/* <LoginModal
        loginModalOpen={loginModalOpen}
        onLoginWithKakao={() => loginWithKakao()}
        onModal={(isOpen) => setLoginModalOpen(isOpen)}
      /> */}

      {showLanguageModal && (
        <LanguageModal onModal={(isOpen) => setShowLanguageModal(isOpen)} />
      )}

      {popupModalOpen && (
        <SystemPopupModal
          popupId={activePopup.id}
          preview={false}
          onModal={setPopupModalOpen}
        />
      )}

      {loading && <Loading />}
    </main>
  );
}

export default Workspace;

import React, { useState, useEffect } from "react";
import { $put, $post, $get } from "../utils/common";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function WorkMaster(props) {
  const { t, i18n } = useTranslation();
  const [workmaster, setWorkmaster] = useState(false);
  const saveWorkmaster = async () => {
    if (!workmaster.master_title) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("타이틀을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!workmaster.brand_title) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("브랜드명을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!workmaster.brand_keyword) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("핵심 키워드를 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!workmaster.brand_reference) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("참고할 내용을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("어시스턴트 저장"),
      text: t("어시스턴트를 저장하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: t("예"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (props.workMasterId) {
          const tempWorkmaster = { ...workmaster };
          delete tempWorkmaster.id;
          delete tempWorkmaster.datetime;
          delete tempWorkmaster.modify_datetime;

          const res = await $put(
            `/api/work/workmaster/${props.workMasterId}`,
            tempWorkmaster
          );
          if (res.status === 200) {
            props.onList();
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("저장 완료"),
              text: t("어시스턴트가 저장되었습니다."),
            });
          }
          return;
        } else {
          const res = await $post("/api/work/workmaster", workmaster);
          if (res.status === 200) {
            props.onList();
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("저장 완료"),
              text: t("어시스턴트가 저장되었습니다."),
            });
          }
        }
      }
    });
  };

  const getWorkMasterDetail = async () => {
    const res = await $get(`/api/work/workmaster/${props.workMasterId}`);
    if (res.status === 200) {
      setWorkmaster(res.data);
    }
  };

  useEffect(() => {
    getWorkMasterDetail();
  }, [props.workMasterId]);

  return (
    <div>
      <h1>{t("어시스턴트")}</h1>
      <p>
        {t(
          "어시스턴트로 생성한 새글 쓰기를 클릭하면 브랜드 정보를 손쉽게 사용할 수 있습니다."
        )}
      </p>
      <div className="form-group">
        <label className="form-label mandatory">{t("타이틀")}</label>
        <input
          type="text"
          className="form-control"
          value={workmaster.master_title}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, master_title: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label mandatory">{t("브랜드명")}</label>
        <input
          type="text"
          className="form-control"
          value={workmaster.brand_title}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, brand_title: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label mandatory">{t("핵심 키워드")}</label>
        <input
          type="text"
          className="form-control"
          value={workmaster.brand_keyword}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, brand_keyword: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label">{t("금칙어")}</label>
        <input
          type="text"
          className="form-control"
          value={workmaster.brand_forbid}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, brand_forbid: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label mandatory">{t("대상")}</label>
        <input
          type="text"
          className="form-control"
          value={workmaster.brand_target}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, brand_target: e.target.value })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label mandatory">{t("참고할 내용")}</label>
        <textarea
          className="form-control"
          rows="10"
          value={workmaster.brand_reference}
          // placeholder={t(
          //   "문서의 핵심 내용을 입력하면, 더블유닷이 입력된 핵심 내용을 바탕으로 목차 및 내용을 더 상세하게 제안합니다. (1,000자 이내)"
          // )}
          onChange={(e) =>
            setWorkmaster({ ...workmaster, brand_reference: e.target.value })
          }
        />
      </div>
      <div className="flex justify-content-center">
        <button className="btn btn-primary" onClick={() => saveWorkmaster()}>
          {t("저장")}
        </button>
      </div>
    </div>
  );
}

export default WorkMaster;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function AccountSession() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const getSessionList = async () => {
    const res = await $get(`/api/account/session`);
    if (res.status === 200) {
      if (res.data.length === 0) {
        Swal.fire({
          title: t("세션 없음"),
          text: t("현재 활성화된 세션이 없습니다."),
          icon: "info",
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          confirmButtonText: t("확인"),
        });
        navigate("/");
      } else {
        setSessionList(res.data);
      }
    }
  };

  const deleteOtherSession = async () => {
    Swal.fire({
      title: t("다른 세션 모두 종료하기"),
      text: t("모든 세션을 종료하시겠습니까?"),
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      showCancelButton: true,
      confirmButtonText: t("네, 종료합니다."),
      cancelButtonText: t("취소"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/account/session`);
        if (res.status === 200) {
          navigate("/");
        }
      }
    });
  };

  useEffect(() => {
    getSessionList();
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "url(./assets/images/mockup-02.png)",
        // backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div className="container">
        <div className="content">
          <div className="content-body align-items-start">
            <div
              className="flex justify-content-between align-items-center mb-10"
              style={{ width: "100%" }}
            >
              <div className="sub-title">{t("세션 관리")}</div>
              <button
                className="btn btn-default btn-sm"
                onClick={() => navigate("/")}
              >
                {t("홈으로 이동")}
              </button>
            </div>
            {sessionList.map((session, index) => (
              <div className="ip-card">
                <div className="card-header flex-column justify-content-center align-items-center">
                  <h4 className="card-title">{session.ip_address}</h4>
                  {index === 0 && sessionList.length > 1 ? (
                    <div className="flex-column justify-content-center align-items-center">
                      <p className="badge bg-primary">{t("현재 사용 세션")}</p>
                      <p>
                        {t("세션이 중복 사용중입니다. 세션을 모두 종료하세요.")}
                      </p>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => deleteOtherSession()}
                      >
                        {t("다른 세션 모두 종료하기")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="card-body">
                  <p>
                    <b>User-Agent</b>: {session.user_agent}
                  </p>
                  <p>
                    <b>Signed in</b>: {$dateTimezone(session.create_datetime)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {loading && <Loading />}
      </div>
    </div>
  );
}

export default AccountSession;

import React, { useState, useEffect } from "react";
import { $put, $post, $get } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

function ImageModal(props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [requestText, setRequestText] = useState("");
  const [imageType, setImageType] = useState("illustration");
  const [imageSize, setImageSize] = useState("1024x1024");

  const createImage = async () => {
    setLoading(true);

    const res = await $post("/api/prompt/dalle", {
      requestText,
      imageType: imageType,
      imageSize: imageSize,
      workId: props.workId,
    });

    setImgSrc(res.data.url);

    setLoading(false);
  };

  const getWorkDetail = async () => {
    const res = await $get(`/api/work/${props.workId}`);
    if (res.status === 200) {
      setRequestText(res.data.keyword);
    }
  };

  const downloadImage = async () => {
    setLoading(true);
    const res = await $post("/api/upload/image-from-url", {
      imageURL: imgSrc,
      workId: props.workId,
    });

    if (res.status === 200) {
      const fileUrl = res.data.url;
      props.onInsert(fileUrl);
    }

    setLoading(false);
  };

  useEffect(() => {
    getWorkDetail();
  }, [props.workId]);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}

          <h3>{t("이미지 삽입")}</h3>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-label">{t("이미지 설명")}</label>
            <input
              type="text"
              className="form-control"
              value={requestText}
              onChange={(e) => setRequestText(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">{t("이미지 유형")}</label>
            <select
              className="form-control"
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
            >
              <option value="illustration">{t("일러스트")}</option>
              <option value="photograph">{t("사진")}</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">{t("이미지 크기")}</label>
            <select
              className="form-control"
              value={imageSize}
              onChange={(e) => setImageSize(e.target.value)}
            >
              <option value="1024x1024">1:1</option>
              <option value="1792x1024">16:9</option>
              <option value="1024x1792">9:16</option>
            </select>
          </div>
          {imgSrc !== "" && <img src={imgSrc} className="dalle" alt="" />}
        </div>
        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => props.onModal(false)}
            >
              {t("닫기")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => createImage()}
            >
              {t("이미지 생성")}
            </button>
          )}

          {imgSrc !== "" && !loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => downloadImage()}
            >
              {t("이미지 삽입")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageModal;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $get,
  $put,
  $upload,
  $delete,
  $dateFormat,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import Loading from "./Loading";

function UsageHistoryModal(props) {
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsageHistory = async () => {
    const res = await $get(`/api/usage/work/${props.workId}`);
    if (res.status === 200) {
      setUsageHistory(res.data);
    }
  };

  useEffect(() => {
    getUsageHistory();
  }, [props.workId]);

  return (
    <div className="modal">
      <div className="modal-content modal-md">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>
            {usageHistory && usageHistory.length > 0
              ? usageHistory[0].title
              : ""}
          </h3>
        </div>
        <div className="modal-body">
          {/* <div className="table-responsive"> */}
          <table className="table table-hover table-nowrap">
            <thead>
              <tr>
                <th style={{ width: "180px" }}>기능명</th>
                <th style={{ width: "80px" }}>사용 수</th>
                {props.roleLevel === 0 && (
                  <th style={{ width: "100px" }}>인풋토큰</th>
                )}
                {props.roleLevel === 0 && (
                  <th style={{ width: "100px" }}>아웃풋토큰</th>
                )}
              </tr>
            </thead>
            <tbody>
              {usageHistory &&
                usageHistory.map((usage, idx) => (
                  <tr key={`usage-${idx}`}>
                    <td>{usage.func_title}</td>
                    <td>{$convertNumberFormat(usage.func_key_count)}</td>
                    {props.roleLevel === 0 && (
                      <td>{$convertNumberFormat(usage.prompt_tokens)}</td>
                    )}
                    {props.roleLevel === 0 && (
                      <td>{$convertNumberFormat(usage.completion_tokens)}</td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default UsageHistoryModal;

// AuthProvider.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";
import { $get } from "../utils/common";
import { useTranslation } from "react-i18next";

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  const login = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
    i18n.changeLanguage(data.lang);
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setUserData(null);
    const res = (await $get("/api/logout")).data;
    if (res.success) {
      navigate("/");
    }
  };

  const checkLogin = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      const user = await $get(`/api/user/${res.email}`);
      login(user.data);

      // if (
      //   res.sessionList &&
      //   res.sessionList.length > 1 &&
      //   location.pathname !== "/account-session"
      // ) {
      //   navigate("/account-session");
      // } else if (res.sessionList && res.sessionList.length === 1) {
      //   if (res.sessionIp !== res.sessionList[0].ip_address) {
      //     logout();
      //   }
      // } else if (res.sessionList && res.sessionList.length === 0) {
      //   logout();
      // }
      // if (user.data.active_yn === "N") {
      //   navigate("/waiting-list");
      // }
    } else {
      setIsLoggedIn(false);
      setUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (location.state && location.state.auth) {
      checkLogin();
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userData,
        setUserData,
        login,
        logout,
        checkLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

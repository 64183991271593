import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function SEOModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [assistantMessage, setAssistantMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const optimizeSEO = async () => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    setAssistantMessage("");
    sessionStorage.setItem("stopAssistant", false);

    let url = `${serverUrl}/api/prompt/seo`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);
      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");

      setAssistantMessage((prev) => prev + value);

      assistantScrollRef.current.scrollTop =
        assistantScrollRef.current.scrollHeight;
    }

    sessionStorage.setItem("stopAssistant", false);
    setAssistantMessage($convertMarkdownToHtml(tempAssistantMessage.join("")));

    bProgress = false;
    setLoading(false);
  };

  const saveSEO = async () => {
    const res = await $put(`/api/work/${props.workId}`, {
      seo: assistantMessage,
    });

    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        text: t("저장되었습니다."),
        showConfirmButton: false,
        timer: 1500,
      });

      props.onModal(false);
    }
  };

  const getSEO = async () => {
    const res = await $get(`/api/work/seo/${props.workId}`);
    if (res.status === 200) {
      const seo = res.data;
      if (seo) {
        setAssistantMessage(res.data);
      } else {
        optimizeSEO();
      }
    }
  };

  useEffect(() => {
    getSEO();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>{t("SEO 최적화")}</h3>
        </div>

        <div
          className="modal-body"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: assistantMessage }}
        ></div>

        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => optimizeSEO()}
            >
              {t("다시 제안 받기")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => saveSEO()}
            >
              {t("저장")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SEOModal;

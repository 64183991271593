import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function MemoModal(props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const changeUrlTag = (text) => {
    const urlRegex =
      /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    const urls = text.match(urlRegex) || [];

    urls.forEach((url) => {
      text = text.replace(
        url,
        `<a href="${url}" class="underline text-white" target="_blank">${url}</a>`
      );
    });

    return text;
  };

  const saveWorkResearch = async () => {
    const res = await $post("/api/work/research", {
      work_id: props.workId,
      request_text: message.length > 30 ? message.substring(0, 30) : message,
      response_text: changeUrlTag(message),
      type: "memo",
    });
    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("자료목록에 추가되었습니다."),
        showConfirmButton: false,
        timer: 1500,
      });
      props.onList();
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="flex align-items-center">{t("메모하기")}</h3>
        </div>
        <div className="modal-body">
          <textarea
            className="form-control"
            placeholder={t("메모 내용을 입력하세요.")}
            value={message}
            style={{ minHeight: "300px" }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="modal-footer flex">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveWorkResearch()}
          >
            {t("자료목록에 추가")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MemoModal;

import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function FeedbackModal(props) {
  const appUrl = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t, i18n } = useTranslation();

  const changeUrlTag = (text) => {
    const urlRegex =
      /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    const urls = text.match(urlRegex) || [];

    urls.forEach((url) => {
      text = text.replace(
        url,
        `<a href="${url}" class="underline text-white" target="_blank">${url}</a>`
      );
    });

    return text;
  };

  const saveFeedback = async () => {
    const res = await $post("/api/work/feedback", {
      work_id: props.workId,
      selected_text: props.selectedText,
      feedback: changeUrlTag(message),
    });
    if (res.status === 200) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("저장되었습니다."),
        showConfirmButton: false,
        timer: 1500,
      });
      props.onList();
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>{t("선택된 텍스트")}</h3>
          <p className="bg-dark text-white p-10">{props.selectedText}</p>
        </div>
        <div className="modal-body">
          <textarea
            className="form-control"
            placeholder={t("피드백 내용을 입력하세요.")}
            value={message}
            style={{ minHeight: "300px" }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="modal-footer flex">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => saveFeedback()}
          >
            {t("저장")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedbackModal;

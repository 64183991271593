import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $put, $post } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function TitleAssistantModal(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [titleList, setTitleList] = useState([]);

  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getTitle = async () => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    const res = await $post(`/api/prompt/title`, { workId: props.workId });
    if (res.status === 402) {
      navigate("/payment");
    }

    if (res.status === 200) {
      setTitleList(res.data);
    }

    bProgress = false;
    setLoading(false);
  };

  const changeTitle = async (title) => {
    const res = await $put(`/api/work/${props.workId}`, {
      title: title,
    });
    if (res.status === 200) {
      props.onChange(title);
    }
  };

  const getSuggestTitle = async () => {
    const res = await $get(`/api/work/suggest-title/${props.workId}`);
    if (res.status === 200) {
      setTitleList(res.data);
      if (res.data.length === 0) {
        getTitle();
      }
    }
  };

  useEffect(() => {
    getSuggestTitle();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>{t("제목 제안 받기")}</h3>
        </div>

        <div className="modal-body">
          {!loading && (
            <ul className="title-list">
              {titleList.map((title, index) => (
                <li className="title-item" key={index}>
                  <a className="title" onClick={() => changeTitle(title.title)}>
                    {title.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {loading && (
            <div className="flex pl-20">
              <BeatLoader color="#f2295b" /> {t("제목을 제안 받고 있습니다...")}
            </div>
          )}
        </div>

        <div className="modal-footer flex">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => getTitle()}
            >
              {t("다시 제안 받기")}
            </button>
          )}

          {!loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={(e) => props.onModal(false)}
            >
              {t("닫기")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TitleAssistantModal;

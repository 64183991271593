import React, { useState, useEffect, useRef } from "react";
import { $put, $post, $get, $upload } from "../utils/common";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/AuthProvider";

function InfoModal(props) {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData } = useAuth();

  const [info, setInfo] = useState({
    subject: "",
    keyword: "",
    forbid: "",
    reference: "",
    type: 3,
    target: "",
  });
  const [workTypeList, setWorkTypeList] = useState([]);
  const fileRef = useRef(null);

  const saveInfo = async (e) => {
    if (!info.subject) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("주제를 입력해주세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.keyword) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("핵심 키워드를 입력해주세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (props.workId) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("기본 정보 저장"),
        text: t("기본 정보를 저장하시겠습니까?"),
        showCancelButton: true,
        confirmButtonText: t("예"),
        cancelButtonText: t("아니오"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $put(`/api/work/${props.workId}`, {
            subject: info.subject,
            keyword: info.keyword,
            forbid: info.forbid,
            reference: info.reference,
            type: info.type,
            target: info.target,
          });

          if (res.status === 200) {
            props.onChange(info);
          } else {
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("기본 정보 저장 실패"),
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  const getFileContent = () => {
    if (!fileRef.current.files[0]) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("파일을 업로드하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    // Swal loading 파일을 분석하는데 시간이 걸립니다. 분석하시겠습니까? 분석이 완료될때까지 기다려주세요.
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("파일 분석"),
      text: t("파일을 분석하는데 시간이 걸립니다. 분석하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: t("예"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // show loading
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: t("분석이 완료될때까지 기다려주세요..."),
          showConfirmButton: false,
          // timer: 1000,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const res = await $upload(
          "/api/prompt/upload-document-format",
          fileRef.current.files[0],
          {
            workId: props.workId,
            fileName: fileRef.current.files[0].name,
            requestText: t(
              "이 파일은 문서 양식이야. 목차를 구성할 때 이 문서 양식을 참고하려고해. 문서 양식을 분석해서 목차 작성해줘. 부수적인 설명은 달지 말고 목차만 작성해줘."
            ),
          }
        );
        if (res.status === 200) {
          if (res.data.success) {
            setInfo({
              ...info,
              reference: res.data.message,
            });
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("파일 양식을 분석하여 참고할 내용을 입력하였습니다."),
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("파일 분석 실패"),
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      }
    });
  };

  const getWorkTypeList = async () => {
    const res = await $get(`/api/work/worktype`);
    if (res.status === 200) {
      setWorkTypeList(res.data);
    }
  };

  const getWorkDetail = async () => {
    const res = await $get(`/api/work/${props.workId}`);
    if (res.status === 200) {
      // console.log(res.data);
      setInfo(res.data);
    }
  };

  useEffect(() => {
    getWorkTypeList();
    getWorkDetail();
  }, [props.workId]);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>{t("기본 정보")}</h3>
        </div>
        {info && (
          <div className="modal-body">
            {/* <div className="modal-body" style={{ maxHeight: "none" }}> */}
            <div className="form-group">
              <label className="form-label mandatory">{t("타입")}</label>
              <select
                className="form-control"
                value={info.type}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    type: parseInt(e.target.value),
                    need_table_content: e.target.options[
                      e.target.selectedIndex
                    ].getAttribute("data-need-table-content"),
                    table_content_type:
                      e.target.options[e.target.selectedIndex].getAttribute(
                        "table_content_type"
                      ),
                    need_write_next: e.target.options[
                      e.target.selectedIndex
                    ].getAttribute("data-need-write-next"),
                    need_write_direct: e.target.options[
                      e.target.selectedIndex
                    ].getAttribute("data-need-write-direct"),
                  })
                }
              >
                {workTypeList.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                    data-need-table-content={item.need_table_content}
                    data-table-content-type={item.table_content_type}
                    data-need-write-next={item.need_write_next}
                    data-need-write-direct={item.need_write_direct}
                  >
                    {t(item.work_title)}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label mandatory">{t("주제")}</label>
              <input
                type="text"
                className="form-control"
                value={info.subject}
                onChange={(e) => setInfo({ ...info, subject: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label className="form-label mandatory">{t("대상")}</label>
              <input
                type="text"
                className="form-control"
                value={info.target}
                onChange={(e) => setInfo({ ...info, target: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label className="form-label mandatory">{t("핵심 키워드")}</label>
              <input
                type="text"
                className="form-control"
                value={info.keyword}
                onChange={(e) => setInfo({ ...info, keyword: e.target.value })}
              />
            </div>
            <div className="form-group d-none md-flex">
              <label className="form-label">{t("금칙어")}</label>
              <input
                type="text"
                className="form-control"
                value={info.forbid}
                onChange={(e) => setInfo({ ...info, forbid: e.target.value })}
              />
            </div>
            {/* <div className="form-group d-none md-flex">
                <label className="form-label">{t("톤앤매너")}</label>
                <select className="form-control">
                  <option value={t("공식적인")}>{t("공식적인")}</option>
                  <option value={t("격식없는")}>{t("격식없는")}</option>
                  <option value={t("설득력있는")}>{t("설득력있는")}</option>
                  <option value={t("설명하는듯한")}>{t("설명하는듯한")}</option>
                  <option value={t("유머스러운")}>{t("유머스러운")}</option>
                  <option value={t("이야기하는듯한")}>
                    {t("이야기하는듯한")}
                  </option>
                  <option value={t("영감을주는")}>{t("영감을주는")}</option>
                </select>
              </div> */}
            <div className="form-group">
              <label className="form-label">{t("참고할 내용")}</label>
              <textarea
                className="form-control"
                rows={window.innerWidth > 768 ? 10 : 4}
                value={info.reference}
                placeholder={t(
                  "문서의 핵심 내용을 입력하면, 더블유닷이 입력된 핵심 내용을 바탕으로 목차 및 내용을 더 상세하게 제안합니다. (1,000자 이내)"
                )}
                onChange={(e) => {
                  const newText = e.target.value;
                  if (newText.length <= 1000) {
                    setInfo({ ...info, reference: newText });
                  }
                }}
              />
            </div>
            {userData && userData.role_level === 0 && (
              <div>
                <input
                  type="file"
                  className="form-control mr-5"
                  style={{ display: "none" }}
                  placeholder={t("파일을 업로드하세요.")}
                  accept=".pdf"
                  ref={fileRef}
                  onChange={(e) => getFileContent()}
                />
                <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  <a
                    onClick={() => fileRef.current.click()}
                    style={{ color: "var(--tg-primary-main)" }}
                  >
                    <i className="fa-regular fa-file-lines"></i>{" "}
                    {t(
                      "문서 양식이 있다면 여기 를 클릭하세요. (PDF 파일만 가능, 10MB 이하)"
                    )}
                  </a>
                </p>
              </div>
            )}
          </div>
        )}

        <div className="modal-footer flex justify-content-between">
          <a
            className="btn btn-default"
            href={
              userData && userData.lang === "KO"
                ? "https://wondersaiguide.oopy.io/7ac94ab2-88ef-4e9c-9002-8106b639e88e"
                : "https://wondersaiguideen.oopy.io/7cfb78c0-0dba-467e-8349-14dbe586dca0"
            }
            target="_blank"
          >
            <i className="fa-solid fa-book"></i> {t("사용자 가이드")}
          </a>
          <div>
            <button
              type="button"
              className="btn btn-default mr-5"
              onClick={() => props.onModal(false)}
            >
              {t("닫기")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => saveInfo()}
            >
              {t("저장")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoModal;

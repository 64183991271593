import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

function MarketDataModal(props) {
  const { t, i18n } = useTranslation();
  const [ask, setAsk] = useState("");
  const [loading, setLoading] = useState(false);
  const [researchList, setResearchList] = useState([]);

  let bProgress = false;

  const getResearchItem = async (id) => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    const res = await $post(`/api/prompt/research-item`, {
      workId: props.workId,
    });
    if (res.status === 200) {
      setResearchList(res.data);
    }

    bProgress = false;
    setLoading(false);
  };

  const getMarketInfo = async () => {
    props.onMarketAnalysis(ask);
  };

  useEffect(() => {
    getResearchItem();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="flex align-items-center">{t("자료 조사")}</h3>
        </div>

        <div className="modal-body">
          {/* <select className="form-control mb-10">
            <option>Gemini</option>
            <option>Perplexity</option>
          </select> */}
          <textarea
            className="form-control"
            placeholder={t("조사할 내용을 입력하세요.")}
            value={ask}
            onChange={(e) => setAsk(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                getMarketInfo(e);
              }
            }}
          ></textarea>
          {!loading && (
            <div className="mt-30">
              <h4 style={{ marginBottom: "5px" }}>
                {t("다음 내용을 조사하면 어떨까요?")}{" "}
                <button
                  className="btn btn-sm btn-transparent"
                  onClick={() => getResearchItem()}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              </h4>
              <ul className="title-list">
                {researchList.map((title, index) => (
                  <li className="title-item" key={index}>
                    <a className="title" onClick={() => setAsk(title.title)}>
                      {title.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {loading && (
            <div className="flex mt-30">
              <BeatLoader color="#f2295b" />{" "}
              {t("조사할 항목을 제안 받고 있습니다...")}
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getMarketInfo()}
            disabled={ask === "" ? true : false}
          >
            <i className="fa-solid fa-magnifying-glass-chart"></i>{" "}
            {t("조사 시작하기")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MarketDataModal;

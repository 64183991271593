import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/AuthProvider";
import Loading from "../component/Loading";

function LanguageModal(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const saveLanguage = async (lang) => {
    const res = await $put(`/api/user/lang`, { lang: lang });
    if (res.status === 200) {
      socialLogout();
      props.onModal(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="flex align-items-center">{t("언어 변경")}</h3>
          <p>
            {t(
              "언어를 변경하면 자동으로 로그아웃이 진행됩니다. 변경 후 다시 로그인하세요."
            )}
          </p>
        </div>
        <div className="modal-body">
          {userData && (
            <div className="language-select">
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "EN" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("EN")}
                >
                  English(EN)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "KO" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("KO")}
                >
                  한국어(KO)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "JP" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("JP")}
                >
                  日本語(JP)
                </button>
              </div>
            </div>
          )}
          {userData && (
            <div className="language-select">
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "FR" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("FR")}
                >
                  Français(FR)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "ES" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("ES")}
                >
                  Español(ES)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "DE" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("DE")}
                >
                  Deutsch(DE)
                </button>
              </div>
            </div>
          )}
          {userData && (
            <div className="language-select">
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "IT" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("IT")}
                >
                  Italiano(IT)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "RU" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("RU")}
                >
                  Русский(RU)
                </button>
              </div>
              <div className="language-item">
                <button
                  className={`btn btn-${
                    userData.lang === "NL" ? "primary" : "default"
                  }`}
                  style={{ width: "140px" }}
                  onClick={() => saveLanguage("NL")}
                >
                  Nederlands(NL)
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default LanguageModal;

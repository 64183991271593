import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";
import UsageHistoryModal from "../component/UsageHistoryModal";

function UserDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, tokenData, checkLogin, userData } = useAuth();
  const { email, listPageNo } = location.state || { email: null };

  const [user, setUser] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [usageList, setUsageList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [pageLimit2, setPageLimit2] = useState(10);
  const [pageNo2, setPageNo2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [pageRange2, setPageRange2] = useState([]);
  const [lastPage2, setLastPage2] = useState(1);

  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);
  const [workId, setWorkId] = useState(null);
  const [showUsageHistoryModal, setShowUsageHistoryModal] = useState(false);

  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const paging2 = () => {
    const pages = [];

    const pageFirst =
      pageNo2 % 5 === 0 ? pageNo2 - 4 : Math.floor(pageNo2 / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage2) pageLast = totalPage2;
    setLastPage2(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange2(pages);
  };

  const changePagination = async () => {
    const res = await $get(
      `/api/token/user-month/${email}/${pageNo}/${pageLimit}`
    );
    if (res.status === 200) {
      setTokenList(res.data.tokens);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  const changePaginationUsage = async () => {
    const res = await $get(`/api/usage/user/${email}/${pageNo2}/${pageLimit2}`);
    if (res.status === 200) {
      setUsageList(res.data.usages);
      setTotalPage2(Math.ceil(res.data.total_count / pageLimit2));
      paging2();
    }
  };

  const getLocalDateTime = (utcDate) => {
    const date = new Date(utcDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}T${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
  };

  const getUserDetail = async () => {
    setLoading(true);
    const res = await $get(`/api/user/${email}`);
    if (res.status === 200) {
      const userDetail = res.data;
      if (userDetail.active_start_date) {
        // utc to local
        userDetail.active_start_date = getLocalDateTime(
          userDetail.active_start_date
        );
      }

      if (userDetail.active_end_date) {
        // utc to local
        userDetail.active_end_date = getLocalDateTime(
          userDetail.active_end_date
        );
      }

      setUser(userDetail);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const updateUser = async () => {
    setLoading(true);
    const userData = {
      active_yn: user.active_yn,
      company_id: user.company_id ? user.company_id : null,
    };
    if (
      user.active_yn === "Y" &&
      (user.company_id === null || user.company_id === "")
    ) {
      if (user.active_start_date) {
        userData.active_start_date = new Date(user.active_start_date)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ");
      } else {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: "활성 기간 시작일을 입력해주세요",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return;
      }

      if (user.active_end_date) {
        userData.active_end_date = new Date(user.active_end_date)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ");
      } else {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: "활성 기간 종료일을 입력해주세요",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return;
      }
    } else {
      userData.active_start_date = null;
      userData.active_end_date = null;
    }
    const res = await $put(`/api/user/status/${email}`, userData);
    setLoading(false);
    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 성공",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const updateCompany = async () => {
    setLoading(true);
    const res = await $put(`/api/user/company/${email}`, {
      company_id: user.company_id ? user.company_id : null,
    });
    setLoading(false);
    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 성공",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const addPoint = async () => {
    setLoading(true);
    const res = await $put(`/api/user/point/${email}`, {
      point: point,
    });
    if (res.status === 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "포인트 충전 성공",
        showConfirmButton: false,
        timer: 1000,
      });
      setPoint(0);
      getUserDetail();
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "포인트 충전 실패",
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const payDateFormat = (datetime) => {
    // datetime yyyymmddhhmmss to format yyyy-mm-dd hh:mm:ss
    if (!datetime) return "";
    if (datetime.length < 14) return datetime;
    const year = datetime.substring(0, 4);
    const month = datetime.substring(4, 6);
    const day = datetime.substring(6, 8);
    const hour = datetime.substring(8, 10);
    const minute = datetime.substring(10, 12);
    const second = datetime.substring(12, 14);
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const getCompanyList = async () => {
    setLoading(true);
    const res = await $get(`/api/company/list-all`);
    if (res.status === 200) {
      setCompanyList(res.data);
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    paging2();
  }, [totalPage2]);

  useEffect(() => {
    changePaginationUsage(pageNo2);
  }, [pageNo2]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    getUserDetail();
    getCompanyList();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "url(./assets/images/mockup-02.png)",
        // backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <main className="container" style={{ maxWidth: "1200px" }}>
        {user && (
          <div className="content">
            <div className="content-body align-items-start">
              <div
                className="flex justify-content-between align-items-center mb-10"
                style={{ width: "100%" }}
              >
                <div className="sub-title">{t("사용자 정보")}</div>
                <button
                  className="btn btn-default btn-sm"
                  onClick={() =>
                    navigate("/userlist", { state: { listPageNo: listPageNo } })
                  }
                >
                  {t("목록")}
                </button>
              </div>
              <div className="form-group">
                <label className="form-label">{t("닉네임")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.nickname || ""}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("이름")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.name || ""}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("이메일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.email || ""}
                  disabled={true}
                />
              </div>
              {/* <div className="form-group">
                <label className="form-label">{t("포인트")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={$convertNumberFormat(user.points)}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("포인트 추가")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={point}
                  onChange={(e) => setPoint(e.target.value)}
                />
                <button
                  className="btn btn-primary btn-sm ml-5"
                  onClick={() => addPoint()}
                >
                  {t("충전하기")}
                </button>
              </div> */}
              <div className="form-group">
                <label className="form-label">{t("가입일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    user.create_datetime
                      ? $dateTimezone(user.create_datetime)
                      : ""
                  }
                  disabled={true}
                />
              </div>

              <div className="form-group">
                <label className="form-label">{t("기업")}</label>
                <select
                  className="form-control mr-5"
                  style={{ width: "300px" }}
                  value={user.company_id}
                  onChange={(e) =>
                    setUser({ ...user, company_id: e.target.value })
                  }
                >
                  <option value=""></option>
                  {companyList &&
                    companyList.map((company, idx) => (
                      <option value={company.id} key={`company-${company.id}`}>
                        {company.company_name}
                      </option>
                    ))}
                </select>
                {/* <button
                  className="btn btn-primary btn-sm"
                  onClick={() => updateCompany()}
                >
                  {t("저장")}
                </button> */}
              </div>
              <div className="form-group">
                <label className="form-label">{t("상태")}</label>
                <select
                  className="form-control mr-5"
                  style={{ width: "100px" }}
                  value={user.active_yn}
                  onChange={(e) =>
                    setUser({ ...user, active_yn: e.target.value })
                  }
                >
                  <option value="Y">{t("활성")}</option>
                  <option value="N">{t("비활성")}</option>
                </select>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => updateUser()}
                >
                  {t("저장")}
                </button>
              </div>
              {user.active_yn === "Y" &&
                (user.company_id === null || user.company_id === "") && (
                  <div className="form-group">
                    <label className="form-label">{t("활성 기간")}</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={user.active_start_date}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          active_start_date: e.target.value,
                        });
                      }}
                    />
                    ~
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={user.active_end_date}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          active_end_date: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
              <div
                className="flex justify-content-between align-items-center mb-10 mt-20"
                style={{ width: "100%" }}
              >
                <div className="sub-title">{t("토큰 사용량")}</div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead>
                    <tr>
                      <th>{t("년월")}</th>
                      <th>{t("토큰 인풋")}</th>
                      <th>{t("토큰 아웃풋")}</th>
                      <th>{t("토큰 총합계")}</th>
                      <th>{t("API 비용")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tokenList &&
                      tokenList.map((token, idx) => (
                        <tr key={`token-${idx}`}>
                          <td>{token.date}</td>
                          <td>{$convertNumberFormat(token.prompt_tokens)}</td>
                          <td>
                            {$convertNumberFormat(token.completion_tokens)}
                          </td>
                          <td>{$convertNumberFormat(token.total_tokens)}</td>
                          <td>
                            {/* {`$${$convertNumberFormat(
                              ((token.prompt_tokens * 10 +
                                token.completion_tokens * 30) /
                                1000000) *
                                1.2,
                              "#,###.##"
                            )}`} */}

                            {parseInt(token.date.substring(0, 4)) > 2024 ||
                            (parseInt(token.date.substring(0, 4)) === 2024 &&
                              parseInt(token.date.substring(5, 7)) > 4)
                              ? `$${$convertNumberFormat(
                                  ((token.prompt_tokens * 5 +
                                    token.completion_tokens * 15) /
                                    1000000) *
                                    1.2,
                                  "#,###.##"
                                )}`
                              : `$${$convertNumberFormat(
                                  ((token.prompt_tokens * 10 +
                                    token.completion_tokens * 30) /
                                    1000000) *
                                    1.2,
                                  "#,###.##"
                                )}`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {totalPage > 1 && (
                  <div className="d-flex justify-content-center mt-10">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {pageNo > 5 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo(pageNo - 1)}
                            >
                              <i className="fa-solid fa-arrow-left"></i>
                            </a>
                          </li>
                        )}

                        {pageRange.map((page) => (
                          <li key={page}>
                            <a
                              className={
                                pageNo === page
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={(e) => setPageNo(page)}
                            >
                              {page}
                            </a>
                          </li>
                        ))}

                        {lastPage < totalPage && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo(pageNo + 1)}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
              <div
                className="flex justify-content-between align-items-center mb-10 mt-40"
                style={{ width: "100%" }}
              >
                <div className="sub-title font-nanumMyeongjo">
                  기능 사용 내역
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead>
                    <tr>
                      <th width="*">{t("타이틀")}</th>
                      <th width="100px">{t("사용 수")}</th>
                      <th width="140px">{t("토큰 인풋")}</th>
                      <th width="140px">{t("토큰 아웃풋")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usageList &&
                      usageList.map((usage, idx) => (
                        <tr key={`usage-${idx}`}>
                          <td>{usage.title}</td>
                          <td>
                            <a
                              onClick={() => {
                                setWorkId(usage.work_id);
                                setShowUsageHistoryModal(true);
                              }}
                              style={{ textDecoration: "underline" }}
                            >
                              {$convertNumberFormat(usage.func_key_count)}
                            </a>
                          </td>
                          <td>{$convertNumberFormat(usage.prompt_tokens)}</td>
                          <td>
                            {$convertNumberFormat(usage.completion_tokens)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {totalPage2 > 1 && (
                  <div className="d-flex justify-content-center mt-10">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {pageNo2 > 5 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo2(pageNo2 - 1)}
                            >
                              <i className="fa-solid fa-arrow-left"></i>
                            </a>
                          </li>
                        )}

                        {pageRange2.map((page) => (
                          <li key={page}>
                            <a
                              className={
                                pageNo2 === page
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={(e) => setPageNo2(page)}
                            >
                              {page}
                            </a>
                          </li>
                        ))}

                        {lastPage2 < totalPage2 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo2(pageNo2 + 1)}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {showUsageHistoryModal && (
          <UsageHistoryModal
            workId={workId}
            roleLevel={userData.role_level}
            onModal={setShowUsageHistoryModal}
          />
        )}

        {loading && <Loading />}
      </main>
    </div>
  );
}

export default UserDetail;

import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { $get, $put, $post, $convertMarkdownToHtml } from "../utils/common";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

function GoogleSearchModal(props) {
  const { t, i18n } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchKeywordList, setSearchKeywordList] = useState([]);

  let bProgress = false;

  const getGoogleSearch = async (id) => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    const res = await $post(`/api/gsearch`, {
      workId: props.workId,
      keyword: keyword,
    });
    if (res.status === 200) {
      setSearchList(res.data);
    }

    bProgress = false;
    setLoading(false);
  };

  const getSearchKeyword = async (id) => {
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    const res = await $post(`/api/prompt/search-keyword`, {
      workId: props.workId,
    });
    if (res.status === 200) {
      setSearchKeywordList(res.data);
      console.log(res.data);
    }

    bProgress = false;
    setLoading(false);
  };

  useEffect(() => {
    // getSearchKeyword();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="flex align-items-center">{t("자료 검색")}</h3>
        </div>

        <div className="modal-body">
          <input
            type="text"
            className="form-control"
            placeholder={t("키워드를 입력하세요.")}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                getGoogleSearch(e);
              }
            }}
          />
          {!loading && (
            <div className="mt-30">
              <ul className="title-list">
                {searchList.map((item, index) => (
                  <li
                    className="title-item"
                    style={{ padding: "0px" }}
                    key={index}
                  >
                    {/* <div className="flex flex-column"> */}
                    <a
                      className="title flex-column"
                      href={item.link}
                      target="_blank"
                    >
                      {item.title}
                      <small>{item.link}</small>
                    </a>

                    {/* </div> */}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {loading && (
            <div className="flex mt-30">
              <BeatLoader color="#f2295b" /> {t("검색중입니다...")}
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getGoogleSearch()}
            disabled={keyword === "" ? true : false}
          >
            <i className="fa-solid fa-search"></i> {t("검색")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GoogleSearchModal;

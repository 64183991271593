import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $download,
  $upload,
  $checkOpenAIAvailable,
  $convertMarkdownToHtml,
  $convertMarkdownToHtmlRemoveHeader,
} from "../utils/common";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import ParagraphAssistantModal from "./ParagraphAssistantModal";
import MobileSimulator from "./MobileSimulator";
import InfoModal from "./InfoModal";
import TitleAssistantModal from "./TitleAssistantModal";
import TableContentAssistantModal from "./TableContentAssistantModal";
import ImageModal from "./ImageModal";
import Loading from "./Loading";
import BeatLoader from "react-spinners/BeatLoader";
import LinkAnalysisModal from "./LinkAnalysisModal";
import LinkDataModal from "./LinkDataModal";
import SpellCheckerModal from "./SpellCheckerModal";
import ForbidCheckerModal from "./ForbidCheckerModal";
import FileAnalysisModal from "./FileAnalysisModal";
import FileDataModal from "./FileDataModal";
import MarketAssistantModal from "./MarketAssistantModal";
import MarketDataModal from "./MarketDataModal";
import MemoModal from "./MemoModal";
import SEOModal from "./SEOModal";
import ShareLinkModal from "./ShareLinkModal";
import ImageAnalysisDataModal from "./ImageAnalysisDataModal";
import GlossaryModal from "./GlossaryModal";
import YoutubeDataModal from "./YoutubeDataModal";
import TranslationAssistantModal from "./TranslationAssistantModal";
import GoogleSearchModal from "./GoogleSearchModal";

const TGEditor = (props) => {
  const clientUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, userData, checkLogin } = useAuth();
  const { t, i18n } = useTranslation();

  // const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [beatLoading, setBeatLoading] = useState(false);
  const [beatLoading2, setBeatLoading2] = useState(false);
  const [localStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [useAI, setUseAI] = useState(false);
  const [workId, setWorkId] = useState(props.workId);
  const [workTitle, setWorkTitle] = useState("");
  const [workContent, setWorkContent] = useState("");
  const [workContentHistory, setWorkContentHistory] = useState([]);
  const [workContentHistoryIndex, setWorkContentHistoryIndex] = useState(-1);
  const [info, setInfo] = useState({});
  const [workResearchList, setWorkResearchList] = useState([]);
  const [processingWorkResearchList, setProcessingWorkResearchList] = useState(
    []
  );
  const [translationDocumentList, setTranslationDocumentList] = useState([]);
  const [glossaryList, setGlossaryList] = useState([]);
  const [showGlossaryModal, setShowGlossaryModal] = useState(false);
  const [editAvailable, setEditAvailable] = useState(false);
  const [assistantMessage, setAssistantMessage] = useState(null);
  const [workContentLength, setWorkContentLength] = useState(0);
  const [lastModifiedDatetime, setLastModifiedDatetime] = useState(new Date());
  const [activeTd, setActiveTd] = useState(null);
  const [currentCursorNode, setCurrentCursorNode] = useState(null);
  const [assistantModalOpen, setAssistantModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [showTablePopover, setShowTablePopover] = useState(false);
  const [showAIPopover, setShowAIPopover] = useState(false);
  const [AIPopoverPosition, setAIPopoverPosition] = useState({
    top: 0,
    left: 0,
  });
  const [cursorPositionTop, setCursorPositionTop] = useState(0);
  const [paragraphAsssitantType, setParagraphAsssitantType] = useState("");
  const [showCurrentCursor, setShowCurrentCursor] = useState(false);
  const [showParagraphModal, setShowParagraphModal] = useState(false);
  const [showTranslationModal, setShowTranslationModal] = useState(false);
  const [translationAsssitantType, setTranslationAsssitantType] =
    useState("EN");
  const [showMobileSimulator, setShowMobileSimulator] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [showTableContentModal, setShowTableContentModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showChatbox, setShowChatbox] = useState(false);
  const [showLinkAnalysisModal, setShowLinkAnalysisModal] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);
  const [comment, setComment] = useState("");
  const [mainFont, setMainFont] = useState("Dotum");
  const [fontSize, setFontSize] = useState(16);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [spellCheckText, setSpellCheckText] = useState([]);
  const [showSpellCheckModal, setShowSpellCheckModal] = useState(false);
  const [showForbidCheckModal, setShowForbidCheckModal] = useState(false);
  const [showFileAnalysisModal, setShowFileAnalysisModal] = useState(false);
  const [showImageAnalysisModal, setShowImageAnalysisModal] = useState(false);
  const [showMarketAssistantModal, setShowMarketAssistantModal] =
    useState(false);
  const [showGoogleSearchModal, setShowGoogleSearchModal] = useState(false);
  const [showSEOModal, setShowSEOModal] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [showYoutubeDataModal, setShowYoutubeDataModal] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [stickNotesRightPosition, setStickNotesRightPosition] =
    useState("20px");
  const [fullScreen, setFullScreen] = useState(false);
  const contentRef = useRef(null);
  const scrollRef = useRef(null);
  const noteRef = useRef(null);
  const assistantRef = useRef(null);
  const attachRef = useRef(null);
  const btnBoldRef = useRef(null);
  const btnItalicRef = useRef(null);
  const btnUnderlineRef = useRef(null);
  const btnStrikethroughRef = useRef(null);
  const btnBiggerRef = useRef(null);
  const btnSmallerRef = useRef(null);

  // navigator.platform 사용하지 않고, 맥이면 cmd, 윈도우면 ctrl
  const metaKey = navigator.userAgent.indexOf("Mac") > -1 ? "cmd" : "ctrl";

  const koreanFontFamily = [
    { className: "font-gothic", title: "고딕" },
    { className: "font-dotum", title: "돋움" },
  ];

  const fontSizeList = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32];

  const colors = [
    { className: "bg-black", color: "#000000" },
    // { className: "bg-white", color: "#ffffff" },
    { className: "bg-cyan", color: "#00ffff" },
    { className: "bg-red", color: "#f2295b" },
    { className: "bg-orange", color: "#f7931e" },
    { className: "bg-yellow", color: "#ffce00" },
    { className: "bg-green", color: "#00b14f" },
    { className: "bg-blue", color: "#00a1e9" },
    { className: "bg-purple", color: "#6639b6" },
    { className: "bg-pink", color: "#ec008c" },
    { className: "bg-gray", color: "#808080" },
    { className: "bg-lightgray", color: "#d3d3d3" },
    { className: "bg-darkgray", color: "#a9a9a9" },
    { className: "bg-lightblue", color: "#add8e6" },
    { className: "bg-lightgreen", color: "#90ee90" },
    { className: "bg-lightpink", color: "#ffb6c1" },
    { className: "bg-lightyellow", color: "#ffffe0" },
    { className: "bg-lightpurple", color: "#e6e6fa" },
    { className: "bg-lightorange", color: "#ffcc99" },
    { className: "bg-lightred", color: "#ffcccc" },
  ];

  const openLeftSidebar = () => {
    if (!props.leftSidebarOpen) {
      setShowMarketAssistantModal(false);
      setShowSpellCheckModal(false);
      setShowForbidCheckModal(false);
      setShowGlossaryModal(false);
    }

    // setLeftSidebarOpen(!leftSidebarOpen);
    props.onToggle();
    window.setTimeout(() => {
      // changeStickNotesRightPosition();
    }, 500);
  };

  const handleInputChange = (bScroll = true) => {
    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }
    // 공백 제거 regex
    if (noteRef.current.innerText.replace(/\s/g, "") === "") {
      noteRef.current.innerHTML = "";
    }

    // noteRef 안의 텍스트 노드의 총 텍스트 길이 합
    const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
      (acc, cur) => {
        // if (cur.nodeType === Node.TEXT_NODE) {
        return acc + cur.textContent.replace(/\s/g, "").length;
        // } else {
        //   return acc;
        // }
      },
      0
    );

    setWorkContentLength(totalTextLength);
    if (bScroll) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

    const selection = document.getSelection();
    const activeNode = selection.anchorNode;

    setTimeout(() => {
      if (
        activeNode &&
        noteRef.current &&
        noteRef.current.contains(activeNode)
      ) {
        setCurrentCursorNode(activeNode);
      }
    }, 500);

    // lastModifiedDatetime과 현재 datetime을 비교해서 60초 이상 차이가 나면 자동 저장
    const now = new Date();
    const diff = now.getTime() - lastModifiedDatetime.getTime();

    // localStorage에 beingSaveWork 가 있는지 확인
    // beingSaveWork 가 true이면 자동 저장 중이므로 return
    if (
      localStorage.getItem("beingSaveWork") === undefined ||
      localStorage.getItem("beingSaveWork") === null ||
      localStorage.getItem("beingSaveWork") === "true"
    )
      return;

    if (diff > 60000) {
      localStorage.setItem("beingSaveWork", true);
      setTimeout(() => {
        if (!loading) saveWork();
        setLastModifiedDatetime(now);
        localStorage.setItem("beingSaveWork", false);
      }, 1000);
    }
  };

  const handleSelectionChange = () => {
    if (assistantModalOpen) return;
    const selection = window.getSelection();
    const tempSelectedText = selection.toString().trim();
    // console.log(tempSelectedText);
    if (
      tempSelectedText.length > 0 &&
      noteRef.current.contains(selection.anchorNode)
    ) {
      setSelectedText(tempSelectedText);
      const range = selection.getRangeAt(0);
      setSelectedRange(range);
      const rect = range.getBoundingClientRect();
      // 선택된 텍스트의 위치를 기준으로 popover가 부모 요소의 왼쪽이나 오른쪽을 벗어나지 않도록 위치 조정
      //   const top = rect.bottom;
      //   const left = rect.left;
      //   const right = rect.left + window.scrollX + rect.width;
      //   setAIPopoverPosition({
      //     top: top,
      //     left: left,
      //   });

      setAIPopoverPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + rect.width / 2,
        // left: rect.left + window.scrollX + rect.width / 2,
      });

      //   setShowAIPopover(true);
    } else {
      setShowAIPopover(false);
    }
  };

  const getDirectParentElement = (e) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    let parentElement = range.commonAncestorContainer;

    // 텍스트 노드가 반환될 경우, 부모 요소를 얻음
    if (parentElement.nodeType === Node.TEXT_NODE) {
      parentElement = parentElement.parentNode;
    }

    return parentElement;
  };

  const htmlStringToNode = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    if (doc.body.childNodes.length === 1) {
      if (doc.body.firstChild.nodeType === Node.TEXT_NODE) {
        return doc.body.firstChild.textContent;
      } else {
        return doc.body.firstChild.innerHTML;
      }
    } else {
      return doc.body.innerHTML;
    }
  };

  const checkNode = (nodeName) => {
    let node = getDirectParentElement();
    while (
      node !== noteRef.current &&
      node.nodeName !== nodeName &&
      node.nodeName !== "P"
    ) {
      node = node.parentNode;
    }

    const html = node.outerHTML;
    const startTagRegexp = new RegExp(`<${nodeName}>`, "g");
    const endTagRegexp = new RegExp(`<\/${nodeName}>`, "g");
    // console.log("html", html);
    if (html.indexOf(`<${nodeName}>`) > -1) {
      const ele = htmlStringToNode(
        html.replace(startTagRegexp, "").replace(endTagRegexp, "")
      );
      return [true, ele, node];
    }

    return [false, null];
  };

  const applyFormat = (format, color) => {
    const selection = window.getSelection();
    if (noteRef.current.contains(selection.anchorNode)) {
      // setSelectedText(selectedText);
      // const range = selection.getRangeAt(0);
      // setSelectedRange(range);
      const range = selectedRange;

      if (format === "bold" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "STRONG") {
        //   const strong = getDirectParentElement();
        //   const text = strong.innerText;
        //   strong.parentNode.removeChild(strong);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const strong = document.createElement("strong");
        //   strong.appendChild(range.extractContents());
        //   range.insertNode(strong);
        // }

        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<strong>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace(/<strong>/g, "")
            .replace(/<\/strong>/g, "");
        } else {
          const node = checkNode("strong");
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const strong = document.createElement("strong");
            strong.appendChild(range.extractContents());
            range.insertNode(strong);
          }
        }
      } else if (format === "italic" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "EM") {
        //   const em = getDirectParentElement();
        //   const text = em.innerText;
        //   em.parentNode.removeChild(em);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const em = document.createElement("em");
        //   em.appendChild(range.extractContents());
        //   range.insertNode(em);
        // }

        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<em>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace(/<em>/g, "")
            .replace(/<\/em>/, "");
        } else {
          const node = checkNode("em");
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const em = document.createElement("em");
            em.appendChild(range.extractContents());
            range.insertNode(em);
          }
        }
      } else if (format === "underline" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "U") {
        //   const u = getDirectParentElement();
        //   const text = u.innerText;
        //   u.parentNode.removeChild(u);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const u = document.createElement("u");
        //   u.appendChild(range.extractContents());
        //   range.insertNode(u);
        // }
        const ele = getDirectParentElement();
        // console.log(ele.outerHTML.st)
        if (ele.outerHTML.indexOf("<u>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace(/<u>/g, "")
            .replace(/<\/u>/g, "");
        } else {
          const node = checkNode("u");
          if (node[0]) {
            node[2].innerHTML = node[1];
          } else {
            const u = document.createElement("u");
            u.appendChild(range.extractContents());
            range.insertNode(u);
          }
        }
      } else if (format === "strikethrough" && selectedText.length > 0) {
        // if (getDirectParentElement().nodeName === "S") {
        //   const s = getDirectParentElement();
        //   const text = s.innerText;
        //   s.parentNode.removeChild(s);
        //   range.insertNode(document.createTextNode(text));
        // } else {
        //   const s = document.createElement("s");
        //   s.appendChild(range.extractContents());
        //   range.insertNode(s);
        // }
        const ele = getDirectParentElement();
        if (ele.outerHTML.indexOf("<s>") > -1) {
          ele.outerHTML = ele.outerHTML
            .replace(/<s>/g, "")
            .replace(/<\/s>/g, "");
        } else {
          const node = checkNode("s");
          if (node[0]) {
            node[2].innerHTML = node[1];
            // node[2].outerHTML = node[2].innerHTML;
          } else {
            const s = document.createElement("s");
            s.appendChild(range.extractContents());
            range.insertNode(s);
          }
        }
      } else if (format === "bigger" && selectedText.length > 0) {
        // if (selectedText.trim() === "") {
        //   Swal.fire({
        //     iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        //     title: t("문장을 선택하세요."),
        //     showConfirmButton: false,
        //     timer: 1000,
        //   });
        //   return;
        // }
        // if (getDirectParentElement().nodeName === "P") {
        //   const s = document.createElement("span");
        //   s.style.fontSize = "1.2rem";
        //   s.appendChild(range.extractContents());
        //   range.insertNode(s);
        // }

        // fontSize + 0.2 + "rem"
        const fontSize = getDirectParentElement().style.fontSize
          ? parseFloat(getDirectParentElement().style.fontSize)
          : 1;

        getDirectParentElement().style.fontSize = fontSize + 0.2 + "rem";
      } else if (format === "smaller" && selectedText.length > 0) {
        // if (
        //   getDirectParentElement().nodeName === "SPAN" &&
        //   getDirectParentElement().style.fontSize === "1.2rem"
        // ) {
        //   const span = getDirectParentElement();
        //   const text = span.innerText;
        //   span.parentNode.removeChild(span);
        //   range.insertNode(document.createTextNode(text));
        // }

        let fontSize = getDirectParentElement().style.fontSize
          ? parseFloat(getDirectParentElement().style.fontSize) - 0.2
          : 1 - 0.2;

        if (fontSize < 1) fontSize = 1;
        if (fontSize === 1) {
          getDirectParentElement().style.fontSize = "";
        } else {
          getDirectParentElement().style.fontSize = fontSize + "rem";
        }
      } else if (format === "quote" && selectedText.length > 0) {
        if (getDirectParentElement().nodeName !== "Q") {
          const parentElement = getDirectParentElement();
          if (parentElement.innerText.trim() === selectedText.trim()) {
            const q = document.createElement("q");
            const text = document.createTextNode(selectedText);

            q.style.textAlign = "center";
            q.style.fontSize = "1.2rem";
            q.style.fontWeight = "700";
            q.appendChild(text);

            parentElement.parentNode.insertBefore(q, parentElement.nextSibling);
            parentElement.parentNode.removeChild(parentElement);
          }
        } else {
          const q = getDirectParentElement();
          const text = q.innerText;
          q.parentNode.removeChild(q);
          const p = document.createElement("p");
          p.appendChild(document.createTextNode(text));
          range.insertNode(p);
        }
      } else if (format === "alignLeft") {
        getDirectParentElement().style.textAlign = "left";
      } else if (format === "alignCenter") {
        getDirectParentElement().style.textAlign = "center";
      } else if (format === "alignRight") {
        getDirectParentElement().style.textAlign = "right";
      } else if (format === "alignJustify") {
        const p = document.createElement("p");
        p.style.textAlign = "justify";
        p.appendChild(range.extractContents());
        range.insertNode(p);
      } else if (format === "indent") {
        if (getDirectParentElement().style.paddingLeft === "") {
          getDirectParentElement().style.paddingLeft = "20px";
        } else {
          getDirectParentElement().style.paddingLeft =
            parseInt(getDirectParentElement().style.paddingLeft) + 20 + "px";
        }
      } else if (format === "outdent") {
        if (parseInt(getDirectParentElement().style.paddingLeft) >= 20) {
          getDirectParentElement().style.paddingLeft =
            parseInt(getDirectParentElement().style.paddingLeft) - 20 + "px";
        }
      } else if (format === "color") {
        setShowTextColorPicker(false);
        if (getDirectParentElement().nodeName === "SPAN") {
          getDirectParentElement().parentNode.removeChild(span);
          const span = document.createElement("span");
          span.style.color = color;
          span.appendChild(range.extractContents());
          range.insertNode(span);
        } else {
          const span = document.createElement("span");
          span.style.color = color;
          span.appendChild(range.extractContents());
          range.insertNode(span);
        }
      }
    }

    setSelectedRange(null);
    setSelectedText("");
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  };

  const removeSelectedText = () => {
    if (selectedRange) {
      selectedRange.deleteContents();
      setSelectedRange(null);
      setSelectedText("");
      setShowAIPopover(false);
    }
  };

  const hidePopover = (e) => {
    // double click return;
    if (e.detail > 1) return;

    if (e.target.closest(".fa-robot")) return;
    if (e.target.closest(".fa-message")) return;

    setShowAIPopover(false);
  };

  const writeDirect = async () => {
    if (!noteRef.current) return;

    sessionStorage.setItem("stopAssistant", false);

    let url = `${serverUrl}/api/prompt/write`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    setWorkContent("");

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      // value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      setAssistantMessage((prev) => prev + value);
      tempAssistantMessage.push(value);
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }

    // sessionStorage.setItem("stopAssistant", false);

    // const result = tempAssistantMessage.join("").split("<br />");

    // const resHtml = [];
    // result.forEach((item) => {
    //   if (item === "") return;
    //   if (item === null) return;
    //   resHtml.push(`<p>${item}</p>`);
    // });

    setAssistantMessage("");
    // setWorkContent(resHtml.join("") + "<p><br></p>");
    setWorkContent($convertMarkdownToHtml(tempAssistantMessage.join("")));

    setTimeout(() => {
      saveWork();
    }, 2000);

    setLoading(false);
    handleInputChange();
  };

  const writeNextParagraph = async () => {
    if (!noteRef.current) return;

    const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
      (acc, cur) => {
        return acc + cur.textContent.replace(/\s/g, "").length;
      },
      0
    );

    if (totalTextLength < 200) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("이어쓰기를 할 수 없습니다."),
        text: t("내용이 너무 짧습니다."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    sessionStorage.setItem("stopAssistant", false);
    setBeatLoading(true);

    let url = `${serverUrl}/api/prompt/next-paragraph`;

    // workContent: 2000자를 넘는 경우 최근 2000자만 전송, 단 2000가 문장의 중간에 위치할 문장이 잘리지 않도록 전체 문장을 전송
    let latestContent = noteRef.current.innerText;
    if (latestContent.length > 2000) {
      latestContent = latestContent.slice(-2000);
      latestContent = latestContent.slice(latestContent.indexOf(".") + 1);
    }

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        workTitle: workTitle,
        // workContent: noteRef.current.innerText,
        workContent: latestContent,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    assistantRef.current.style.display = "block";

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      // value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      setAssistantMessage((prev) => prev + value);
      tempAssistantMessage.push(value);
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }

    sessionStorage.setItem("stopAssistant", false);

    // const result = tempAssistantMessage.join("").split("<br />");

    // const resHtml = [];
    // result.forEach((item) => {
    //   if (item === "") return;
    //   if (item === null) return;
    //   resHtml.push(`<p>${item}</p>`);
    // });

    assistantRef.current.style.display = "none";

    setAssistantMessage("");
    // setWorkContent(
    //   noteRef.current.innerHTML + resHtml.join("") + "<p><br></p>"
    // );
    setWorkContent(
      noteRef.current.innerHTML +
        $convertMarkdownToHtml(tempAssistantMessage.join("")) +
        "<p><br></p>"
    );

    setTimeout(() => {
      saveWork();
    }, 2000);

    setBeatLoading(false);
    setLoading(false);
    handleInputChange();
  };

  const insertParagraphNode = (parentNode, activeNode, node) => {
    // parentNode의 모든 childNode를 순회하면서 activeNode를 찾고, activeNode 앞에 node를 삽입
    if (parentNode.childNodes.length > 0) {
      for (let i = 0; i < parentNode.childNodes.length; i++) {
        if (parentNode.childNodes[i] === activeNode) {
          parentNode.insertBefore(node, activeNode);
          return;
        } else {
          if (parentNode.childNodes[i].childNodes.length > 0) {
            insertParagraphNode(parentNode.childNodes[i], activeNode, node);
          }
        }
      }
    } else {
      parentNode.insertBefore(node, activeNode);
    }
  };

  const writeNextParagraphBetween = async () => {
    if (!noteRef.current) return;
    if (loading) return;

    const totalTextLength = Array.from(noteRef.current.childNodes).reduce(
      (acc, cur) => {
        return acc + cur.textContent.replace(/\s/g, "").length;
      },
      0
    );

    if (totalTextLength < 300) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("이어쓰기를 할 수 없습니다."),
        text: t("300자 이상 작성해야 이어쓰기를 사용할 수 있습니다."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    const selection = document.getSelection();
    const activeNode = selection.anchorNode;

    if (activeNode && noteRef.current && noteRef.current.contains(activeNode)) {
    } else {
      writeNextParagraph();
      return;
    }

    sessionStorage.setItem("stopAssistant", false);
    setBeatLoading(true);

    let url = `${serverUrl}/api/prompt/between-paragraph`;

    let previousNodes = [];
    let tempActiveNode = activeNode;
    while (true) {
      if (tempActiveNode.previousSibling) {
        tempActiveNode = tempActiveNode.previousSibling;
        previousNodes.push(tempActiveNode.textContent);
      } else {
        if (previousNodes.length === 0) {
          if (tempActiveNode.parentNode !== noteRef.current) {
            tempActiveNode = tempActiveNode.parentNode;
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    let previousContent = previousNodes.reverse().join("");

    if (previousContent.length > 1000) {
      previousContent = previousContent.slice(-1000);
      previousContent = previousContent.slice(previousContent.indexOf(".") + 1);
    }

    let nextNodes = [];
    tempActiveNode = activeNode;
    while (true) {
      if (tempActiveNode.nextSibling) {
        tempActiveNode = tempActiveNode.nextSibling;
        nextNodes.push(tempActiveNode.textContent);
      } else {
        if (nextNodes.length === 0) {
          if (tempActiveNode.parentNode !== noteRef.current) {
            tempActiveNode = tempActiveNode.parentNode;
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    let nextContent = nextNodes.join("");

    if (nextContent.length > 1000) {
      nextContent = nextContent.slice(0, 1000);
      nextContent = nextContent.slice(0, nextContent.lastIndexOf(".") + 1);
    }

    const div = document.createElement("div");
    div.style.backgroundColor = "#f5f5f5";

    insertParagraphNode(noteRef.current, activeNode, div);

    // noteRef.current의 모든 childNode를 순회하면서 activeNode를 찾고, activeNode 앞에 div를 삽입

    noteRef.current.childNodes.forEach((node) => {
      if (node === activeNode) {
        noteRef.current.insertBefore(div, node);
      }
    });

    const p2 = document.createElement("p");
    p2.appendChild(document.createElement("br"));
    activeNode.parentNode.insertBefore(p2, div.nextSibling);

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        workTitle: workTitle,
        previousContent: previousContent,
        nextContent: nextContent,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);
      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      div.innerHTML += value;
    }

    div.innerHTML = $convertMarkdownToHtml(tempAssistantMessage.join(""));
    div.removeAttribute("style");

    sessionStorage.setItem("stopAssistant", false);

    setAssistantMessage("");
    setSelectedRange(null);
    setSelectedText("");

    setTimeout(() => {
      saveWork();
    }, 2000);

    setBeatLoading(false);

    setLoading(false);
    handleInputChange(false);
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  const requestChat = async () => {
    if (!noteRef.current) return;

    if (comment === "") return;

    const requestText = comment;
    setComment("");
    setShowChatbox(false);

    setBeatLoading(true);

    sessionStorage.setItem("stopAssistant", false);

    let div = null;
    if (currentCursorNode) {
      div = document.createElement("div");
      div.style.backgroundColor = "#f5f5f5";
      currentCursorNode.parentNode.insertBefore(
        div,
        currentCursorNode.nextSibling
      );

      const p2 = document.createElement("p");
      p2.appendChild(document.createElement("br"));
      currentCursorNode.parentNode.insertBefore(p2, div.nextSibling);
    }

    let url = `${serverUrl}/api/prompt/request`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        workTitle: workTitle,
        workContent: noteRef.current.innerText,
        requestText: requestText,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");

      if (currentCursorNode && div) {
        div.innerHTML += value;
      } else {
        setAssistantMessage((prev) => prev + value);
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }

    div.removeAttribute("style");
    sessionStorage.setItem("stopAssistant", false);

    if (currentCursorNode && div) {
      div.innerHTML = $convertMarkdownToHtmlRemoveHeader(
        tempAssistantMessage.join("")
      );
    } else {
      setAssistantMessage("");
      setWorkContent(
        noteRef.current.innerHTML +
          $convertMarkdownToHtmlRemoveHeader(tempAssistantMessage.join("")) +
          "<p><br></p>"
      );
    }

    setTimeout(() => {
      saveWork();
    }, 2000);

    setLoading(false);
    setBeatLoading(false);
    handleInputChange();
  };

  const convertHTMLStringToElements = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return Array.from(doc.body.childNodes);
  };

  const changeParagraph = (paragraph) => {
    const range = window.getSelection().getRangeAt(0);
    const newNodes = convertHTMLStringToElements(paragraph).reverse();

    range.deleteContents();
    newNodes.forEach((node) => {
      range.insertNode(node);
    });

    // selectedRange.innerHTML = paragraph;

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    setSelectedRange(null);
    setSelectedText("");
    setTimeout(() => {
      saveWork();
    }, 2000);
  };

  //   키보드 이벤트를 처리하는 함수
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "s") {
      event.preventDefault();
      saveWork();
    }
  };

  const copyWorkContent = () => {
    // navigator.clipboard.writeText(noteRef.current.innerText);
    // noteRef.current 전체를 선택한 후 클립보드 복사
    const range = document.createRange();
    range.selectNodeContents(noteRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();

    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("복사 완료"),
      text: t("클립보드에 복사되었습니다."),
      showConfirmButton: false,
      timer: 1000,
    });
  };

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const res = await $download(`/api/work/convert/pdf/${props.workId}`);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${workTitle ? workTitle : "untitled"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("PDF 다운로드 중 오류 발생:", error);
    }
    setLoading(false);
  };

  const downloadDocx = async () => {
    setLoading(true);
    try {
      const res = await $download(`/api/work/convert/docx/${props.workId}`);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${workTitle ? workTitle : "untitled"}.docx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("DOCX 다운로드 중 오류 발생:", error);
    }
    setLoading(false);
  };

  const changeWorkContentHistory = (changeIndex) => {
    const tempWorkContentHistory = JSON.parse(
      localStorage.getItem("workContentHistory")
    );

    if (tempWorkContentHistory.length === 0) return;

    if (workContentHistoryIndex + changeIndex < 0) {
      localStorage.setItem("workContentHistoryIndex", -1);
      setWorkContentHistoryIndex(-1);
      return;
    }

    if (workContentHistoryIndex + changeIndex >= workContentHistory.length)
      return;

    localStorage.setItem(
      "workContentHistoryIndex",
      workContentHistoryIndex + changeIndex
    );
    setWorkContent(
      tempWorkContentHistory[workContentHistoryIndex + changeIndex]
    );
    setWorkContentHistoryIndex(workContentHistoryIndex + changeIndex);
  };

  const saveWork = async (title) => {
    if (props.workId === null) {
      return;
    }

    setLoading(true);

    // setBeatLoading(true);

    if (noteRef.current) {
      if (noteRef.current.innerText.replace(/\s/g, "") === "") {
        noteRef.current.innerHTML = "";
      }
    }

    // noteRef.current.innerHTML에서 모든 style="background-color: var(--tg-white); color: var(--tg-text-main); font-family: inherit; font-size: inherit; font-style: inherit; font-variant-ligatures: inherit; font-variant-caps: inherit; font-weight: inherit;" 제거
    let noteHtml = noteRef.current.innerHTML;
    // 모든 <!--StartFragment-->와 <!--EndFragment--> 제거
    noteHtml = noteHtml.replace(/<!--StartFragment-->/g, "");
    noteHtml = noteHtml.replace(/<!--EndFragment-->/g, "");
    const regexp =
      /style="background-color: var\(--tg-white\); color: var\(--tg-text-main\); font-family: inherit; font-size: inherit; font-style: inherit; font-variant-ligatures: inherit; font-variant-caps: inherit; font-weight: inherit;"/g;
    if (regexp.test(noteHtml)) {
      const tempNote = noteHtml.replace(regexp, "");

      noteRef.current.innerHTML = tempNote;
      noteHtml = tempNote;
    }

    // const workContent = noteRef.current.innerHTML;
    const res = await $put(`/api/work/${props.workId}`, {
      content: noteHtml,
      title: title !== undefined ? title : workTitle,
    });

    if (res.status === 200) {
      if (isLocalStorageSupported()) {
        const lastSavedWorkContent = JSON.parse(
          localStorage.getItem("workContentHistory")
        );
        if (
          lastSavedWorkContent[lastSavedWorkContent.length - 1] !== noteHtml
        ) {
          const tempWorkContentHistory = [
            ...JSON.parse(localStorage.getItem("workContentHistory")),
            noteHtml,
          ];

          if (tempWorkContentHistory.length > 30) {
            setWorkContentHistory(tempWorkContentHistory.slice(-30));
            localStorage.setItem(
              "workContentHistory",
              JSON.stringify(tempWorkContentHistory.slice(-30))
            );
          } else {
            setWorkContentHistory(tempWorkContentHistory);
            localStorage.setItem(
              "workContentHistory",
              JSON.stringify(tempWorkContentHistory)
            );
          }

          setWorkContentHistoryIndex(tempWorkContentHistory.length - 1);
          localStorage.setItem(
            "workContentHistoryIndex",
            tempWorkContentHistory.length - 1
          );
        }
      }

      props.onList();
    }

    setLoading(false);

    // setBeatLoading(false);
  };

  const getWork = async () => {
    if (props.workId === null) {
      setWorkContent("");
      setWorkTitle("");
      setWorkContentLength(0);
      return;
    }

    setLoading(true);
    const res = await $get(`/api/work/${props.workId}`);
    if (res.status === 200) {
      setWorkContent(res.data.content);
      setWorkTitle(res.data.title);
      setWorkId(res.data.id);
      // res.data.content의 html 문자열에서 순수 텍스트 노드의 텍스트 길의 총합
      const totalTextLength = Array.from(
        convertHTMLStringToElements(res.data.content)
      ).reduce((acc, cur) => {
        return acc + cur.textContent.replace(/\s/g, "").length;
      }, 0);

      setWorkContentLength(totalTextLength);

      // setWorkContentLength(res.data.content.replace(/\s/g, "").length);
      setInfo({
        subject: res.data.subject,
        keyword: res.data.keyword,
        forbid: res.data.forbid,
        reference: res.data.reference,
        type: res.data.type,
        need_table_content: res.data.need_table_content,
        table_content_type: res.data.table_content_type,
        need_write_next: res.data.need_write_next,
        need_write_direct: res.data.need_write_direct,
      });

      if (res.data.subject) setEditAvailable(true);
    }

    setLoading(false);
  };

  const insertContent = (content) => {
    if (noteRef.current.innerText === "") {
      noteRef.current.innerHTML = "<div>" + content + "</div>";
      return;
    }

    const selection = document.getSelection();
    const activeNode = selection.anchorNode;

    if (activeNode && noteRef.current && noteRef.current.contains(activeNode)) {
      // console.log("현재 커서가 위치한 노드는 noteRef.current 내부에 있습니다.");
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("커서를 먼저 위치시켜주세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    const div = document.createElement("div");

    // activeNode.parentNode.insertBefore(div, activeNode.nextSibling);
    insertParagraphNode(noteRef.current, activeNode, div);

    const p2 = document.createElement("p");
    p2.appendChild(document.createElement("br"));
    activeNode.parentNode.insertBefore(p2, div.nextSibling);

    div.innerHTML = content;

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    setAssistantMessage("");
    setSelectedRange(null);
    setSelectedText("");

    setTimeout(() => {
      saveWork();
    }, 2000);

    handleInputChange(false);
  };

  const findParentTable = (node) => {
    if (node.nodeName === "TABLE") {
      return node;
    } else {
      return findParentTable(node.parentNode);
    }
  };

  const findParentTr = (node) => {
    if (node.nodeName === "TR") {
      return node;
    } else {
      return findParentTr(node.parentNode);
    }
  };

  const isTdElement = (node) => {
    if (node.nodeName === "TD") {
      setActiveTd(node);
      return true;
    } else if (node.nodeName === "TABLE") {
      return false;
    } else if (node === noteRef.current) {
      return false;
    } else {
      return isTdElement(node.parentNode);
    }
  };

  const insertTable = () => {
    const table = document.createElement("table");
    table.style.width = "100%";

    const tbody = document.createElement("tbody");
    table.appendChild(tbody);

    for (let i = 0; i < 3; i++) {
      const tr = document.createElement("tr");
      tr.appendChild(document.createElement("td"));
      tr.appendChild(document.createElement("td"));
      tr.appendChild(document.createElement("td"));
      tbody.appendChild(tr);
    }

    const selection = document.getSelection();
    const activeNode = selection.anchorNode;

    if (activeNode && noteRef.current && noteRef.current.contains(activeNode)) {
      // console.log("현재 커서가 위치한 노드는 noteRef.current 내부에 있습니다.");
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("커서를 먼저 위치시켜주세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    activeNode.parentNode.insertBefore(table, activeNode.nextSibling);

    const p2 = document.createElement("p");
    p2.appendChild(document.createElement("br"));
    activeNode.parentNode.insertBefore(p2, table.nextSibling);

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    setAssistantMessage("");
    setSelectedRange(null);
    setSelectedText("");

    setTimeout(() => {
      saveWork();
    }, 2000);

    handleInputChange(false);
  };

  const insertTableRow = (location = "below") => {
    // location = "above" or "below"
    const td = activeTd;
    const table = findParentTable(td);
    console.log(table);
    const tdCountPerRow = table.querySelectorAll("tr")[0].childElementCount;
    const tr = document.createElement("tr");
    for (let i = 0; i < tdCountPerRow; i++) {
      const p = document.createElement("p");
      p.appendChild(document.createElement("br"));
      const newTd = document.createElement("td");
      newTd.appendChild(p);
      tr.appendChild(newTd);
    }

    const tbody = table.querySelector("tbody");
    try {
      if (location === "above") {
        tbody.insertBefore(tr, findParentTr(td));
      } else {
        tbody.insertBefore(tr, findParentTr(td).nextSibling);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const insertTableColumn = (location = "right") => {
    // location = "left" or "right"
    const td = activeTd;
    const table = findParentTable(td);

    const trs = table.querySelectorAll("tr");
    // find index of td
    let tdIndex = 0;
    trs.forEach((tr) => {
      const tds = tr.querySelectorAll("td");
      tds.forEach((td) => {
        if (td === activeTd) {
          tdIndex = Array.from(tds).indexOf(td);
        }
      });
    });

    for (let i = 0; i < trs.length; i++) {
      const p = document.createElement("p");
      p.appendChild(document.createElement("br"));
      const newTd = document.createElement("td");
      newTd.appendChild(p);

      if (location === "left") {
        trs[i].insertBefore(newTd, trs[i].children[tdIndex]);
      } else {
        if (tdIndex === trs[i].children.length - 1) {
          trs[i].appendChild(newTd);
        } else {
          trs[i].insertBefore(newTd, trs[i].children[tdIndex + 1]);
        }
      }
    }
  };

  const removeTableRow = () => {
    const td = activeTd;
    const table = findParentTable(td);
    const tr = td.parentNode;
    const tbody = table.querySelector("tbody");
    tbody.removeChild(tr);
  };

  const removeTableColumn = () => {
    const td = activeTd;
    const table = findParentTable(td);
    const trs = table.querySelectorAll("tr");
    let tdIndex = 0;
    trs.forEach((tr) => {
      const tds = tr.querySelectorAll("td");
      tds.forEach((td) => {
        if (td === activeTd) {
          tdIndex = Array.from(tds).indexOf(td);
        }
      });
    });
    for (let i = 0; i < trs.length; i++) {
      trs[i].removeChild(trs[i].children[tdIndex]);
    }
  };

  const deleteTable = () => {
    const td = activeTd;
    const table = findParentTable(td);
    const p = document.createElement("p");
    p.appendChild(document.createElement("br"));
    table.parentNode.insertBefore(p, table.nextSibling);
    table.parentNode.removeChild(table);
  };

  const insertImage = (url, activeNode) => {
    const img = document.createElement("img");
    img.src = `${serverUrl}${url}`;
    img.style.width = "100%";
    // img.style.maxWidth = "600px";
    img.style.height = "auto";
    img.style.margin = "0 auto";
    img.setAttribute("data-image-name", url.split("/").pop());

    const div = document.createElement("div");
    div.style.display = "flex";
    div.style.justifyContent = "center";
    div.style.alignItems = "center";
    div.appendChild(img);

    if (noteRef.current.innerText === "") {
      noteRef.current.appendChild(div);
      return;
    }

    // const selection = document.getSelection();
    // const activeNode = selection.anchorNode;

    if (activeNode && noteRef.current && noteRef.current.contains(activeNode)) {
      // console.log("현재 커서가 위치한 노드는 noteRef.current 내부에 있습니다.");
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("이미지를 삽입할 곳 커서를 먼저 위치시켜주세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    // activeNode.parentNode.insertBefore(div, activeNode.nextSibling);

    insertParagraphNode(noteRef.current, activeNode, div);

    const p2 = document.createElement("p");
    p2.appendChild(document.createElement("br"));
    activeNode.parentNode.insertBefore(p2, div.nextSibling);

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    setAssistantMessage("");
    setSelectedRange(null);
    setSelectedText("");

    setTimeout(() => {
      saveWork();
    }, 2000);

    handleInputChange(false);
  };

  const uploadImage = async (file, activeNode) => {
    const res = await $upload("/api/upload/image", file, {
      workId: props.workId,
    });
    if (res.status === 200) {
      // insertImage(
      //   `${serverUrl}/static/images/${res.data.filename}`,
      //   activeNode
      // );

      insertImage(`/static/images/${res.data.filename}`, activeNode);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("이미지 업로드 실패"),
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const getLinkContent = (link) => {
    const timestamp = new Date().getTime();
    setProcessingWorkResearchList([
      ...processingWorkResearchList,
      { type: "link", timestamp: timestamp },
    ]);
    $post("/api/prompt/link-analyze-background", {
      link: link,
      workId: props.workId,
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          getWorkResearchList();
          toast.info(t("{{link}} 분석 완료", { link: link }));
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("링크 분석 실패"),
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
      setProcessingWorkResearchList([
        ...processingWorkResearchList.filter(
          (item) => item.timestamp !== timestamp
        ),
      ]);
    });
  };

  const getYoutubeContent = (url) => {
    const timestamp = new Date().getTime();
    setProcessingWorkResearchList([
      ...processingWorkResearchList,
      { type: "youtube", timestamp: timestamp },
    ]);
    $post("/api/prompt/youtube-background", {
      url: url,
      workId: props.workId,
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          getWorkResearchList();
          toast.info(t("유튜브 요약 완료"));
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("유튜브 요약 실패"),
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
      setProcessingWorkResearchList([
        ...processingWorkResearchList.filter(
          (item) => item.timestamp !== timestamp
        ),
      ]);
    });
  };

  const getMarketContent = (ask) => {
    const timestamp = new Date().getTime();
    setProcessingWorkResearchList([
      ...processingWorkResearchList,
      { type: "market", timestamp: timestamp },
    ]);
    // research-background
    // gemini-background
    $post("/api/prompt/gemini-background", {
      ask: ask,
      workId: props.workId,
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          getWorkResearchList();
          toast.info(t("{{ask}} 완료", { ask: ask }));
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("자료 조사 실패"),
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
      setProcessingWorkResearchList([
        ...processingWorkResearchList.filter(
          (item) => item.timestamp !== timestamp
        ),
      ]);
    });
  };

  const getFileContent = (file, requestText) => {
    const timestamp = new Date().getTime();
    setProcessingWorkResearchList([
      ...processingWorkResearchList,
      { type: "file", timestamp: timestamp },
    ]);

    $upload("/api/prompt/upload-background2", file, {
      workId: props.workId,
      fileName: file.name,
      requestText: requestText,
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          getWorkResearchList();
          toast.info(t("{{file}} 분석 완료", { file: file.name }));
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("파일 분석 실패"),
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
      setProcessingWorkResearchList([
        ...processingWorkResearchList.filter(
          (item) => item.timestamp !== timestamp
        ),
      ]);
    });
  };

  const getImageContent = (file) => {
    const timestamp = new Date().getTime();
    setProcessingWorkResearchList([
      ...processingWorkResearchList,
      { type: "file", timestamp: timestamp },
    ]);

    $upload("/api/prompt/image-analyze", file, {
      workId: props.workId,
      fileName: file.name,
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          getWorkResearchList();
          toast.info(t("{{file}} 이미지 분석 완료", { file: file.name }));
        } else {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("이미지 분석 실패"),
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
      setProcessingWorkResearchList([
        ...processingWorkResearchList.filter(
          (item) => item.timestamp !== timestamp
        ),
      ]);
    });
  };

  const toggleFullScreen = () => {
    if (fullScreen && document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  };

  const closeAllModal = () => {
    setShowAIPopover(false);
    setShowImageModal(false);
    setShowLinkAnalysisModal(false);
    setShowParagraphModal(false);
    setShowTitleModal(false);
    setShowTableContentModal(false);
    setShowInfoModal(false);
    setShowChatbox(false);
    setShowTextColorPicker(false);
    setShowSpellCheckModal(false);
    setShowForbidCheckModal(false);
    setShowMarketAssistantModal(false);
    setShowGlossaryModal(false);
    setProcessingWorkResearchList([]);
  };

  const checkSpell = async () => {
    const pTags = noteRef.current.querySelectorAll("p");
    const paragraphs = [];
    // noteRef 안의 모든 텍스트 노드의 텍스트를 추출
    const textNodes = Array.from(noteRef.current.childNodes).filter(
      (node) =>
        node.nodeType === Node.TEXT_NODE ||
        (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== "TABLE")
    );

    // textNodes.forEach((node) => {
    //   if (node.textContent.trim() !== "") {
    //     paragraphs.push(node.textContent.trim());
    //   }
    // });

    for (let i = 0; i < pTags.length; i++) {
      const text = pTags[i].innerText.trim();
      if (text === "") continue;
      if (text.length > 300) {
        const sentences = text.split(".");
        sentences.forEach((sentence) => {
          if (sentence.trim() !== "") {
            sentence = sentence.replace(/\\n/g, "");
            paragraphs.push(sentence.trim());
          }
        });
      } else {
        paragraphs.push(text);
      }
    }

    closeAllModal();

    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }
    setSpellCheckText(paragraphs);
    setTimeout(() => {
      setShowSpellCheckModal(true);
    }, 500);
  };

  const checkForbiddenWord = () => {
    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }
    closeAllModal();

    setTimeout(() => {
      setShowForbidCheckModal(true);
    }, 500);
  };

  const openMarketAssistantModal = () => {
    // 자료 조사가 실행중인지 확인
    if (processingWorkResearchList.length > 0) {
      // type이 market인 작업이 있는지 확인
      if (
        processingWorkResearchList.filter((item) => item.type === "market")
          .length > 0
      ) {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: t("진행중인 자료 조사가 완료된 후 시도해주세요."),
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }

    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }

    closeAllModal();

    setTimeout(() => {
      setShowMarketAssistantModal(true);
    }, 500);
  };

  const openLinkAnalysisModal = () => {
    // 링크 분석이 실행중인지 확인
    if (processingWorkResearchList.length > 0) {
      // type이 link인 작업이 있는지 확인
      if (
        processingWorkResearchList.filter((item) => item.type === "link")
          .length > 0
      ) {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: t("진행중인 링크 분석이 완료된 후 시도해주세요."),
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }

    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }

    closeAllModal();

    setTimeout(() => {
      setShowLinkAnalysisModal(true);
    }, 500);
  };

  const openYoutubeAnalysisModal = () => {
    // 유튜브 분석이 실행중인지 확인
    if (processingWorkResearchList.length > 0) {
      // type이 youtube인 작업이 있는지 확인
      if (
        processingWorkResearchList.filter((item) => item.type === "youtube")
          .length > 0
      ) {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: t("진행중인 유튜브 분석이 완료된 후 시도해주세요."),
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }

    if (props.leftSidebarOpen) {
      openLeftSidebar();
    }

    closeAllModal();

    setTimeout(() => {
      setShowYoutubeDataModal(true);
    }, 500);
  };

  const getWorkResearchList = async () => {
    const res = await $get(`/api/work/research/${props.workId}`);
    if (res.status === 200) {
      setWorkResearchList(res.data);
    }
  };

  const deleteWorkResearch = async (id) => {
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: t(`삭제`),
      cancelButtonText: t(`취소`),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/work/research/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("삭제 완료"),
            showConfirmButton: false,
            timer: 1000,
          });
          getWorkResearchList();
        }
      }
    });
  };

  const changeStickNotesRightPosition = () => {
    if (contentRef === null) return;
    // const right =
    //   window.innerWidth - noteRef.current.getBoundingClientRect().right - 230;
    // console.log(
    //   "noteRef.current.getBoundingClientRect().right",
    //   noteRef.current.getBoundingClientRect().right
    // );

    const right =
      contentRef.current.offsetWidth / 2 +
      noteRef.current.offsetWidth / 2 -
      10 +
      (props.leftSidebarOpen ? 240 : 30);
    // console.log("right: ", right);
    setStickNotesRightPosition(right);
  };

  // const generateShareLink = async () => {
  //   const res = await $get(`/api/work/share/${props.workId}`);
  //   if (res.status === 200) {
  //     navigator.clipboard.writeText(`${clientUrl}/link/${res.data}`);
  //     Swal.fire({
  //       iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
  //       title: "링크가 복사되었습니다.",
  //       showConfirmButton: false,
  //       timer: 1000,
  //     });
  //   }
  // };

  const translateDocument = async (lang) => {
    Swal.fire({
      title: t("번역중입니다..."),
      html: t("잠시만 기다려주세요."),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();

        const res = await $post(`/api/prompt/translate`, {
          workId: props.workId,
          lang: lang,
        });
        Swal.close();

        if (res.status === 200) {
          getTranslationDocumentList();
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("번역 완료"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      },
    });
  };

  const checkUseAI = async () => {
    // Swal progress
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("AI 서비스 사용 가능 여부 확인 중"),
      html: t("잠시만 기다려주세요."),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();
        const checkServiceAvailable = await $get(`/api/prompt/check`);

        setUseAI(checkServiceAvailable.data.status);
        if (!checkServiceAvailable.data.status) {
          Swal.fire({
            iconHtml:
              '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            title: t("현재 AI 기능을 사용할 수 없습니다."),
            showConfirmButton: true,
            confirmButtonText: t("확인"),
          });
        } else {
          Swal.close();
        }
      },
    });
  };

  const getTranslationDocumentList = async () => {
    const res = await $get(`/api/work/translate/${props.workId}`);
    if (res.status === 200) {
      setTranslationDocumentList(res.data);
    }
  };

  const insertGlossary = async () => {
    setBeatLoading2(true);

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    if (
      glossaryList.filter((item) => item.glossary === selectedText).length > 0
    ) {
      setBeatLoading2(false);

      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("이미 용어집에 존재하는 용어입니다."),
        showConfirmButton: false,
        timer: 1000,
      });

      return;
    }

    const res = await $post(`/api/prompt/glossary`, {
      workId: props.workId,
      glossary: selectedText,
    });

    setSelectedRange(null);
    setSelectedText("");

    if (res.status === 200 && res.data.success) {
      toast.info(t(`"${selectedText}" 용어가 용어집에 추가되었습니다.`));
      getGlossaryList();
    } else {
      toast.info(t(`"${selectedText}" 용어 추가에 실패했습니다.`));
    }

    setBeatLoading2(false);
  };

  const insertGlossaryAll = () => {
    const h = [];
    if (glossaryList.length === 0) return;
    // dl, dt, dd 태그로 구성된 용어집을 생성
    h.push("<dl>");
    glossaryList.forEach((item) => {
      // item.glossary는 용어, item.description은 설명
      h.push(`<dt>${item.glossary}</dt>`);
      h.push(`<dd>${item.description}</dd>`);
    });
    h.push("</dl>");

    h.push("<p><br></p>");

    noteRef.current.innerHTML += h.join("");
  };

  const getGlossaryList = async () => {
    const res = await $get(`/api/work/glossary/${props.workId}`);
    if (res.status === 200) {
      setGlossaryList(res.data);
    }
  };

  const checkExistTableContent = async () => {
    const res = await $get(`/api/work/suggest-table-content/${props.workId}`);

    if (res.status === 200) {
      if (res.data.length === 0) {
        Swal.fire({
          iconHtml:
            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
          title: t("입력된 기본 정보를 바탕으로 목차를 제안 받으시겠습니까?"),
          showCancelButton: true,
          confirmButtonText: t("예"),
          cancelButtonText: t("아니오"),
        }).then((result) => {
          if (result.isConfirmed) {
            setShowTableContentModal(true);
          }
        });
      }
    }
  };

  // useEffect(() => {
  //   if (showParagraphModal) {
  //     saveWork();
  //   }
  // }, [showParagraphModal]);

  // 맥인 경우 cmd+b, 윈도우인 경우 ctrl+b 입력시 bold 적용
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "b" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        btnBoldRef.current.click();
      } else if (e.key === "i" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        btnItalicRef.current.click();
      } else if (e.key === "u" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        btnUnderlineRef.current.click();
      } else if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        saveWork();
      } else if (e.key === "[" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        btnBiggerRef.current.click();
      } else if (e.key === "]" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        btnSmallerRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    changeStickNotesRightPosition();
  }, [props.leftSidebarOpen]);

  const isLocalStorageSupported = () => {
    try {
      const testKey = "test";
      localStorage.setItem(testKey, "testValue");
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    checkLogin();
    window.addEventListener("resize", changeStickNotesRightPosition);
    changeStickNotesRightPosition();
    setSelectedRange(null);
    setSelectedText("");
    setAssistantMessage("");
    setWorkContentLength(0);
    setInfo({});
    setEditAvailable(false);
    closeAllModal();
    checkUseAI();
    getWork();
    getWorkResearchList();
    getTranslationDocumentList();
    getGlossaryList();
    if (isLocalStorageSupported()) {
      setLocalStorageAvailable(true);
      localStorage.setItem("workContentHistory", JSON.stringify([]));
      localStorage.setItem("workContentHistoryIndex", -1);
      setWorkContentHistory([]);
      setWorkContentHistoryIndex(-1);
    }

    // 마우스 커서의 위치 Y 좌표 + .content-body의 스크롤 Y - scrollRef의 top setCursorPositionTop에 저장
    // const handleMouseMove = (e) => {
    //   setCursorPositionTop(
    //     e.clientY +
    //       scrollRef.current.scrollTop -
    //       scrollRef.current.offsetTop -
    //       28
    //   );
    // };

    // window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("resize", changeStickNotesRightPosition);
      // window.removeEventListener("mousemove", handleMouseMove);
    };

    // const saveInterval = setInterval(() => {
    //   if (!loading && workContent !== noteRef.current.innerHTML) saveWork();
    // }, 300000);

    // return () => {
    //   clearInterval(saveInterval);
    // };
  }, [props.workId]);

  useEffect(() => {
    if (isLocalStorageSupported()) {
      setLocalStorageAvailable(true);
      localStorage.setItem("workContentHistory", JSON.stringify([]));
      localStorage.setItem("workContentHistoryIndex", -1);
      setWorkContentHistory([]);
      setWorkContentHistoryIndex(-1);
    }

    return () => {
      if (isLocalStorageSupported()) {
        localStorage.setItem("workContentHistory", JSON.stringify([]));
        localStorage.setItem("workContentHistoryIndex", -1);
        setWorkContentHistory([]);
        setWorkContentHistoryIndex(-1);
      }

      // 저장 여부 확인 후 저장이 안되어 있다면 "작업 내용이 저장되지 않았습니다. 나가시겠습니까?" 라는 메시지 출력, Swal.fire
      // Swal.fire({
      //   iconHtml:
      //     '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      //   title: "작업 내용이 저장되지 않았습니다. 나가시겠습니까?",
      //   showCancelButton: true,
      //   confirmButtonText: "나가기",
      //   cancelButtonText: "취소",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     if (isLocalStorageSupported()) {
      //       localStorage.setItem("workContentHistory", JSON.stringify([]));
      //       localStorage.setItem("workContentHistoryIndex", -1);
      //       setWorkContentHistory([]);
      //       setWorkContentHistoryIndex(-1);
      //     }
      //   }
      // });
    };
  }, []);

  return (
    <div className="content" ref={contentRef}>
      {userData && userData.active_yn === "Y" && (
        <div className="toolbar top">
          <div className="left">
            <div className="item-group main">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => setShowInfoModal(true)}
                >
                  <i className="fa-solid fa-file-circle-exclamation"></i>
                  <div className="tooltiptext">{t("기본 정보")}</div>
                </button>
              </div>

              {info && info.need_table_content === "Y" && (
                <div className="item d-none sm-block">
                  <button
                    type="button"
                    className="btn btn-xsm btn-transparent"
                    onClick={() => {
                      if (!info.subject) {
                        Swal.fire({
                          iconHtml:
                            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                          title: t("기본 정보 등록 후 사용할 수 있습니다."),
                          timer: 1000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        });
                        return;
                      }
                      setShowTableContentModal(true);
                    }}
                    disabled={!editAvailable || !useAI}
                  >
                    <i className="fa-solid fa-list-ol"></i>
                    <div className="tooltiptext">{t("목차 생성")}</div>
                  </button>
                </div>
              )}
              <div className="item d-none md-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (!info.subject) {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("기본 정보 등록 후 사용할 수 있습니다."),
                        timer: 1000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }
                    setShowTitleModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-t"></i>
                  <div className="tooltiptext">{t("제목 생성")}</div>
                </button>
              </div>
              <div className="item d-none xxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => {
                    const selection = document.getSelection();
                    const activeNode = selection.anchorNode;

                    if (
                      activeNode &&
                      noteRef.current &&
                      noteRef.current.contains(activeNode)
                    ) {
                      setCurrentCursorNode(activeNode);
                      setShowImageModal(true);
                    } else {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t(
                          "이미지를 삽입할 곳에 커서를 먼저 위치시켜주세요."
                        ),
                        showConfirmButton: false,
                        timer: 1000,
                      });
                      return;
                    }
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-regular fa-image"></i>
                  <div className="tooltiptext">{t("이미지생성")}</div>
                </button>
              </div>
              {/* <div className="item d-none xxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => {
                    const selection = document.getSelection();
                    const activeNode = selection.anchorNode;
                    if (
                      activeNode &&
                      noteRef.current &&
                      noteRef.current.contains(activeNode)
                    ) {
                      setCurrentCursorNode(activeNode);
                      attachRef.current.click();
                    } else {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t(
                          "이미지를 삽입할 곳에 커서를 먼저 위치시켜주세요."
                        ),
                        showConfirmButton: false,
                        timer: 1000,
                      });
                      return;
                    }
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-regular fa-file-image"></i>
                  <div className="tooltiptext">{t("이미지추가")}</div>
                </button>
              </div> */}
              <div className="item d-none lg-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={(e) => openMarketAssistantModal()}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-magnifying-glass-chart"></i>
                  <div className="tooltiptext">{t("자료 조사")}</div>
                </button>
              </div>
              {/* <div className="item d-none xl-block">
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={() => openLinkAnalysisModal()}
                disabled={!editAvailable}
              >
                <i className="fa-solid fa-link"></i>
                <div className="tooltiptext">{t("링크 분석")}</div>
              </button>
            </div>
            <div className="item d-none xxl-block">
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={() => setShowFileAnalysisModal(true)}
                disabled={!editAvailable}
              >
                <i className="fa-solid fa-file-import"></i>
                <div className="tooltiptext">{t("파일 분석")}</div>
              </button>
            </div> */}
              {/* <div className="item d-none xxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => setShowMemoModal(true)}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-note-sticky"></i>
                  <div className="tooltiptext">{t("메모 추가")}</div>
                </button>
              </div> */}
              <div
                className={
                  props.leftSidebarOpen ? "item d-none" : "item d-none lg-block"
                }
              >
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (selectedText === "") {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("문장을 선택하세요."),
                        timer: 1000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }
                    setParagraphAsssitantType("");
                    setShowAIPopover(false);
                    setShowParagraphModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-retweet"></i>
                  <div className="tooltiptext">{t("문장 교체")}</div>
                </button>
              </div>
              <div className="item d-none sm-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (selectedText === "") {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("문장을 선택하세요."),
                        timer: 1500,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }

                    // console.log(
                    //   "selectedText: ",
                    //   selectedText.replace(/\n/g, "")
                    // );

                    // const selection = document.getSelection();
                    // const range = selection.getRangeAt(0);
                    // if (range.startContainer !== range.endContainer) {
                    //   Swal.fire({
                    //     iconHtml:
                    //       '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                    //     title: t("하나의 문단만 선택하세요."),
                    //     text: t(
                    //       "상세히쓰기는 하나의 문단에 대해서만 가능합니다."
                    //     ),
                    //     timer: 1500,
                    //     showCancelButton: false,
                    //     showConfirmButton: false,
                    //   });
                    //   return;
                    // }

                    setParagraphAsssitantType("detail-");
                    setShowAIPopover(false);
                    setShowParagraphModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-regular fa-keyboard"></i>
                  <div className="tooltiptext">{t("상세히쓰기")}</div>
                </button>
              </div>
              <div className="item d-none xl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (selectedText === "") {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("문장을 선택하세요."),
                        timer: 1000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }
                    setParagraphAsssitantType("short-");
                    setShowAIPopover(false);
                    setShowParagraphModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-filter"></i>
                  <div className="tooltiptext">{t("간략히쓰기")}</div>
                </button>
              </div>
              <div className="item d-none xl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (selectedText === "") {
                      Swal.fire({
                        // iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("문장을 선택하세요."),
                        timer: 1000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }
                    setParagraphAsssitantType("list-");
                    setShowAIPopover(false);
                    setShowParagraphModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-list"></i>
                  <div className="tooltiptext">{t("목록화하기")}</div>
                </button>
              </div>
              <div className="item d-none xxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => {
                    if (selectedText === "") {
                      Swal.fire({
                        // iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t("문장을 선택하세요."),
                        timer: 1000,
                        showCancelButton: false,
                        showConfirmButton: false,
                      });
                      return;
                    }
                    setParagraphAsssitantType("table-");
                    setShowAIPopover(false);
                    setShowParagraphModal(true);
                  }}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-table"></i>
                  <div className="tooltiptext">{t("표로시각화")}</div>
                </button>
              </div>
              {info && info.need_write_next === "Y" && (
                <div className="item d-none lg-block">
                  <button
                    type="button"
                    className="btn btn-xsm btn-transparent"
                    onClick={(e) => {
                      if (!info.subject) {
                        Swal.fire({
                          iconHtml:
                            '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                          title: t("기본 정보 등록 후 사용할 수 있습니다."),
                          timer: 1000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        });
                        return;
                      }
                      // writeNextParagraph();
                      writeNextParagraphBetween();
                    }}
                    disabled={!editAvailable || !useAI}
                  >
                    <i className="fa-solid fa-feather-pointed"></i>
                    <div className="tooltiptext">{t("이어쓰기")}</div>
                  </button>
                </div>
              )}
              <div className="item d-none xl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => setShowChatbox(!showChatbox)}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-regular fa-comment-dots"></i>
                  <div className="tooltiptext">{t("AI챗봇")}</div>
                </button>
              </div>
              {/* <div className="item d-none xxxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => openYoutubeAnalysisModal()}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-brands fa-youtube"></i>
                  <div className="tooltiptext">{t("유튜브 요약")}</div>
                </button>
              </div>   */}
              <div className="item d-none xxxl-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => setShowGoogleSearchModal(true)}
                  disabled={!editAvailable || !useAI}
                >
                  <i className="fa-solid fa-search"></i>
                  <div className="tooltiptext">{t("자료 검색")}</div>
                </button>
              </div>

              {/* {userData && userData.lang === "KO" && (
              <div
                className={
                  props.leftSidebarOpen ? "item d-none" : "item d-none xl-block"
                }
              >
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => checkSpell()}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-spell-check"></i>
                  <div className="tooltiptext">{t("맞춤법검사")}</div>
                </button>
              </div>
            )}
            {userData && userData.lang === "KO" && (
              <div
                className={
                  props.leftSidebarOpen
                    ? "item d-none"
                    : "item d-none xxxl-block"
                }
              >
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => checkForbiddenWord()}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-ban"></i>
                  <div className="tooltiptext">{t("금칙어검사")}</div>
                </button>
              </div>
            )} */}
              {/* <div
              className={
                props.leftSidebarOpen ? "item d-none" : "item d-none xxl-block"
              }
            >
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={() => setShowSEOModal(true)}
                disabled={!editAvailable}
              >
                <i className="fa-solid fa-arrows-to-circle"></i>
                <div className="tooltiptext">{t("SEO최적화")}</div>
              </button>
            </div> */}
            </div>
          </div>

          <div className="right">
            <div className="item-group main">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => saveWork()}
                  disabled={!editAvailable}
                >
                  <i className="fa-regular fa-save"></i>
                  <div className="tooltiptext">{t("저장하기")}</div>
                </button>
              </div>
              {/* <div className="item d-none xxxl-block">
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={() => copyWorkContent()}
                disabled={!editAvailable}
              >
                <i className="fa-regular fa-copy"></i>
                <div className="tooltiptext">{t("복사하기")}</div>
              </button>
            </div> */}
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent"
                  onClick={() => setShowShareLinkModal(true)}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-link"></i>
                  <div className="tooltiptext">{t("공유링크")}</div>
                </button>
              </div>
              {/* <div className="item">
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={(e) => downloadPdf()}
                disabled={!editAvailable}
              >
                <i className="fa-regular fa-file-pdf"></i>
                <div className="tooltiptext">{t("PDF저장")}</div>
              </button>
            </div> */}
              {/* <div className="item d-none xxxl-block">
              <button
                type="button"
                className="btn btn-xsm btn-transparent"
                onClick={(e) => downloadDocx()}
                disabled={!editAvailable}
              >
                <i className="fa-regular fa-file-word"></i>
                <div className="tooltiptext">{t("DOC저장")}</div>
              </button>
            </div> */}
              <div className="item user-menu dropdown d-none md-block">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent dropdown-toggle"
                  onClick={(e) => setShowMoreButton(!showMoreButton)}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                  <div className="tooltiptext">{t("더보기")}</div>
                </button>
                <ul
                  className={
                    showMoreButton ? "dropdown-menu show" : "dropdown-menu"
                  }
                >
                  {/* <li className="dropdown-item">
                  <a
                    className="dropdown-link"
                    onClick={() => {
                      if (!info.subject) {
                        Swal.fire({
                          iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                          title: t("기본 정보 등록 후 사용할 수 있습니다."),
                          timer: 1000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        });
                        return;
                      }
                      setShowTitleModal(true);
                      setShowMoreButton(false);
                    }}
                    disabled={!editAvailable}
                  >
                    <i className="fa-solid fa-t"></i>
                    <div className="tooltiptext">{t("제목 생성")}</div>
                  </a>
                </li> */}
                  {useAI && (
                    <li className="dropdown-item xxl-none">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowMoreButton(false);
                          setShowGoogleSearchModal(true);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-search"></i>
                        <div className="tooltiptext">{t("자료 검색")}</div>
                      </a>
                    </li>
                  )}
                  {useAI && (
                    <li className="dropdown-item xxl-none">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowMoreButton(false);
                          openYoutubeAnalysisModal();
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-brands fa-youtube"></i>
                        <div className="tooltiptext">{t("유튜브 요약")}</div>
                      </a>
                    </li>
                  )}
                  {useAI && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowMoreButton(false);
                          setShowImageAnalysisModal(true);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-chart-column"></i>
                        <div className="tooltiptext">
                          {t("통계 이미지 분석")}
                        </div>
                      </a>
                    </li>
                  )}
                  {useAI && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowMoreButton(false);
                          openLinkAnalysisModal();
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-link"></i>
                        <div className="tooltiptext">{t("링크 분석")}</div>
                      </a>
                    </li>
                  )}
                  {/* {useAI && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowMoreButton(false);
                          // 파일 분석 중인지 확인
                          if (processingWorkResearchList.length > 0) {
                            // type이 youtube인 작업이 있는지 확인
                            if (
                              processingWorkResearchList.filter(
                                (item) => item.type === "file"
                              ).length > 0
                            ) {
                              Swal.fire({
                                iconHtml:
                                  '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                                title: t(
                                  "진행중인 파일 분석이 완료된 후 시도해주세요."
                                ),
                                showConfirmButton: false,
                                timer: 1500,
                              });
                              return;
                            }
                          }

                          setShowFileAnalysisModal(true);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-file-import"></i>
                        <div className="tooltiptext">{t("파일 분석")}</div>
                      </a>
                    </li>
                  )} */}

                  {userData && userData.lang === "KO" && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          checkSpell();
                          setShowMoreButton(false);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-spell-check"></i>
                        <div className="tooltiptext">{t("맞춤법검사")}</div>
                      </a>
                    </li>
                  )}
                  {userData && userData.lang === "KO" && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          checkForbiddenWord();
                          setShowMoreButton(false);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-ban"></i>
                        <div className="tooltiptext">{t("금칙어검사")}</div>
                      </a>
                    </li>
                  )}
                  {useAI && (
                    <li className="dropdown-item">
                      <a
                        className="dropdown-link"
                        onClick={() => {
                          setShowSEOModal(true);
                          setShowMoreButton(false);
                        }}
                        disabled={!editAvailable}
                      >
                        <i className="fa-solid fa-arrows-to-circle"></i>
                        <div className="tooltiptext">{t("SEO최적화")}</div>
                      </a>
                    </li>
                  )}
                  {/* <li className="dropdown-item">
                  <a
                    className="dropdown-link"
                    onClick={(e) => {
                      // translateDocument();
                      setShowMoreButton(false);
                      if (workTitle === "") {
                        Swal.fire({
                          iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                          title: t("제목을 입력하세요."),
                          timer: 1000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        });
                        return;
                      }

                      if (workContent === "") {
                        Swal.fire({
                          iconHtml: '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                          title: t("내용을 입력하세요."),
                          timer: 1000,
                          showCancelButton: false,
                          showConfirmButton: false,
                        });
                        return;
                      }

                      //Swal prompt select
                      Swal.fire({
                        title: t("번역할 언어를 선택하세요."),
                        text: t(
                          "지금까지 작성한 내용을 선택한 언어로 번역합니다."
                        ),
                        input: "select",
                        inputOptions: {
                          KO: "한국어(KO)",
                          EN: "English(EN)",
                          JP: "日本語(JP)",
                          FR: "Français(FR)",
                          ES: "Español(ES)",
                          DE: "Deutsch(DE)",
                        },
                        inputPlaceholder: t("언어 선택"),
                        showCancelButton: true,
                        cancelButtonText: t("취소"),
                        confirmButtonText: t("확인"),
                        inputValidator: (value) => {
                          return new Promise((resolve) => {
                            if (value !== "") {
                              resolve();
                            } else {
                              resolve(t("언어를 선택하세요."));
                            }
                          });
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          translateDocument(result.value);
                        }
                      });
                    }}
                    disabled={!editAvailable}
                  >
                    <i className="fa-solid fa-language"></i>
                    <div className="tooltiptext">{t("번역본 만들기")}</div>
                  </a>
                </li> */}
                  <li className="dropdown-item">
                    <a
                      className="dropdown-link"
                      onClick={(e) => {
                        downloadPdf();
                        setShowMoreButton(false);
                      }}
                      disabled={!editAvailable}
                    >
                      <i className="fa-regular fa-file-pdf"></i>
                      <div className="tooltiptext">{t("PDF저장")}</div>
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <a
                      className="dropdown-link"
                      onClick={(e) => {
                        downloadDocx();
                        setShowMoreButton(false);
                      }}
                      disabled={!editAvailable}
                    >
                      <i className="fa-regular fa-file-word"></i>
                      <div className="tooltiptext">{t("DOCX저장")}</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {userData && userData.active_yn === "Y" && (
        <div className="toolbar d-none lg-flex">
          <div className="left">
            <div className="item">
              {/* <button
              type="button"
              className="btn btn-xsm btn-transparent"
              onClick={() => {
                openLeftSidebar();
              }}
            >
              <i
                className={
                  leftSidebarOpen
                    ? "fa-solid fa-angles-left"
                    : "fa-solid fa-angles-right"
                }
              ></i>
            </button> */}
              <button
                type="button"
                className="btn btn-xsm btn-transparent tooltip"
                onClick={() => toggleFullScreen()}
              >
                <i
                  className="fa-solid fa-up-right-and-down-left-from-center"
                  style={{ display: fullScreen ? "none" : "" }}
                ></i>
                <i
                  className="fa-solid fa-down-left-and-up-right-to-center"
                  style={{ display: fullScreen ? "" : "none" }}
                ></i>
                <div className="tooltiptext">
                  {fullScreen ? t("전체화면 취소") : t("전체화면")}
                </div>
              </button>
            </div>
            {/* <div className="item-group">
            <div className="item">
              <select
                className="sel-font-family"
                value={mainFont}
                onChange={(e) => setMainFont(e.target.value)}
              >
                {koreanFontFamily.map((font, idx) => (
                  <option key={`font-${idx}`} value={font.className}>
                    {font.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="item">
              <select
                className="sel-font-family"
                value={fontSize}
                onChange={(e) => setFontSize(e.target.value)}
              >
                {fontSizeList.map((font, idx) => (
                  <option key={`size-${idx}`} value={font}>
                    {font}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
            {/* <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("bold")}
                  ref={btnBoldRef}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-bold"></i>
                  <div className="tooltiptext">
                    {t("굵게")}({metaKey}+b)
                  </div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("italic")}
                  ref={btnItalicRef}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-italic"></i>
                  <div className="tooltiptext">
                    {t("기울임")}({metaKey}+i)
                  </div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("underline")}
                  ref={btnUnderlineRef}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-underline"></i>
                  <div className="tooltiptext">
                    {t("밑줄")}({metaKey}+u)
                  </div>
                </button>
              </div>
              
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("bigger")}
                  ref={btnBiggerRef}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-heading"></i>
                  <div className="tooltiptext">
                    {t("글자 크게")}({metaKey}+[)
                  </div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("smaller")}
                  ref={btnSmallerRef}
                  disabled={!editAvailable}
                >
                  <i
                    className="fa-solid fa-heading"
                    style={{ fontSize: "0.6rem" }}
                  ></i>
                  <div className="tooltiptext">
                    {t("글자 작게")}({metaKey}+])
                  </div>
                </button>
              </div>
            </div> */}
            {/* <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("alignLeft")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-align-left"></i>
                  <div className="tooltiptext">{t("왼쪽 정렬")}</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("alignCenter")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-align-center"></i>
                  <div className="tooltiptext">{t("가운데 정렬")}</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("alignRight")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-align-right"></i>
                  <div className="tooltiptext">{t("오른쪽 정렬")}</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("alignJustify")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-align-justify"></i>
                  <div className="tooltiptext">{t("양쪽 정렬")}</div>
                </button>
              </div>
            </div> */}
            {/* <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("indent")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-indent"></i>
                  <div className="tooltiptext">{t("들여쓰기")}</div>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => applyFormat("outdent")}
                  disabled={!editAvailable}
                >
                  <i className="fa-solid fa-outdent"></i>
                  <div className="tooltiptext">{t("내어쓰기")}</div>
                </button>
              </div>
            </div> */}
            <div className="item-group">
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  onClick={(e) => {
                    const selection = document.getSelection();
                    const activeNode = selection.anchorNode;
                    if (
                      activeNode &&
                      noteRef.current &&
                      noteRef.current.contains(activeNode)
                    ) {
                      setCurrentCursorNode(activeNode);
                      attachRef.current.click();
                    } else {
                      Swal.fire({
                        iconHtml:
                          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                        title: t(
                          "이미지를 삽입할 곳에 커서를 먼저 위치시켜주세요."
                        ),
                        showConfirmButton: false,
                        timer: 1000,
                      });
                      return;
                    }
                  }}
                  disabled={!editAvailable}
                >
                  <i className="fa-regular fa-file-image"></i>
                  <div className="tooltiptext">{t("이미지 추가")}</div>
                </button>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={attachRef}
                  onChange={(e) =>
                    uploadImage(e.target.files[0], currentCursorNode)
                  }
                />
              </div>
              <div className="item">
                <button
                  type="button"
                  className="btn btn-xsm btn-transparent tooltip"
                  disabled={!editAvailable}
                  onClick={() => insertTable()}
                >
                  <i className="fa-solid fa-table"></i>
                  <div className="tooltiptext">{t("표 삽입")}</div>
                </button>
              </div>
            </div>
            {localStorageAvailable && (
              <div className="item-group">
                <div className="item">
                  <button
                    type="button"
                    className="btn btn-xsm btn-transparent tooltip"
                    disabled={!editAvailable || workContentHistoryIndex < 0}
                    onClick={() => changeWorkContentHistory(-1)}
                  >
                    <i className="fa-solid fa-rotate-left"></i>
                    {workContentHistoryIndex > 0 && (
                      <div className="tooltiptext">{t("실행 취소")}</div>
                    )}
                  </button>
                </div>
                <div className="item">
                  <button
                    type="button"
                    className="btn btn-xsm btn-transparent tooltip"
                    disabled={
                      !editAvailable ||
                      workContentHistory.length === 0 ||
                      workContentHistoryIndex === workContentHistory.length - 1
                    }
                    onClick={() => changeWorkContentHistory(1)}
                  >
                    <i className="fa-solid fa-rotate-right"></i>
                    {workContentHistory.length > 0 &&
                      workContentHistoryIndex <
                        workContentHistory.length - 1 && (
                        <div className="tooltiptext">{t("다시 실행")}</div>
                      )}
                  </button>
                </div>
              </div>
            )}

            {(translationDocumentList.length > 0 ||
              glossaryList.length > 0) && (
              <div className="item-group">
                {glossaryList.length > 0 && (
                  <div className="item">
                    <button
                      type="button"
                      className="btn btn-xsm btn-transparent"
                      onClick={() => {
                        if (props.leftSidebarOpen) {
                          openLeftSidebar();
                        }
                        setShowGlossaryModal(true);
                      }}
                    >
                      {t("용어집")}
                    </button>
                  </div>
                )}
                {translationDocumentList.length > 0 && (
                  <div className="item">
                    <button
                      type="button"
                      className="btn btn-xsm btn-transparent"
                      onClick={() => {
                        window.open(`/translation/${props.workId}`);
                      }}
                    >
                      {t("번역본")}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="right">
            {workContentLength > 0 && !beatLoading && !beatLoading2 && (
              <div className="item-group">
                <div className="item" style={{ fontSize: "0.8rem" }}>
                  {t("{{workContentLength}} 글자", { workContentLength })}
                </div>
              </div>
            )}
            {beatLoading && <BeatLoader color="#f2295b" />}

            {beatLoading && (
              <button
                type="button"
                className="btn btn-transparent btn-xsm"
                onClick={() => stopGenerating()}
              >
                <i className="fa-regular fa-circle-stop"></i> {t("중지")}
              </button>
            )}

            {beatLoading2 && <BeatLoader color="#f2295b" />}
          </div>
        </div>
      )}
      <div
        className="content-body overflow-y"
        ref={scrollRef}
        style={{ height: `calc(100vh - 130px)` }}
        onClick={(e) => hidePopover(e)}
      >
        <div className="note">
          {/* <a
            className="btn btn-xsm btn-primary tooltip"
            href={
              userData && userData.lang === "KO"
                ? "https://wondersaiguide.oopy.io/d5ce9925-7962-429a-83b6-f5af7011626c"
                : "https://wondersaiguideen.oopy.io/57f2ca60-d272-4d2c-81fd-0868fa931981"
            }
            target="_blank"
          >
            <i className="fa-solid fa-book"></i>
            <div className="tooltiptext">{t("사용자 가이드")}</div>
          </a> */}
          <div className="work-title">
            <input
              type="text"
              value={workTitle}
              placeholder={t("제목을 입력하세요.")}
              onChange={(e) => {
                setWorkTitle(e.target.value);
              }}
            />
          </div>
          <div
            className={`work-content ${mainFont}`}
            contentEditable={editAvailable}
            spellCheck="false"
            ref={noteRef}
            onInput={(e) => {
              handleInputChange(false);
              // e.target.anchorNode의 top 위치
              // console.log(e.target.anchorNode.parentNode.offsetTop);
              setTimeout(() => {
                const selection = document.getSelection();
                const activeNode = selection.anchorNode;
                setCursorPositionTop(activeNode.offsetTop);
                setShowCurrentCursor(true);
              }, 500);
            }}
            onPaste={(e) => {
              // 클립보드 데이터가 html이면, 요소에서 style과 class를 제거하고 붙여넣기
              // if (e.clipboardData.types.includes("text/html")) {
              //   e.preventDefault();
              //   const html = (e.clipboardData || window.clipboardData).getData(
              //     "text/html"
              //   );
              //   // html에서 body innerHTML 추출
              //   const parser = new DOMParser();
              //   const doc = parser.parseFromString(html, "text/html");
              //   const body = doc.body;
              //   // body 안에 있는 모든 요소의 style과 class 제거
              //   body.querySelectorAll("*").forEach((node) => {
              //     node.removeAttribute("style");
              //     node.removeAttribute("class");
              //   });
              //   document.execCommand("insertHTML", false, body.innerHTML);
              // }
              // text만 붙여넣기

              // 기본 붙여넣기 동작 방지
              e.preventDefault();

              // 클립보드에서 HTML 데이터를 가져옴. 텍스트만 필요한 경우 'text/plain'을 사용
              let html = (e.clipboardData || window.clipboardData).getData(
                "text/html"
              );

              // HTML이 없는 경우, 순수 텍스트로 대체
              if (!html) {
                html = (e.clipboardData || window.clipboardData).getData(
                  "text/plain"
                );
              }

              // DOM 파서를 사용하여 HTML을 파싱
              let tempDiv = document.createElement("div");
              tempDiv.innerHTML = html;

              // 모든 요소를 순회하며 불필요한 속성 제거
              Array.from(tempDiv.querySelectorAll("*")).forEach(function (
                node
              ) {
                node.removeAttribute("style"); // style 속성 제거
                node.removeAttribute("class"); // class 속성 제거
                node.removeAttribute("id"); // id 속성 제거
                node.removeAttribute("name"); // name 속성 제거
                node.removeAttribute("tabindex"); // tabindex 속성 제거
                node.removeAttribute("role"); // tabindex 속성 제거

                // data-* 속성 제거
                Array.from(node.attributes).forEach(function (attr) {
                  if (attr.name.startsWith("data-")) {
                    node.removeAttribute(attr.name);
                  }

                  if (attr.name.startsWith("aria-")) {
                    node.removeAttribute(attr.name);
                  }

                  if (attr.name.startsWith("_")) {
                    node.removeAttribute(attr.name);
                  }
                });
                // 필요에 따라 여기에 더 많은 속성 제거 코드를 추가
              });

              // 정리된 HTML을 contenteditable 요소에 삽입
              document.execCommand("insertHTML", false, tempDiv.innerHTML);
            }}
            onMouseUp={(e) => handleSelectionChange(e)}
            onContextMenu={(e) => {
              setShowCurrentCursor(false);

              e.preventDefault();
              if (userData && userData.active_yn === "N") {
                return;
              }

              if (isTdElement(e.target)) {
                setAIPopoverPosition({
                  top: e.pageY,
                  left: e.pageX,
                });
                setShowTablePopover(true);
              } else if (selectedText !== "") {
                setAIPopoverPosition({
                  top: e.pageY,
                  left: e.pageX,
                });
                setShowAIPopover(true);
              }
            }}
            onClickCapture={(e) => {
              // 왼쪽 마우스 클릭이 아니면 return;
              if (e.button !== 0) return;

              setCursorPositionTop(e.pageY);
              setShowCurrentCursor(false);
              if (e.target.nodeName === "IMG") {
                e.preventDefault();
                window.open(e.target.src);
              } else if (e.target.nodeName === "A") {
                e.preventDefault();
                window.open(e.target.href);
              } else if (noteRef.current.contains(e.target)) {
                setShowTablePopover(false);
                setActiveTd(null);
                setTimeout(() => {
                  const selection = document.getSelection();
                  const activeNode = selection.anchorNode;

                  if (
                    activeNode &&
                    noteRef.current &&
                    noteRef.current.contains(activeNode)
                  ) {
                    setCurrentCursorNode(activeNode);
                  }
                }, 500);
              }
            }}
            dangerouslySetInnerHTML={{
              __html:
                workContent === "" ||
                workContent === null ||
                workContent === "<p><br></p>"
                  ? ``
                  : workContent,
            }}
            onClick={(e) => {
              if (e.target.innerText.replace(/\s/g, "") === "") {
                e.target.innerHTML = "<p><br></p>";
              }
            }}
            placeholder={t("여기에 글을 작성하세요.")}
          ></div>
          <div
            className={`work-content-ai ${mainFont}`}
            style={{ marginTop: "0px !important", display: "none" }}
            ref={assistantRef}
            dangerouslySetInnerHTML={{ __html: assistantMessage }}
          ></div>
          <div className="work-content-footer"></div>

          {!editAvailable && (
            <div className="work-guide">
              {t("기본정보를 입력해야 시작할 수 있습니다.")}
            </div>
          )}

          <div
            className="cursor-location"
            style={{
              top: cursorPositionTop,
              display: showCurrentCursor ? "block" : "none",
            }}
          >
            <i className="fa-solid fa-arrow-right-long"></i>
          </div>
        </div>

        <div
          className="chat-box"
          style={{ display: showChatbox ? "flex" : "none" }}
        >
          <textarea
            rows="1"
            value={comment}
            placeholder={t(
              "무엇이든 요청하세요! AI가 답변을 문서에 추가합니다."
            )}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }

                // 요청할 내용이 없으면 return
                if (comment === "") return;

                // 커서 위치가 없으면
                if (!currentCursorNode) {
                  Swal.fire({
                    iconHtml:
                      '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                    title: t(
                      "답변이 추가될 위치에 커서를 먼저 위치시켜주세요."
                    ),
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  return;
                }
                requestChat(e);
              }
            }}
          ></textarea>
          <button
            onClick={(e) => requestChat(e)}
            disabled={comment === "" ? true : false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="none"
              width="20"
              height="20"
            >
              <path
                d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div className="stick-notes" style={{ left: stickNotesRightPosition }}>
        {workResearchList.map((item, index) => (
          <div className="stick-notes-item" key={`stick-note-item-${item.id}`}>
            <div className={`stick-note-header ${item.type}`}>
              <a
                className="stick-note-title"
                onClick={(e) => {
                  e.target.parentNode.parentNode.parentNode.childNodes.forEach(
                    (node) => {
                      if (node !== e.target.parentNode.parentNode) {
                        node.classList.remove("active");
                      }
                    }
                  );
                  e.target.parentNode.parentNode.classList.toggle("active");
                }}
              >
                {item.request_text}
              </a>{" "}
              <button
                type="button"
                className="btn btn-sm btn-transparent"
                onClick={() => deleteWorkResearch(item.id)}
              >
                <i className="fa-regular fa-trash-can"></i>
              </button>
            </div>
            <div className="collapse">
              <div
                className="card card-body"
                dangerouslySetInnerHTML={{ __html: item.response_text }}
              ></div>
              <button
                type="button"
                className="btn btn-sm btn-default m-10"
                onClick={() => insertContent(item.response_text)}
              >
                {t("본문에 삽입하기")}
              </button>
            </div>
          </div>
        ))}
        {processingWorkResearchList.map((item, index) => (
          <div className="stick-notes-item" key={`stick-note-item-${item.id}`}>
            <div className={`stick-note-header ${item.type}`}>
              <a className="stick-note-title">
                <BeatLoader color="#fff" />
              </a>
            </div>
          </div>
        ))}
      </div>
      {!props.leftSidebarOpen && (
        <div className="chat-message-button">
          {beatLoading && <BeatLoader color="#fff" />}

          {!beatLoading && (
            <button
              type="button"
              className="btn btn-xsm btn-tranparent"
              onClick={() => setShowChatbox(!showChatbox)}
            >
              <i className="fa-regular fa-comment-dots"></i>
            </button>
          )}
        </div>
      )}

      {showAIPopover && (
        <div
          className="ai-popover"
          style={{
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
          }}
        >
          <button
            type="button"
            className="btn btn-xsm btn-transparent"
            onClick={() => {
              if (selectedText === "") {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("용어를 선택하세요."),
                  timer: 1000,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }
              setShowAIPopover(false);
              insertGlossary();
            }}
            disabled={!editAvailable}
          >
            <i className="fa-solid fa-briefcase"></i> {t("용어추가")}
          </button>
          <button
            type="button"
            className="btn btn-xsm btn-transparent"
            onClick={() => {
              if (selectedText === "") {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("번역할 문장을 선택하세요."),
                  timer: 1000,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }
              setShowAIPopover(false);

              // selectedText의 길이가 2000자 이상이면, 선택된 문장이 너무 길다는 메시지 출력
              if (selectedText.length > 2000) {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("2,000자 이하의 문장을 선택하세요."),
                  text: t("선택한 문장이 너무 길어 번역할 수 없습니다."),
                  timer: 1500,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }

              Swal.fire({
                title: t("번역할 언어를 선택하세요."),
                text: t("선택한 문장을 번역합니다."),
                input: "select",
                inputOptions: {
                  KO: "한국어(KO)",
                  EN: "English(EN)",
                  JP: "日本語(JP)",
                  FR: "Français(FR)",
                  ES: "Español(ES)",
                  DE: "Deutsch(DE)",
                  IT: "Italiano(IT)",
                  NL: "Nederlands(NL)",
                  RU: "Русский(RU)",
                },
                inputPlaceholder: t("언어 선택"),
                showCancelButton: true,
                cancelButtonText: t("취소"),
                confirmButtonText: t("확인"),
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (value !== "") {
                      resolve();
                    } else {
                      resolve(t("언어를 선택하세요."));
                    }
                  });
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  setTranslationAsssitantType(result.value);
                  // setShowAIPopover(false);
                  setShowTranslationModal(true);
                }
              });
            }}
            disabled={!editAvailable}
          >
            <i className="fa-solid fa-language"></i> {t("번역하기")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setParagraphAsssitantType("");
              setShowAIPopover(false);
              setShowParagraphModal(true);
            }}
          >
            <i className="fa-solid fa-retweet"></i> {t("문장 교체")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setParagraphAsssitantType("detail-");
              setShowAIPopover(false);
              setShowParagraphModal(true);
            }}
          >
            <i className="fa-regular fa-keyboard"></i> {t("상세하게 쓰기")}
          </button>
          <button
            type="button"
            className="btn btn-xsm btn-transparent"
            onClick={() => {
              if (selectedText === "") {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("문장을 선택하세요."),
                  timer: 1000,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }
              setParagraphAsssitantType("short-");
              setShowAIPopover(false);
              setShowParagraphModal(true);
            }}
          >
            <i className="fa-solid fa-filter"></i> {t("간략히쓰기")}
          </button>
          {/* <button
            type="button"
            className="btn btn-xsm btn-transparent"
            onClick={() => {
              if (selectedText === "") {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("문장을 선택하세요."),
                  timer: 1000,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }
              setParagraphAsssitantType("list-");
              setShowAIPopover(false);
              setShowParagraphModal(true);
            }}
            disabled={!editAvailable}
          >
            <i className="fa-solid fa-list"></i> {t("목록화하기")}
          </button> */}
          {/* <button
            type="button"
            className="btn btn-xsm btn-transparent"
            onClick={() => {
              if (selectedText === "") {
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("문장을 선택하세요."),
                  timer: 1000,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
                return;
              }
              setParagraphAsssitantType("table-");
              setShowAIPopover(false);
              setShowParagraphModal(true);
            }}
            disabled={!editAvailable}
          >
            <i className="fa-solid fa-table"></i> {t("표로시각화")}
          </button> */}
        </div>
      )}

      {showTablePopover && (
        <div
          className="ai-popover"
          style={{
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
          }}
        >
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              insertTableRow("above");
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("위에 행 1개 삽입")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              insertTableRow("below");
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("아래에 행 1개 삽입")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              insertTableColumn("left");
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("왼쪽에 열 1개 삽입")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              insertTableColumn("right");
            }}
          >
            <i className="fa-solid fa-plus"></i> {t("오른쪽에 열 1개 삽입")}
          </button>
          {/* line-divider */}
          <div className="line-divider"></div>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              removeTableRow();
            }}
          >
            <i className="fa-regular fa-trash-can"></i> {t("행 삭제")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              removeTableColumn();
            }}
          >
            <i className="fa-regular fa-trash-can"></i> {t("열 삭제")}
          </button>
          <button
            className="btn btn-transparent btn-xsm mr-5"
            onClick={() => {
              setShowTablePopover(false);
              deleteTable();
            }}
          >
            <i className="fa-regular fa-trash-can"></i> {t("표 삭제")}
          </button>
        </div>
      )}

      {showTextColorPicker && (
        <div
          className="color-palette"
          style={{
            position: "absolute",
            display: "flex",
            //   width: "430px",
            justifyContent: "center",
            top: `${AIPopoverPosition.top}px`,
            left: `${AIPopoverPosition.left}px`,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          {colors.map((color, index) => (
            <div
              className="color-option"
              style={{ backgroundColor: color.color }}
              data-class={color.className}
              key={index}
              onClick={(e) => {
                applyFormat("color", color.color);
              }}
            ></div>
          ))}
        </div>
      )}

      {showParagraphModal && (
        <ParagraphAssistantModal
          workId={workId}
          workTitle={workTitle}
          workContent={noteRef.current.innerText}
          type={paragraphAsssitantType}
          selectedText={selectedText}
          onModal={setShowParagraphModal}
          onChange={(paragraph) => {
            setShowParagraphModal(false);
            changeParagraph(paragraph);
          }}
        />
      )}

      {showTranslationModal && (
        <TranslationAssistantModal
          workId={workId}
          workTitle={workTitle}
          workContent={noteRef.current.innerText}
          type={translationAsssitantType}
          selectedText={selectedText}
          onModal={setShowTranslationModal}
          onChange={(paragraph) => {
            console.log(paragraph);
            setShowTranslationModal(false);
            changeParagraph(paragraph);
          }}
        />
      )}

      {showMobileSimulator && (
        <MobileSimulator
          title={workTitle}
          content={noteRef.current.innerHTML}
          onModal={setShowMobileSimulator}
        />
      )}

      {showInfoModal && (
        <InfoModal
          workId={workId}
          onChange={(workInfo) => {
            setInfo(workInfo);
            setEditAvailable(true);
            if (workInfo.need_write_direct === "Y") {
              writeDirect();
            }
            setShowInfoModal(false);
            checkExistTableContent();
            // 목차를 바로 생성하시겠습니까?
            // Swal.fire({
            //   iconHtml:
            //     '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            //   title: t("목차를 바로 생성하시겠습니까?"),
            //   showCancelButton: true,
            //   confirmButtonText: t("예"),
            //   cancelButtonText: t("아니요"),
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     setShowTableContentModal(true);
            //   }
            // });
          }}
          onModal={setShowInfoModal}
        />
      )}

      {showTitleModal && (
        <TitleAssistantModal
          workId={workId}
          onModal={setShowTitleModal}
          onChange={(title) => {
            setShowTitleModal(false);
            setWorkTitle(title);
            saveWork(title);
          }}
        />
      )}

      {showTableContentModal && (
        <TableContentAssistantModal
          workId={workId}
          type={info.type}
          onModal={setShowTableContentModal}
          onSave={() => {
            setShowTableContentModal(false);

            // if (noteRef.current.innerText === "") {
            //   Swal.fire({
            //     iconHtml:
            //       '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            //     title: t("자료 조사를 먼저 하는 건 어떠세요?"),
            //     showCancelButton: true,
            //     confirmButtonText: t("예"),
            //     cancelButtonText: t("아니요"),
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //       setShowMarketAssistantModal(true);
            //     }
            //   });
            // }
          }}
          onChange={(tableContent) => {
            setShowTableContentModal(false);
            setWorkContent(noteRef.current.innerHTML + tableContent);
            saveWork();
            // Swal 자료 조사를 먼저 하는 건 어떠세요?
            // Swal.fire({
            //   iconHtml:
            //     '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
            //   title: t("자료 조사를 먼저 하는 건 어떠세요?"),
            //   showCancelButton: true,
            //   confirmButtonText: t("예"),
            //   cancelButtonText: t("아니요"),
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     setShowMarketAssistantModal(true);
            //   }
            // });
          }}
        />
      )}

      {showImageModal && (
        <ImageModal
          workId={workId}
          onModal={setShowImageModal}
          onInsert={(url) => {
            setShowImageModal(false);
            insertImage(url, currentCursorNode);
          }}
        />
      )}

      {showMarketAssistantModal && (
        <MarketDataModal
          onModal={setShowMarketAssistantModal}
          workId={workId}
          onMarketAnalysis={(ask) => {
            getMarketContent(ask);
            setShowMarketAssistantModal(false);
          }}
        />
      )}

      {showGoogleSearchModal && (
        <GoogleSearchModal
          onModal={setShowGoogleSearchModal}
          workId={workId}
          // onMarketAnalysis={(ask) => {
          //   getMarketContent(ask);
          //   setShowMarketAssistantModal(false);
          // }}
        />
      )}

      {showLinkAnalysisModal && (
        // <LinkAnalysisModal
        //   workId={workId}
        //   onModal={setShowLinkAnalysisModal}
        //   onList={() => {
        //     setShowLinkAnalysisModal(false);
        //     getWorkResearchList();
        //   }}
        //   onChange={(marketContent) => {
        //     insertContent(marketContent);
        //   }}
        // />

        <LinkDataModal
          onModal={setShowLinkAnalysisModal}
          onLinkAnalysis={(link) => {
            getLinkContent(link);
            setShowLinkAnalysisModal(false);
          }}
        />
      )}

      {showYoutubeDataModal && (
        <YoutubeDataModal workId={workId} onModal={setShowYoutubeDataModal} />
      )}

      {showFileAnalysisModal && (
        // <FileAnalysisModal
        //   workId={workId}
        //   onModal={setShowFileAnalysisModal}
        //   onList={() => {
        //     setShowFileAnalysisModal(false);
        //     getWorkResearchList();
        //   }}
        //   onChange={(fileContent) => {
        //     insertContent(fileContent);
        //   }}
        // />

        <FileDataModal
          onModal={setShowFileAnalysisModal}
          onFileAnalysis={(file, requestText) => {
            getFileContent(file, requestText);
            setShowFileAnalysisModal(false);
          }}
        />
      )}

      {showImageAnalysisModal && (
        <ImageAnalysisDataModal
          onModal={setShowImageAnalysisModal}
          onImageAnalysis={(file) => {
            getImageContent(file);
            setShowImageAnalysisModal(false);
          }}
        />
      )}

      {showSpellCheckModal && (
        <SpellCheckerModal
          check={spellCheckText}
          workId={workId}
          onChangeSentence={(sentence) => {
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              sentence.corrected
            );

            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              sentence.original,
              sentence.corrected
            );
          }}
          onMouseOver={(sentence) => {
            const regex = new RegExp(sentence.original, "g");
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              sentence.original,
              `<span class="spell-check-highlight">${sentence.original}</span>`
            );

            setTimeout(() => {
              const span = noteRef.current.querySelector(
                `span.spell-check-highlight`
              );
              if (span)
                span.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 300);
          }}
          onMouseOut={(sentence) => {
            const regex = new RegExp(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              "g"
            );
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              `<span class="spell-check-highlight">${sentence.original}</span>`,
              `${sentence.original}`
            );
          }}
          onModal={setShowSpellCheckModal}
        />
      )}

      {showForbidCheckModal && (
        <ForbidCheckerModal
          workId={workId}
          check={noteRef.current.innerText}
          onModal={setShowForbidCheckModal}
        />
      )}

      {showMemoModal && (
        <MemoModal
          workId={workId}
          onModal={setShowMemoModal}
          onList={() => {
            setShowMemoModal(false);
            getWorkResearchList();
          }}
        />
      )}

      {showSEOModal && (
        <SEOModal
          workId={workId}
          workContent={noteRef.current.innerText}
          onModal={setShowSEOModal}
        />
      )}

      {showShareLinkModal && (
        <ShareLinkModal workId={workId} onModal={setShowShareLinkModal} />
      )}

      {showGlossaryModal && (
        <GlossaryModal
          workId={workId}
          onModal={setShowGlossaryModal}
          onList={() => {
            setShowGlossaryModal(false);
            getGlossaryList();
          }}
          onInsert={() => {
            setShowGlossaryModal(false);
            insertGlossaryAll();
          }}
        />
      )}

      {loading && <Loading />}
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default TGEditor;

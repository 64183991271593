import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { $get, $put } from "../utils/common";

function CompanyHome() {
  const { company_domain } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <h1
        className="company-title"
        onClick={() => navigate(`/c/${company_domain}`)}
      >
        원더스랩
      </h1>
      <main className="main">
        <div
          className="container"
          style={{ maxWidth: "1200px", marginTop: "0px" }}
        >
          <div className="row">
            <div className="col-xl-9 col-md-9 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <article className="card mb-4">
                    <header className="card-header">
                      <div className="card-meta">
                        <a href="#">
                          <time
                            className="timeago"
                            datetime="2021-09-26 20:00"
                            timeago-id="1"
                          >
                            2024년 1월 22일
                          </time>
                        </a>{" "}
                        in <a>시장 분석 보고서</a>
                      </div>
                      <a href="post-image.html">
                        <h4 className="card-title">
                          2024년 숏폼 시장 예상 및 대응 전략
                        </h4>
                      </a>
                    </header>
                    <a href="post-image.html">
                      <img
                        className="card-img"
                        src="https://milo.bootlab.io/img/articles/22.jpg"
                        alt=""
                      />
                    </a>
                    <div className="card-body">
                      <p className="card-text">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                        neque sed ipsum.{" "}
                      </p>
                    </div>
                  </article>
                  <article className="card mb-4">
                    <header className="card-header">
                      <div className="card-meta">
                        <a href="#">
                          <time
                            className="timeago"
                            datetime="2021-09-26 20:00"
                            timeago-id="1"
                          >
                            2024년 1월 22일
                          </time>
                        </a>{" "}
                        in <a>시장 분석 보고서</a>
                      </div>
                      <a href="post-image.html">
                        <h4 className="card-title">
                          2024년 숏폼 시장 예상 및 대응 전략
                        </h4>
                      </a>
                    </header>
                    <a href="post-image.html">
                      <img
                        className="card-img"
                        src="https://milo.bootlab.io/img/articles/22.jpg"
                        alt=""
                      />
                    </a>
                    <div className="card-body">
                      <p className="card-text">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                        neque sed ipsum.{" "}
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-md-6 col-12">
                  <article className="card mb-4">
                    <header className="card-header">
                      <div className="card-meta">
                        <a href="#">
                          <time
                            className="timeago"
                            datetime="2021-09-15 20:00"
                            timeago-id="4"
                          >
                            2024년 1월 21일
                          </time>
                        </a>{" "}
                        in <a href="page-category.html">사업계획서</a>
                      </div>
                      <a href="post-image.html">
                        <h4 className="card-title">
                          의류 브랜드 사용자 데이터 수집 및 활용
                        </h4>
                      </a>
                    </header>
                    <a href="post-image.html">
                      <img
                        className="card-img"
                        src="https://milo.bootlab.io/img/articles/5.jpg"
                        alt=""
                      />
                    </a>
                    <div className="card-body">
                      <p className="card-text">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                        neque sed ipsum.{" "}
                      </p>
                    </div>
                  </article>
                  <article className="card mb-4">
                    <header className="card-header">
                      <div className="card-meta">
                        <a href="#">
                          <time
                            className="timeago"
                            datetime="2021-09-26 20:00"
                            timeago-id="1"
                          >
                            2024년 1월 22일
                          </time>
                        </a>{" "}
                        in <a>시장 분석 보고서</a>
                      </div>
                      <a href="post-image.html">
                        <h4 className="card-title">
                          2024년 숏폼 시장 예상 및 대응 전략
                        </h4>
                      </a>
                    </header>
                    <a href="post-image.html">
                      <img
                        className="card-img"
                        src="https://milo.bootlab.io/img/articles/22.jpg"
                        alt=""
                      />
                    </a>
                    <div className="card-body">
                      <p className="card-text">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                        neque sed ipsum.{" "}
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-3 col-12">
              <aside className="sidebar sidebar-sticky">
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="card-title">About</h4>
                    <p className="card-text">
                      Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                      rhoncus, sem quam <a href="#">semper libero</a>, sit amet
                      adipiscing sem neque sed ipsum.{" "}
                    </p>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="card-title">Categories</h4>
                    <div className="tag-list">
                      <a className="tag" href="page-category.html">
                        시장 분석 보고서
                      </a>
                      <a className="tag" href="page-category.html">
                        사업계획서
                      </a>
                      <a className="tag" href="page-category.html">
                        제안서
                      </a>
                      <a className="tag" href="page-category.html">
                        프로젝트 계획서
                      </a>
                      <a className="tag" href="page-category.html">
                        위험 평가 보고서
                      </a>
                    </div>
                  </div>
                </div>
              </aside>
              {/* <aside className="sidebar">
                  <div className="widget widget-stats">
                    <div className="stats-icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                    <div className="stats-info">
                      <h4>TOTAL DOCUMENTS</h4>
                      <p>1,922</p>
                    </div>
                    <div className="stats-link">
                      <a>
                        View Detail{" "}
                        <i className="fa fa-arrow-alt-circle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="widget widget-stats">
                    <div className="stats-icon">
                      <i className="fa-solid fa-users"></i>
                    </div>
                    <div className="stats-info">
                      <h4>TOTAL USERS</h4>
                      <p>16</p>
                    </div>
                    <div className="stats-link">
                      <a href="javascript:;">
                        View Detail{" "}
                        <i className="fa fa-arrow-alt-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </aside> */}
            </div>
          </div>
        </div>
      </main>
      <div className="site-newsletter">
        <div className="container">
          <div className="text-center">
            <h3 className="h4 mb-2">Subscribe to our newsletter</h3>
            <p className="text-muted">
              Join our monthly newsletter and never miss out on new stories and
              promotions.
            </p>

            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-7 col-lg-5 ms-auto me-auto">
                <div className="input-group mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                    aria-label="Email address"
                  />
                  {/* <span className="input-group-btn"> */}
                  <button className="btn btn-secondary" type="button">
                    Subscribe
                  </button>
                  {/* </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyHome;

import "./App.css";
import "./404.css";

import React, { useState } from "react";
// import Home from "./pages/Home";
import Workspace from "./pages/Workspace";
// import Payment from "./pages/PaypalPayment";
import Order from "./pages/Order";
import OrderSuccess from "./pages/OrderSuccess";
import OrderFail from "./pages/OrderFail";
import Link from "./pages/Link";
import TrasnslationDocument from "./pages/TranslationDocument";
import MarketPlace from "./pages/MarketPlace";
import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";
import CompanyList from "./pages/CompanyList";
import CompanyInsert from "./pages/CompanyInsert";
import CompanyDetail from "./pages/CompanyDetail";
import CompanyHome from "./pages/CompanyHome";
import PublishedDocument from "./pages/PublishedDocument";
import MyPage from "./pages/MyPage";
import SystemPopup from "./pages/SystemPopup";
import SystemPopupRegister from "./pages/SystemPopupRegistration";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import AccountSession from "./pages/AccountSession";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

import "./locales/i18n";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Workspace />} />
            {/* <Route path="/payment" element={<Payment />} /> */}
            {/* 
            <Route path="/order/:currency/:price" element={<Order />} />
            <Route path="/order-success" element={<OrderSuccess />} />
            <Route path="/order-fail" element={<OrderFail />} /> */}
            <Route path="/link/:share_link" element={<Link />} />
            <Route
              path="/translation/:work_id"
              element={<TrasnslationDocument />}
            />
            <Route path="/marketplace" element={<MarketPlace />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/userdetail" element={<UserDetail />} />
            <Route path="/companylist" element={<CompanyList />} />
            <Route path="/companyinsert" element={<CompanyInsert />} />
            <Route path="/companydetail" element={<CompanyDetail />} />
            <Route path="/c/:company_domain" element={<CompanyHome />} />
            <Route
              path="/c/:company_domain/:work_id"
              element={<PublishedDocument />}
            />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="/system-popup" element={<SystemPopup />} />
            <Route
              path="/system-popup-register"
              element={<SystemPopupRegister />}
            />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/500" element={<InternalServerError />} />
            <Route path="/account-session" element={<AccountSession />} />
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { $get, $put, $download } from "../utils/common";
import Swal from "sweetalert2";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";

function TrasnslationDocument() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { work_id } = useParams();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [workTitle, setWorkTitle] = useState("");
  const [workContent, setWorkContent] = useState("");
  const [workList, setWorkList] = useState([]);
  const [activeWorkLang, setActiveWorkLang] = useState("");
  const [loading, setLoading] = useState(false);
  const noteRef = useRef();

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const res = await $download(
        `/api/work/convert/pdf/${activeWorkLang}/${work_id}`
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${workTitle ? workTitle : "untitled"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("PDF 다운로드 중 오류 발생:", error);
    }
    setLoading(false);
  };

  const downloadDocx = async () => {
    setLoading(true);
    try {
      const res = await $download(
        `/api/work/convert/docx/${activeWorkLang}/${work_id}`
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${workTitle ? workTitle : "untitled"}.docx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("DOCX 다운로드 중 오류 발생:", error);
    }
    setLoading(false);
  };

  const getWork = async () => {
    const res = await $get(`/api/work/translate/detail/${work_id}`);
    setWorkList(res.data);
    if (res.data.length > 0) {
      setActiveWorkLang(res.data[0].lang);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("링크가 존재하지 않습니다."),
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    if (workList.length > 0) {
      setWorkTitle(workList.find((item) => item.lang === activeWorkLang).title);
      setWorkContent(
        workList.find((item) => item.lang === activeWorkLang).content
      );
    }
  }, [activeWorkLang]);

  useEffect(() => {
    getWork();
  }, []);
  return (
    <main className="container full">
      <div className="content">
        <div
          className="content-header flex justify-content-center p-10"
          style={{ gap: "0px 10px" }}
        >
          {workList &&
            workList.length > 0 &&
            workList.map((item, index) => {
              return (
                <button
                  key={index}
                  className={
                    activeWorkLang === item.lang
                      ? "btn btn-primary btn-sm"
                      : "btn btn-default btn-sm"
                  }
                  onClick={() => {
                    setActiveWorkLang(item.lang);
                  }}
                >
                  {item.lang}
                </button>
              );
            })}
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => downloadPdf()}
          >
            <i className="fa-regular fa-file-pdf"></i>
          </button>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => downloadDocx()}
          >
            <i className="fa-regular fa-file-word"></i>
          </button>
        </div>
        <div className="content-body overflow-y">
          <div className="note">
            <div
              className="work-title"
              style={{ fontSize: "1.8rem", fontWeight: "700" }}
            >
              {workTitle}
            </div>
            <div
              ref={noteRef}
              className="work-content"
              dangerouslySetInnerHTML={{
                __html: workContent,
              }}
            ></div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </main>
  );
}

export default TrasnslationDocument;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $put,
  $post,
  $convertMarkdownToHtml,
  $convertMarkdownToHtml2,
} from "../utils/common";
import Swal from "sweetalert2";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

function YoutubeDataModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const { isLoggedIn, userData } = useAuth();
  const [assistantParagraph, setAssistantParagraph] = useState("");
  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const getYoutubeContent = async (type) => {
    if (link === "") {
      return;
    }
    if (bProgress) return;
    bProgress = true;
    setLoading(true);
    setAssistantParagraph("");
    sessionStorage.setItem("stopAssistant", false);

    const url = `${serverUrl}/api/prompt/youtube`;

    // props.selectedText 문장을 포함하는 문단을 찾아서 props.selectedText를 포함하고, props.selectedText 이전 최대 600자와 props.selectedText 이후 최대 1000자를 가져온다.

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        workId: props.workId,
        url: link,
        type: type,
      }),
    });

    if (res.status === 402) {
      navigate("/payment");
    }

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantParagraph((prev) => prev + value);

      assistantScrollRef.current.scrollTop =
        assistantScrollRef.current.scrollHeight;
    }

    sessionStorage.setItem("stopAssistant", false);

    if (props.type === "table-") {
      if (userData.lang === "KO") {
        setAssistantParagraph(
          $convertMarkdownToHtml(tempAssistantMessage.join(""))
        );
      } else {
        setAssistantParagraph(
          $convertMarkdownToHtml2(tempAssistantMessage.join(""))
        );
      }
    } else {
      setAssistantParagraph(
        $convertMarkdownToHtml(tempAssistantMessage.join(""))
      );
    }

    bProgress = false;
    setLoading(false);
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3 className="flex align-items-center">{t("유튜브 요약")}</h3>
        </div>
        <div className="modal-body">
          <input
            type="search"
            className="form-control mr-5"
            placeholder={t("유튜브 영상 URL를 입력하세요.")}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                getYoutubeContent();
              }
            }}
          />
          {/* <p>{t("유튜브 영상 내용을 아티클로 작성합니다.")}</p> */}
        </div>
        <div
          className="modal-body"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: assistantParagraph }}
        ></div>
        <div className="modal-footer">
          {loading && (
            <div className="pl-20">
              <BeatLoader color="#f2295b" />
            </div>
          )}
          {loading && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => stopGenerating()}
            >
              <i className="fa-regular fa-circle-stop"></i> {t("중지")}
            </button>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => getYoutubeContent("summary")}
              disabled={link === "" ? true : false}
            >
              <i className="fa-brands fa-youtube"></i> {t("요약하기")}
            </button>
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => getYoutubeContent("article")}
              disabled={link === "" ? true : false}
            >
              <i className="fa-regular fa-file"></i> {t("아티클로 쓰기")}
            </button>
          )}
          {!loading && assistantParagraph !== "" && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                navigator.clipboard.writeText(assistantParagraph);
                Swal.fire({
                  iconHtml:
                    '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                  title: t("복사되었습니다."),
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
            >
              <i className="fa-regular fa-copy"></i> {t("복사하기")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default YoutubeDataModal;

import React, { useState, useEffect, useRef } from "react";
import { $get, $put, $post } from "../utils/common";
import Swal from "sweetalert2";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";

function ForbidCheckerModal(props) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const [resultMessage, setResultMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const assistantScrollRef = useRef(null);

  let bProgress = false;

  const checkForbid = async () => {
    setLoading(true);
    setResultMessage("");

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://riacg0udgj.execute-api.ap-northeast-2.amazonaws.com/dev/hello-lambda"
    );
    xhr.onreadystatechange = function (event) {
      setResultMessage(event.target.response);
    };
    xhr.send(JSON.stringify(props.check));

    $post("/api/prompt/forbid", { workId: props.workId });

    setLoading(false);
  };

  useEffect(() => {
    checkForbid();
  }, []);

  return (
    <div className="modal left">
      <div className="modal-content">
        <div className="modal-header">
          {!loading && (
            <span className="close" onClick={() => props.onModal(false)}>
              &times;
            </span>
          )}
          <h3>
            {t("금칙어 검사")} {loading && <BeatLoader color="#f2295b" />}
          </h3>
        </div>

        <div
          className="modal-body"
          ref={assistantScrollRef}
          dangerouslySetInnerHTML={{ __html: resultMessage }}
        ></div>
      </div>
    </div>
  );
}

export default ForbidCheckerModal;

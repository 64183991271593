import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function WorkList(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [sortKey, setSortKey] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const [workList, setWorkList] = useState([]);
  const [searchText, setSearchText] = useState("");
  // 한달전 날짜
  const beforeMonth = new Date();
  beforeMonth.setMonth(beforeMonth.getMonth() - 1);
  beforeMonth.setDate(beforeMonth.getDate() + 1);

  const [searchFromDate, setSearchFromDate] = useState(
    beforeMonth.toISOString().slice(0, 10)
  );
  // 오늘 날짜

  const [searchToDate, setSearchToDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getWorkList = async () => {
    setLoading(true);
    const searchQuery = searchText ? `&search=${searchText}` : "";
    const res = await $get(
      `/api/work/list/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}${searchQuery}`
    );
    if (res.status === 200) {
      setWorkList(res.data.works);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("글 조회 실패"),
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const searchQuery = searchText ? `&search=${searchText}` : "";
    const res = await $get(
      `/api/work/list/${pageNo}/${pageLimit}?fromDate=${searchFromDate}&toDate=${searchToDate}${searchQuery}`
    );
    if (res.status === 200) {
      setWorkList(res.data.works);
      console.log(Math.ceil(res.data.total_count / pageLimit));
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      // setTimeout(() => {
      paging();
      // }, 1000);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("글 조회 실패"),
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const deleteWork = async (id) => {
    Swal.fire({
      iconHtml:
        '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
      title: t("글 삭제"),
      text: t("글을 삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonText: t("예"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $delete(`/api/work/${id}`);
        if (res.status === 200) {
          props.onList();
          getWorkList();
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  return (
    <main className="container">
      <div className="content">
        <div
          className="content-body bg-white align-items-start"
          ref={scrollRef}
        >
          <div className="search-box mb-10">
            <div className="input-group-sm">
              <input
                type="date"
                className="form-control"
                value={searchFromDate}
                onChange={(e) => setSearchFromDate(e.target.value)}
              />
              ~
              <input
                type="date"
                className="form-control"
                value={searchToDate}
                onChange={(e) => setSearchToDate(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="타이틀을 입력하세요."
                style={{ width: `240px` }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && getWorkList();
                }}
              />
              <button
                className="btn btn-default btn-sm"
                type="button"
                onClick={() => getWorkList()}
              >
                <i className="fa-solid fa-search"></i> {t("조회")}
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th width="*">{t("타이틀")}</th>
                  <th width="200px">{t("생성일")}</th>
                  <th width="80px"></th>
                </tr>
              </thead>
              <tbody>
                {workList &&
                  workList.map((work) => (
                    <tr key={work.id}>
                      <td>
                        <a onClick={() => props.onSelect(work.id)}>
                          {work.title ? work.title : t("제목없음")}
                        </a>
                      </td>
                      <td>{$dateTimezone(work.create_datetime)}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-transparent"
                          onClick={() => deleteWork(work.id)}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {totalPage > 1 && (
              <div className="d-flex justify-content-center mt-10">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {pageNo > 5 && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </a>
                      </li>
                    )}

                    {pageRange.map((page) => (
                      <li key={page}>
                        <a
                          className={
                            pageNo === page ? "page-link active" : "page-link"
                          }
                          onClick={(e) => setPageNo(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}

                    {lastPage < totalPage && (
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => setPageNo(pageNo + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </main>
  );
}

export default WorkList;

import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function LinkDataModal(props) {
  const { t, i18n } = useTranslation();
  const [link, setLink] = useState("");

  const getLinkContent = async () => {
    if (link === "") {
      return;
    }

    //http or https check
    // const linkRegex = new RegExp(
    //   "^(https?:\\/\\/)?" + // protocol
    //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|", // domain name
    //   "((\\d{1,3}\\.){3}\\d{1,3}))", // OR ip (v4) address
    //   "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*", // port and path
    //   "(\\?[;&a-z\\d%_.~+=-]*)?", // query string
    //   "(\\#[-a-z\\d_]*)?$",
    //   "i"
    // );
    // if (!linkRegex.test(link)) {
    //   Swal.fire({
    //     icon: "error",
    //     title: t("링크 오류"),
    //     text: t("링크 형식이 올바르지 않습니다."),
    //   });
    //   return;
    // }

    props.onLinkAnalysis(link);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3 className="flex align-items-center">{t("링크 분석")}</h3>
        </div>
        <div className="modal-body">
          <input
            type="search"
            className="form-control mr-5"
            placeholder={t("웹페이지 URL를 입력하세요.")}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                getLinkContent();
              }
            }}
          />
          <p>{t("입력된 웹페이지의 내용을 요약합니다.")}</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getLinkContent()}
            disabled={link === "" ? true : false}
          >
            <i className="fa-solid fa-magnifying-glass-chart"></i>{" "}
            {t("링크 분석 시작")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LinkDataModal;

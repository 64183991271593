import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import { $post, $get } from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function SocialLogin(props) {
  const appUrl = process.env.REACT_APP_URL;
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout } = useAuth();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showLogo, setShowLogo] = useState(false);
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);
  const googleLoginRef = useRef(null);

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const scrollHandler = () => {
    // if (
    //   sectionRef1.current === null ||
    //   sectionRef2.current === null ||
    //   sectionRef3.current === null
    // )
    //   return;
    // let scrollPosition = window.pageYOffset;
    // const halfHeight = window.innerHeight / 2;
    // if (scrollPosition < halfHeight) {
    //   sectionRef1.current.style.transform = `translateY(${
    //     scrollPosition * 0.5
    //   }px)`;
    // }
    // 색상 변화
    // let colorIntensity = Math.min(scrollPosition / halfHeight, 1);
    // sectionRef2.current.style.backgroundColor = `rgba(0, 0, 0, ${colorIntensity})`;
    // 텍스트 애니메이션
    // let textOpacity = Math.min(scrollPosition / halfHeight, 1);
    // sectionRef2.current.style.opacity = textOpacity;
    // sectionRef2.current.style.transform = `scale(${
    //   1 + scrollPosition / (halfHeight * 5)
    // })`;
  };

  const loginWithGoogle = async () => {
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  const setI18nLanguage = () => {
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    i18n.changeLanguage(userLanguage);
  };

  useEffect(() => {
    setI18nLanguage();
    document.addEventListener("scroll", scrollHandler);

    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => {
      document.removeEventListener("scroll", scrollHandler);
      return () => lightbox.destroy();
    };
  }, []);

  return (
    <div className="login-container">
      <section className="section" ref={sectionRef1}>
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {/* <div
                className="flex justify-content-center logo mb-30"
              >
                <img src="./assets/images/wdot-logo.png" alt="logo" />
              </div> */}
              <div className="login-description mb-20">
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  <img
                    src="./assets/images/wdot-logo.png"
                    style={{
                      height: "60px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                    }}
                    alt="logo"
                  />
                  {t("문서 작성 AI 어시스턴트")}
                </p>
                {/* <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  {t("일에 대한 마침표를 빠르게")}
                  <span className="dot" style={{ fontSize: "2rem" }}>
                    .
                  </span>
                </p> */}
              </div>
              {appUrl.includes("localhost") && (
                <a onClick={() => props.onLoginWithKakao()} className="mb-10">
                  <img
                    src="./assets/images/kakao-login-button.png"
                    width="300px"
                    height="auto"
                    alt="카카오 로그인 버튼"
                  />
                </a>
              )}

              <div
                className="google-login"
                style={{ display: "none" }}
                ref={googleLoginRef}
              >
                {!navigator.userAgent.includes("Safari") && (
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLogin
                      onSuccess={onGoogleSuccess}
                      onError={onGoogleFailure}
                      size="large"
                      shape="rectangular"
                      logo_alignment="left"
                      type="standard"
                      width="300px"
                      height="52px"
                      useOneTap={false}
                    />
                  </GoogleOAuthProvider>
                )}
              </div>
              {userLocalLang === "ko-KR" ? (
                <img
                  className="google-login-button"
                  src="./assets/images/google-login-button.png"
                  style={{ cursor: "pointer" }}
                  width="300px"
                  height="auto"
                  alt="구글 로그인 버튼"
                  onClick={() => {
                    if (navigator.userAgent.includes("Safari")) {
                      loginWithGoogle();
                    } else {
                      googleLoginRef.current
                        .querySelector("[role=button]")
                        .click();
                    }
                  }}
                />
              ) : (
                <img
                  className="google-login-button"
                  src="./assets/images/google-login-button-en.png"
                  style={{ cursor: "pointer" }}
                  width="300px"
                  height="auto"
                  alt="구글 로그인 버튼"
                  onClick={() => {
                    if (navigator.userAgent.includes("Safari")) {
                      loginWithGoogle();
                    } else {
                      googleLoginRef.current
                        .querySelector("[role=button]")
                        .click();
                    }
                  }}
                />
              )}

              <a
                onClick={() => {
                  Swal.fire({
                    iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                    title: t("지금 무료로 시작하기"),
                    text: t("7일간 무료 이용이 가능합니다."),
                    showCancelButton: true,
                    confirmButtonText: t("네"),
                    cancelButtonText: t("아니오"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (navigator.userAgent.includes("Safari")) {
                        loginWithGoogle();
                      } else {
                        googleLoginRef.current
                          .querySelector("[role=button]")
                          .click();
                      }
                    }
                  });
                }}
                className="btn btn-primary mt-10"
                style={{ cursor: "pointer", width: "300px" }}
              >
                {t("지금 무료로 시작하기")}
              </a>
              <a
                href="https://www.wonderslab.ai"
                target="_blank"
                className="btn btn-default mt-10"
                style={{ cursor: "pointer", width: "300px" }}
              >
                {t("더 알아보기")}
              </a>
              {/* <div className="p-10 mt-20">
                <p>
                  {t("계속하면 '더블유닷'의 이용 약관에 동의하는 것입니다.")}{" "}
                  <a
                    href="https://wonderslab.notion.site/Terms-of-Service-a9a0ed72416b462eb313065e7f2e1bd7?pvs=4"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    {t("보기")}
                  </a>
                </p>
                <p>
                  {t("'더블유닷'의 개인정보 처리방침을 확인하세요.")}{" "}
                  <a
                    href="https://wonderslab.notion.site/Privacy-Policy-9961c2e36d9c4c2487adc066fdd5588f?pvs=4"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    {t("보기")}
                  </a>
                </p>
              </div> */}
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center align-items-center">
              <div className="youtube-video">
                {/* <img src="./assets/images/mockup-01.png" alt="logo" />
          <div className="youtube-play-button">
            <i className="fa-brands fa-youtube"></i>
          </div> */}

                <img
                  src="./assets/images/wdot-youtube.png"
                  alt="video"
                  className="img-fluid rounded-3 shadow-lg"
                  width="100%"
                />
                <a
                  href="https://www.youtube.com/watch?v=ubx4ixcTYpg"
                  className="glightbox rounded-circle"
                  style={{
                    backgroundColor: "white",
                    cursor: "pointer",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    boxShadow: "0 0 0 10px rgba(242, 41, 91, 0.3)",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                  }}
                >
                  <i
                    className="fa fa-play-circle fa-3x"
                    style={{ color: "var(--tg-primary-main)" }}
                  ></i>
                </a>

                {/* <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ubx4ixcTYpg?si=IPdXu1yzTQgfpK25"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-text" ref={sectionRef3}>
        <div className="section-title">
          "
          {t(
            "더블유닷(Wdot)은 기업에서 필요한 각종 문서의 작성 과정을 혁신적으로 간소화하며, 아티클, 사설, 칼럼, 보고서, 사업계획서, 시나리오, 책 집필 등에 필요한 모든 작업을 하나의 플랫폼에서 완성할 수 있는 도큐먼트 AI 어시스 턴트입니다."
          )}
          "
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/table_content.gif" alt="" />
              ) : (
                <img src="./assets/gif/table_content_en.gif" alt="" />
              )}
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("목차 생성")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "문서 작성을 시작할 때 가장 중요한 것은 문서의 목차를 빠르게 구성하는 것입니다. 목차를 빠르게 구성하는 것만으로도 문서 작업 속도를 높일 수 있습니다. 더블유닷은 입력된 기본 정보를 바탕으로 목차를 자동으로 생성합니다. 생성된 목차가 마음에 들지 않는다면 '긴 목차', '짧은 목차' 버튼을 클릭해서 새로 제안 받을 수 있습니다. 제안 받은 목차는 사용자가 원하는 형태로 수정할 수 있습니다."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("자료 조사")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "하나의 문서를 완성하기 위해서 수많은 자료를 검색해서 취합하고 정리하는데 많은 시간을 소요하게 됩니다. 더블유닷은 조사할 내용을 입력하면 더블유닷이 문서에서 바로 사용할 수 있는 형태로 내용을 정리해 줍니다. 이제 더이상 자료 조사하느라 시간을 낭비하지 마세요. 그리고 문서 작성을 위해 어떤 자료를 조사해야 할지 막막해도 걱정하지 마세요. 더블유닷이 기본 정보를 바탕으로 어떤 자료를 조사하면 좋을지 추천해드립니다."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/material.gif" alt="" />
              ) : (
                <img src="./assets/gif/material_en.gif" alt="" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/make_longer.gif" alt="" />
              ) : (
                <img src="./assets/gif/make_longer_en.gif" alt="" />
              )}
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("상세히쓰기")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "작성한 내용을 좀 더 상세하게 구체화하고 싶으신가요? 문장을 선택한 후 '상세히쓰기' 버튼 클릭으로 더블유닷이 자동으로 문장을 확장해 줍니다. 더블유닷이 제안한 내용을 그대로 사용하거나 수정해서 사용할 수 있습니다."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("이어쓰기")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "글을 쓰다가 막혔나요? 이어쓰기 버튼을 클릭하면 더블유닷이 이전 문장과 다음 문장의 문맥을 이해하고 자연스럽게 이어지는 문장을 제안해 줍니다. 제안된 문장을 그대로 사용하거나 수정해서 사용할 수 있습니다."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/continue.gif" alt="" />
              ) : (
                <img src="./assets/gif/continue_en.gif" alt="" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/youtube.gif" alt="" />
              ) : (
                <img src="./assets/gif/youtube_en.gif" alt="" />
              )}
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("유튜브 요약")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "작성중인 문서와 관련된 유튜브 영상을 직접 시청하면서 정리하느라 시간을 낭비하지 마세요. 더블유닷에 유튜브 영상 URL만 입력하면 더블유닷이 영상 내용을 요약해 줍니다. 요약된 내용을 그대로 사용하거나 수정해서 사용할 수 있습니다."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gif">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column justify-content-center">
              <div className="function-title">{t("웹페이지 요약")}</div>
              <div className="function-description">
                <p>
                  {t(
                    "참고할 웹페이지가 있나요? 웹페이지의 URL을 입력하면 더블유닷이 웹페이지를 요약해 줍니다. 웹페이지의 내용이 모국어가 아니여도 상관없습니다. 더블유닷이 웹페이지의 내용을 이해하고 여러분의 언어에 맞게 요약해 줍니다. 요약된 내용을 그대로 사용하거나 수정해서 사용할 수 있습니다."
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="flex-column align-items-center justify-content-center">
              {userLocalLang.startsWith("ko") ? (
                <img src="./assets/gif/link.gif" alt="" />
              ) : (
                <img src="./assets/gif/link_en.gif" alt="" />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="section black"
        ref={sectionRef2}
        style={{
          background: "url(./assets/images/mockup-02.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--tg-black)",
            padding: "0px 40px",
            opacity: "0.7",
            position: "absolute",
            top: "20px",
          }}
        >
          <p
            style={{
              color: "var(--tg-white)",
              textAlign: "center",
              padding: "20px 30px",
            }}
          >
            {t(
              "더블유닷(Wdot)은 기업에서 필요한 각종 문서의 작성 과정을 혁신적으로 간소화하며, 아티클, 사설, 칼럼, 보고서, 사업계획서, 시나리오, 책 집필 등에 필요한 모든 작업을 하나의 플랫폼에서 완성할 수 있는 도큐먼트 AI 어시스 턴트입니다."
            )}
          </p>
        </div>
      </section> */}
      {/* <section className="section yellow" ref={sectionRef4}></section> */}
      <div style={{ width: "100%" }}>
        <p style={{ textAlign: "center" }}>
          copyright &copy; {currentYear}{" "}
          <a
            className="underline"
            href="https://www.wonderslab.ai"
            target="_blank"
            // style={{ color: "var(--tg-white)" }}
          >
            wonderslab.ai
          </a>
        </p>
      </div>
    </div>
  );
}

export default SocialLogin;

import React, { useState, useEffect } from "react";
import { $get, $post, $delete } from "../utils/common";

function MobileSimulator(props) {
  const [activeResolution, setActiveResolution] = useState(0);
  const resolutions = [
    { width: 360, height: 740 },
    { width: 390, height: 884 },
  ];
  return (
    <div className="modal">
      <div
        className="simulator"
        style={{
          width: `${resolutions[activeResolution].width + 72}px`,
          height: `${resolutions[activeResolution].height}px`,
        }}
      >
        <div className="resolution">
          <div>
            {resolutions.map((resolution, index) => (
              <div
                key={index}
                className={
                  index === activeResolution
                    ? "resolution-item active"
                    : "resolution-item"
                }
                onClick={() => setActiveResolution(index)}
              >
                {resolution.width}
              </div>
            ))}
          </div>
          <div
            className="resolution-close"
            onClick={() => props.onModal(false)}
          >
            닫기
          </div>
        </div>
        <div className="simulator-viewer">
          <div className="simulator-viewer-header mb-20">{props.title}</div>
          <div
            className="simulator-viewer-body"
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default MobileSimulator;

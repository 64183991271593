import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";
import UsageHistoryModal from "../component/UsageHistoryModal";

function MyPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, checkLogin, logout } = useAuth();
  const { email } = location.state || { email: null };

  const [user, setUser] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [usageList, setUsageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [pageLimit2, setPageLimit2] = useState(10);
  const [pageNo2, setPageNo2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [pageRange2, setPageRange2] = useState([]);
  const [lastPage2, setLastPage2] = useState(1);
  const [workId, setWorkId] = useState(null);
  const [showUsageHistoryModal, setShowUsageHistoryModal] = useState(false);

  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const paging2 = () => {
    const pages = [];

    const pageFirst =
      pageNo2 % 5 === 0 ? pageNo2 - 4 : Math.floor(pageNo2 / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage2) pageLast = totalPage2;
    setLastPage2(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange2(pages);
  };

  const changePaginationUsage = async () => {
    const res = await $get(`/api/usage/user/${pageNo2}/${pageLimit2}`);
    if (res.status === 200) {
      setUsageList(res.data.usages);
      setTotalPage2(Math.ceil(res.data.total_count / pageLimit2));
      paging2();
    }
  };

  const getUserDetail = async () => {
    setLoading(true);
    const res = await $get(`/api/user`);
    if (res.status === 200) {
      setUser(res.data);
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const updateUser = async () => {
    setLoading(true);
    const res = await $put(`/api/user/basic`, {
      name: user.name,
      nickname: user.nickname,
      phone: user.phone,
    });
    if (res.status === 200) {
      getUserDetail();
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 성공",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: "사용자 수정 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const payDateFormat = (datetime) => {
    // datetime yyyymmddhhmmss to format yyyy-mm-dd hh:mm:ss
    //2023-12-27T13:54:04+09:00
    if (!datetime) return "";
    if (datetime.length < 19) return datetime;
    // const year = datetime.substring(0, 4);
    // const month = datetime.substring(4, 6);
    // const day = datetime.substring(6, 8);
    // const hour = datetime.substring(8, 10);
    // const minute = datetime.substring(10, 12);
    // const second = datetime.substring(12, 14);
    // return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return datetime.substring(0, 19).replace("T", " ");
  };

  const deleteAccount = async () => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("정말 탈퇴하시겠습니까?"),
      text: t("계정을 삭제하시면 복구할 수 없습니다."),
      showCancelButton: true,
      confirmButtonText: t("네, 삭제할게요!"),
      cancelButtonText: t("아니요, 취소할게요!"),
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete("/api/user");
        if (res.status === 200) {
          if (res.data.status === "success") {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: t("계정이 성공적으로 삭제되었습니다."),
              text: t("잠시 후 로그아웃됩니다."),
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              logout();
              navigate("/");
            }, 2000);
          } else {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: t("계정 삭제 실패"),
              text: t("다시 시도해주세요."),
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: t("계정 삭제 실패"),
            text: t("다시 시도해주세요."),
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  useEffect(() => {
    paging2();
  }, [totalPage2]);

  useEffect(() => {
    changePaginationUsage(pageNo2);
  }, [pageNo2]);

  useEffect(() => {
    // checkLogin();
    getUserDetail();
    // getPaymentList();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "url(./assets/images/mockup-02.png)",
        // backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div className="container" style={{ maxWidth: "1000px" }}>
        <div className="flex-column align-items-center justify-content-center logo">
          <a onClick={() => navigate("/")}>
            <img
              src={`${appUrl}/assets/images/wdot-logo.png`}
              alt="logo"
              style={{ height: "100px" }}
            />
          </a>
          <p style={{ fontSize: "1rem" }}>{t("일에 대한 마침표를 빠르게")}</p>
        </div>
        {user && (
          <div className="content">
            <div className="content-body align-items-start">
              <div
                className="flex justify-content-between align-items-center mb-10"
                style={{ width: "100%" }}
              >
                <div className="sub-title">{t("사용자 정보")}</div>
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => navigate("/")}
                >
                  {t("돌아가기")}
                </button>
              </div>
              <div className="form-group">
                <label className="form-label">{t("닉네임")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.nickname}
                  onChange={(e) =>
                    setUser({ ...user, nickname: e.target.value })
                  }
                />
                <button
                  className="btn btn-primary btn-sm ml-5"
                  onClick={() => updateUser()}
                >
                  {t("저장")}
                </button>
              </div>
              <div className="form-group">
                <label className="form-label">{t("이름")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
                <button
                  className="btn btn-primary btn-sm ml-5"
                  onClick={() => updateUser()}
                >
                  {t("저장")}
                </button>
              </div>
              <div className="form-group">
                <label className="form-label">{t("이메일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.email}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("연락처")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.phone}
                  placeholder="숫자만 입력해주세요."
                  onChange={(e) =>
                    setUser({
                      ...user,
                      phone: e.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                />
                <button
                  className="btn btn-primary btn-sm ml-5"
                  onClick={() => updateUser()}
                >
                  {t("저장")}
                </button>
              </div>

              <div className="form-group">
                <label className="form-label">{t("가입일")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    user.create_datetime
                      ? $dateTimezone(user.create_datetime)
                      : ""
                  }
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("활성화 기간")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    user.active_start_date
                      ? $dateTimezone(user.active_start_date) +
                        "~" +
                        $dateTimezone(user.active_end_date)
                      : ""
                  }
                  disabled={true}
                />
              </div>
              <div
                className="flex justify-content-between align-items-center mb-10 mt-40"
                style={{ width: "100%" }}
              >
                <div className="sub-title font-nanumMyeongjo">
                  {t("기능 사용 내역")}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead>
                    <tr>
                      <th width="*">{t("타이틀")}</th>
                      <th width="100px">{t("사용 수")}</th>
                      {userData.role_level === 0 && (
                        <th width="140px">{t("토큰 인풋")}</th>
                      )}
                      {userData.role_level === 0 && (
                        <th width="140px">{t("토큰 아웃풋")}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {usageList &&
                      usageList.map((usage, idx) => (
                        <tr key={`usage-${idx}`}>
                          <td>{usage.title}</td>
                          <td>
                            <a
                              onClick={() => {
                                setWorkId(usage.work_id);
                                setShowUsageHistoryModal(true);
                              }}
                              style={{ textDecoration: "underline" }}
                            >
                              {$convertNumberFormat(usage.func_key_count)}
                            </a>
                          </td>
                          {userData.role_level === 0 && (
                            <td>{$convertNumberFormat(usage.prompt_tokens)}</td>
                          )}
                          {userData.role_level === 0 && (
                            <td>
                              {$convertNumberFormat(usage.completion_tokens)}
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {totalPage2 > 1 && (
                  <div className="d-flex justify-content-center mt-10">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {pageNo2 > 5 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo2(pageNo2 - 1)}
                            >
                              <i className="fa-solid fa-arrow-left"></i>
                            </a>
                          </li>
                        )}

                        {pageRange2.map((page) => (
                          <li key={page}>
                            <a
                              className={
                                pageNo2 === page
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={(e) => setPageNo2(page)}
                            >
                              {page}
                            </a>
                          </li>
                        ))}

                        {lastPage2 < totalPage2 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={(e) => setPageNo2(pageNo2 + 1)}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
              <div
                className="card border-danger bg-danger mt-50 mb-30 shadow-sm"
                style={{ width: "100%" }}
              >
                <div className="card-body p-lg-5">
                  <div className="mb-30">
                    <h3 className="mb-0">Danger Zone</h3>
                    <small>
                      {t(
                        "계정을 삭제하게 되면 생성한 문서를 포함한 모든 데이터가 삭제되고, 삭제된 데이터는 다시 복구되지 않습니다. 계정 삭제를 신중히 결정하세요!"
                      )}
                    </small>
                  </div>
                  <div className="mt-30">
                    <a
                      className="btn btn-primary"
                      onClick={() => deleteAccount()}
                    >
                      {t("계정 삭제하기")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          //
        )}
      </div>

      {showUsageHistoryModal && (
        <UsageHistoryModal
          workId={workId}
          roleLevel={userData.role_level}
          onModal={setShowUsageHistoryModal}
        />
      )}
      {loading && <Loading />}
    </div>
  );
}

export default MyPage;

import React, { useState, useEffect } from "react";
import { $put, $post, $get } from "../utils/common";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ShareLinkModal(props) {
  const clientUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();

  const [shareLink, setShareLink] = useState("");
  const [passwordYn, setPasswordYn] = useState("N");
  const [accessPassword, setAccessPassword] = useState("");
  const [activeYn, setActiveYn] = useState("Y");
  const [feedbackYn, setFeedbackYn] = useState("N");
  const [marketOpenYn, setMarketOpenYn] = useState("N");
  const [price, setPrice] = useState(0);

  const saveShareLink = async () => {
    if (props.workId) {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("공유 링크 저장"),
        text: t("공유 링크를 저장하시겠습니까?"),
        showCancelButton: true,
        confirmButtonText: t("예"),
        cancelButtonText: t("아니오"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $put(`/api/work/share/${props.workId}`, {
            active_yn: activeYn,
            feedback_yn: feedbackYn,
            market_open_yn: marketOpenYn,
            password_yn: passwordYn,
            price: price,
            access_password: accessPassword,
          });

          if (res.status === 200) {
            props.onModal(false);
          } else {
            Swal.fire({
              iconHtml:
                '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
              title: t("공유 링크 저장 실패"),
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  const getShareLink = async () => {
    const res = await $get(`/api/work/share/${props.workId}`);
    if (res.status === 200) {
      setShareLink(res.data.share_link);
      setActiveYn(res.data.active_yn);
      setFeedbackYn(res.data.feedback_yn);
      setMarketOpenYn(res.data.market_open_yn);
      setPasswordYn(res.data.password_yn);
      setPrice(res.data.price);
      setAccessPassword(res.data.access_password);
    }
  };

  useEffect(() => {
    getShareLink();
  }, [props.workId]);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => props.onModal(false)}>
            &times;
          </span>
          <h3>{t("공유하기")}</h3>
        </div>
        {shareLink && (
          <div className="modal-body">
            <div className="form-group">
              <label className="form-label">{t("공유 링크")}</label>
              <div>
                <a
                  href={`${clientUrl}/link/${shareLink}`}
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >{`${clientUrl}/link/${shareLink}`}</a>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-sm ml-10"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${clientUrl}/link/${shareLink}`
                  );
                  Swal.fire({
                    iconHtml:
                      '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
                    title: t("링크가 복사되었습니다."),
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }}
              >
                {t("복사")}
              </button>
            </div>
            <div className="form-group">
              <label className="form-label">{t("비번 설정")}</label>
              <select
                className="form-control"
                value={passwordYn}
                onChange={(e) => setPasswordYn(e.target.value)}
              >
                <option value="Y">{t("설정")}</option>
                <option value="N">{t("설정 안함")}</option>
              </select>
            </div>
            {passwordYn === "Y" && (
              <div className="form-group">
                <label className="form-label">{t("비번")}</label>
                <input
                  type="password"
                  className="form-control"
                  value={accessPassword}
                  onChange={(e) => setAccessPassword(e.target.value)}
                />
              </div>
            )}
            <div className="form-group">
              <label className="form-label">{t("공개 여부")}</label>
              <select
                className="form-control"
                value={activeYn}
                onChange={(e) => setActiveYn(e.target.value)}
              >
                <option value="Y">{t("공개")}</option>
                <option value="N">{t("비공개")}</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">{t("피드백")}</label>
              <select
                className="form-control"
                value={feedbackYn}
                onChange={(e) => setFeedbackYn(e.target.value)}
              >
                <option value="Y">{t("피드백 받기")}</option>
                <option value="N">{t("피드백 받지 않기")}</option>
              </select>
            </div>
            {/* <div className="form-group">
              <label className="form-label">{t("마켓 오픈")}</label>
              <select
                className="form-control"
                value={marketOpenYn}
                onChange={(e) => setMarketOpenYn(e.target.value)}
              >
                <option value="Y">{t("마켓 오픈")}</option>
                <option value="N">{t("마켓 오픈 안함")}</option>
              </select>
            </div> */}
            {marketOpenYn === "Y" && (
              <div className="form-group">
                <label className="form-label">{t("가격")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            )}
          </div>
        )}

        <div className="modal-footer flex">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onModal(false)}
          >
            {t("닫기")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => saveShareLink()}
          >
            {t("저장")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShareLinkModal;

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { $get, $put } from "../utils/common";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import FeedbackModal from "../component/FeedbackModal";
import WorkFeedbackModal from "../component/WorkFeedbackModal";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";

function Link() {
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const { share_link } = useParams();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const [workId, setWorkId] = useState("");
  const [workTitle, setWorkTitle] = useState("");
  const [workContent, setWorkContent] = useState("");
  const [linkStatus, setLinkStatus] = useState({});
  const [selectedText, setSelectedText] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showWorkFeedbackModal, setShowWorkFeedbackModal] = useState(false);
  const [role, setRole] = useState("viewer"); // owner, feedback_writer, viewer
  const [feedbackList, setFeedbackList] = useState([]);
  const noteRef = useRef();

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (
      selectedText.length > 0 &&
      noteRef.current.contains(selection.anchorNode)
    ) {
      setSelectedText(selectedText);
      const range = selection.getRangeAt(0);
      setSelectedRange(range);
      const rect = range.getBoundingClientRect();

      setShowFeedbackModal(true);
    } else {
    }
  };

  const getFeedbackList = async (feedbackWorkId) => {
    const res = await $get(`/api/work/feedback/${feedbackWorkId}`);
    if (res.status === 200) {
      setFeedbackList(res.data);
    }
  };

  const getWork = async () => {
    const res = await $get(`/api/share-link/${share_link}`);
    if (res.data.length > 0) {
      setWorkId(res.data[0].id);
      setWorkTitle(res.data[0].title);
      setWorkContent(res.data[0].content);

      const res2 = await $get(`/api/work/feedback/${res.data[0].id}`);

      if (res2.data.email === userData.email) {
        setRole("owner");
        setFeedbackList(res2.data);
      } else if (
        res2.data.filter((item) => item.feedback_email === userData.email)
          .length > 0
      ) {
        setRole("feedback_writer");
        setFeedbackList(
          res2.data.filter((item) => item.feedback_email === userData.email)
        );
      } else {
        setRole("viewer");
      }
    } else {
      Swal.fire({
        iconHtml:
          '<img src="./assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />',
        title: t("링크가 존재하지 않습니다."),
        showConfirmButton: false,
        timer: 1000,
      });
    }

    // view_count 증가
    const res2 = await $put(`/api/share-link/${share_link}`);
  };

  const getStatus = async () => {
    const res = await $get(`/api/share-link/status/${share_link}`);
    if (res.data.length > 0) {
      setLinkStatus(res.data[0]);
      if (res.data[0].active_yn === "N") {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: t("링크가 비활성화 되었습니다."),
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        if (res.data[0].password_yn === "Y") {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: t("비밀번호 입력"),
            text: t("비밀번호를 입력하세요."),
            input: "password",
            showCancelButton: true,
            confirmButtonText: t("확인"),
            cancelButtonText: t("취소"),
          }).then(async (result) => {
            if (result.isConfirmed) {
              const bRight = await $get(
                `/api/share-link/status/${share_link}/${result.value}`
              );
              if (bRight.data.access_password) {
                getWork();
              } else {
                Swal.fire({
                  iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                  title: t("비밀번호가 일치하지 않습니다."),
                  showConfirmButton: false,
                  timer: 1000,
                });
              }
            }
          });
        } else {
          getWork();
        }
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("링크가 존재하지 않습니다."),
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getStatus();
  }, [share_link]);
  return (
    <main className="container full">
      <div className="content">
        {feedbackList && feedbackList.length > 0 && (
          <div className="btn-feedback-show">
            <a onClick={() => setShowWorkFeedbackModal(true)}>
              {t("피드백 보기")}({feedbackList.length})
            </a>
          </div>
        )}
        <div className="content-body overflow-y">
          <div className="note">
            <div
              className="work-title"
              style={{ fontSize: "1.8rem", fontWeight: "700" }}
            >
              {workTitle}
            </div>
            <div
              ref={noteRef}
              className="work-content"
              dangerouslySetInnerHTML={{
                __html: workContent,
              }}
              onMouseUp={(e) => {
                if (linkStatus.feedback_yn === "N") return;
                handleSelectionChange(e);
              }}
            ></div>
          </div>
        </div>
      </div>
      {showFeedbackModal && (
        <FeedbackModal
          onModal={setShowFeedbackModal}
          workId={workId}
          selectedText={selectedText}
          onList={() => {
            setShowFeedbackModal(false);
            getFeedbackList(workId);
          }}
        />
      )}

      {showWorkFeedbackModal && (
        <WorkFeedbackModal
          onModal={setShowWorkFeedbackModal}
          role={role}
          email={userData.email}
          workId={workId}
          onList={(list) => {
            setFeedbackList(list);
          }}
          onMouseOver={(sentence) => {
            const regex = new RegExp(sentence, "g");
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              sentence,
              `<span class="spell-check-highlight">${sentence}</span>`
            );

            setTimeout(() => {
              const span = noteRef.current.querySelector(
                `span.spell-check-highlight`
              );
              if (span)
                span.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 300);
          }}
          onMouseOut={(sentence) => {
            const regex = new RegExp(
              `<span class="spell-check-highlight">${sentence}</span>`,
              "g"
            );
            noteRef.current.innerHTML = noteRef.current.innerHTML.replace(
              `<span class="spell-check-highlight">${sentence}</span>`,
              `${sentence}`
            );
          }}
        />
      )}
    </main>
  );
}

export default Link;
